import { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { ApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import type { ICustomer, IGuestRelationship } from '../../../models'
import { isGuestCheck } from '../../../services'

const getDefaultHost = (user: any) => {
  const chosenHostStr = sessionStorage.getItem(`chosenHost_${user.profile.sub}`)
  return chosenHostStr ? (JSON.parse(chosenHostStr) as IGuestRelationship) : undefined
}

const getDefaultMainUser = (user: any) => {
  const mainUserStr = sessionStorage.getItem(`mainUser_${user.profile.sub}`)
  return mainUserStr ? (JSON.parse(mainUserStr) as ICustomer) : undefined
}

export const ApplicationGuestsAndHostsWrapper = (props: any) => {
  // ************************************
  // Lifecycle
  // ************************************

  const { user } = useAuth()
  const [isGuest, setIsGuest] = useState<boolean>(false)
  const [hostIdForCustomerDataRequests, setHostIdForCustomerDataRequests] = useState<string>()
  const [guests, setGuests] = useState<IGuestRelationship[]>()
  const [hosts, setHosts] = useState<IGuestRelationship[]>()
  const [chosenHost, setChosenHost] = useState<IGuestRelationship | undefined>(getDefaultHost(user))
  const [mainUser, setMainUser] = useState<ICustomer | undefined>(getDefaultMainUser(user))

  const context = useMemo(() => {
    return {
      isGuest,
      setIsGuest,
      hostIdForCustomerDataRequests,
      setHostIdForCustomerDataRequests,
      guests,
      setGuests,
      hosts,
      setHosts,
      chosenHost,
      setChosenHost,
      mainUser,
      setMainUser
    }
  }, [hostIdForCustomerDataRequests, guests, hosts, chosenHost, mainUser, isGuest])

  useEffect(() => {
    setIsGuest(
      isGuestCheck({
        chosenHost,
        hosts,
        mainUser
      })
    )
  }, [hostIdForCustomerDataRequests, chosenHost, mainUser])

  useEffect(() => {
    if (chosenHost) {
      sessionStorage.setItem(`chosenHost_${user?.profile.sub}`, JSON.stringify(chosenHost))
      sessionStorage.setItem(`mainUser_${user?.profile.sub}`, JSON.stringify(mainUser))
    } else {
      sessionStorage.removeItem(`chosenHost_${user?.profile.sub}`)
      sessionStorage.removeItem(`mainUser_${user?.profile.sub}`)
    }
    setHostIdForCustomerDataRequests(chosenHost?.customerId)
  }, [chosenHost])

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationGuestsAndHostsContext.Provider value={context}>
      {props.children}
    </ApplicationGuestsAndHostsContext.Provider>
  )
}
