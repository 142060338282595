import { Constants } from '../../data'
import type { IDefaultProps } from '../../models'
import { HomePageData, getText } from '../../services'
import type { IEpiOnboardingStageBlockData } from './OnboardingPage'

interface IOnboardingStagesPageData extends IDefaultProps {
  paramProperties: URLSearchParams
}

// ************************************
// Public
// ************************************

export const getPageContent = async (config: IOnboardingStagesPageData) => {
  const { epiChildren } = config

  if (epiChildren) {
    const homePageData = HomePageData(epiChildren)

    return {
      ...config,
      logo: {
        image: {
          src: homePageData.logo,
          alt: 'logo'
        },
        link: Constants.paths.energyPage
      },
      onboardingStageData: _getOnboardingContentBasedOnStage(config)
    }
  }
}

// ************************************
// Private
// ************************************

const _getPageIndexFromParamQuery = (config: IOnboardingStagesPageData): number => {
  const { paramProperties } = config

  return Number.parseInt(paramProperties?.get('side') ?? '0') ?? 0
}

const _getOnboardingContentBasedOnStage = (config: IOnboardingStagesPageData) => {
  const { translations } = config

  let newOnboardingStageDate: any = undefined

  if (translations?.onboardingContent) {
    translations.onboardingContent.forEach((content: IEpiOnboardingStageBlockData, index: number) => {
      if (index === _getPageIndexFromParamQuery(config)) {
        newOnboardingStageDate = _parseOnboardingStageData(content, translations, index)
      }
    })
  }

  return newOnboardingStageDate
}

const _parseOnboardingStageData = (content: IEpiOnboardingStageBlockData, translations: any, index: number) => {
  return {
    title: content.title ?? 'Missing title in Episerver',
    description: content.description ?? 'Missing description in Episerver',
    stageCount: translations.onboardingContent.length,
    stage: index,
    translations,
    internalRedirect: _getInternalDirectAction(content),
    image: content.image
      ? {
          src: content.image,
          alt: `${getText('altPrefix', translations)} ${content.title}`
        }
      : undefined
  }
}

const _getInternalDirectAction = (content: IEpiOnboardingStageBlockData) => {
  const internalRefenceUrl = content.blockId ? Constants.epiServerPageNames[content.blockId]?.url : null
  if (content.blockId && internalRefenceUrl) {
    return {
      text: content.tutorialReferenceLinkText,
      link: internalRefenceUrl
    }
  }
  return undefined
}
