import { ContentLoader } from '@episerver/content-delivery'
import { IconType } from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { Constants } from '../../../data'
import type {
  EpiPageType,
  IEpiPage,
  IEpiserverPlank,
  IFeatureStateRule,
  IHomePage,
  IIconItem,
  IServiceEpiPage,
  ITranslationItem
} from '../../../models'
import { isWhiteListed, newrelicErrorLogger, newrelicLogger } from '../HelperService'

// ************************************
// Base
// ************************************

export const getInitiatedContentLoader = (token: string): ContentLoader => {
  const { url, version, brand } = Constants.api
  const baseUrl = `${url}/${version}/${brand}/`

  return new ContentLoader({
    apiUrl: baseUrl,
    selectAllProperties: true,
    expandAllProperties: true,
    getHeaders: () =>
      Promise.resolve({
        'Accept-Language': 'no',
        Accept: 'application-json',
        'Content-Type': 'application-json',
        Authorization: `Bearer ${token}`
      })
  })
}

// ************************************
// Functionality
// ************************************

export const fetchEpiContent = async (l: ContentLoader, id: string): Promise<any | null> => {
  const data = await l
    .getContent(id)
    .then((content: any) => {
      const parsedData: any = dataParser(content)
      return parsedData
    })
    .catch((error: any) => {
      newrelicErrorLogger(error, { level: 'error', message: `Content not loaded: ${error.errorMessage} ID: ${id}` })
      return null
    })

  return data
}

export const fetchEpiChildren = async (l: ContentLoader, id: string = Constants.episerver.pageId ?? '') => {
  const data = await l
    .getChildren(id)
    .then((content: any) => {
      if (content?.length > 0) {
        return content
      }
      return null
    })
    .catch((error: any) => {
      newrelicErrorLogger(error, {
        level: 'error',
        message: `Content not loaded: ${error.errorMessage} ID: ${Constants.episerver.pageId}`
      })
      return null
    })

  return data
}

const dataParser = (epiResp: any) => {
  return epiResp
}

export const simplifyEpiBlockCollection = (blocks: any[]) => {
  return blocks.map((block: any) => {
    return block.contentLink.expanded
  })
}

export const findEpiBlockType = (contentType: string[], typeToUse: string) => {
  if (contentType && contentType.length > 0) {
    return _.find(contentType, (type: string) => {
      return type === typeToUse
    })
  }
  return undefined
}

export const findEpiPageId = (type: EpiPageType, epiMap: any) => {
  return epiMap[type].id
}

export const getText = (
  key: string,
  translation: any,
  textsKey = 'translationItems',
  defaultText: string | undefined = undefined
) => {
  if (textsKey) {
    const foundList: ITranslationItem[] = translation ? translation[textsKey] : []

    if (foundList?.length > 0) {
      const text = hasText(key, foundList)
      if (text !== undefined) return text
      logMissingTranslation(key, textsKey, foundList)
      if (defaultText !== undefined) return defaultText
      return `MISSING: ${key}`
    }
    logMissingTranslation(key, textsKey, foundList)
    if (defaultText !== undefined) return defaultText
    return `MISSING: list or items ${textsKey}`
  }
  logMissingTranslation(key, textsKey, [])
  if (defaultText !== undefined) return defaultText
  return 'MISSING: Text array reference'
}

export interface IGetTextConfiguration {
  key: string
  translations: any
  textsKey?: string
  includeMissing?: boolean
  defaultText?: string
}

export const getTextV2 = (config: IGetTextConfiguration) => {
  const { key, translations, textsKey = 'translationItems', includeMissing = true, defaultText } = config

  let text: string | undefined

  if (textsKey && key) {
    const foundList: ITranslationItem[] = translations ? translations[textsKey] : []

    if (foundList && foundList.length > 0) {
      text = hasText(key, foundList)
    }

    if (text === undefined) {
      logMissingTranslation(key, textsKey, foundList)
    }

    if (text === undefined && includeMissing) {
      if (defaultText !== undefined) {
        text = defaultText
      } else {
        text = `MISSING: ${key}`
      }
    }
  } else {
    logMissingTranslation(key, textsKey, [])
    if (defaultText !== undefined) return defaultText
    return includeMissing ? 'MISSING: Text array reference' : undefined
  }

  return includeMissing || text !== undefined ? text : undefined
}

export const hasText = (key: string, foundList: ITranslationItem[]) => {
  return (
    _.find(foundList, (translationItem: ITranslationItem) => {
      return translationItem.key.trim() === key
    })?.value ?? undefined
  )
}

export interface IGetEpiPlankValues {
  key?: string
  plank: IEpiserverPlank | any
  includeMissing?: boolean
  textsKey?: string
  defaultText?: string
}

// Get text from episerver, and place it in plank
export const getPlankText = (config: IGetEpiPlankValues) => {
  const { key, plank, includeMissing = true, textsKey = 'plankProperties', defaultText } = config
  let text: string | undefined

  if (textsKey && key) {
    const foundList: ITranslationItem[] = plank[textsKey]

    if (foundList.length > 0) {
      text = hasText(key, foundList)
    }

    if (text === undefined) {
      logMissingTranslation(key, textsKey, foundList)
    }

    if (text === undefined && includeMissing) {
      if (defaultText !== undefined) {
        text = defaultText
      } else {
        text = `MISSING: ${key}`
      }
    }
  } else {
    logMissingTranslation(key, textsKey, [])
    if (defaultText !== undefined) return defaultText
    return includeMissing ? 'MISSING: Text array reference' : undefined
  }

  return includeMissing || text !== undefined ? text : undefined
}

// Get icon from episerver, and place it in plank
export const getPlankIcon = (config: IGetEpiPlankValues) => {
  const { key, plank, includeMissing = true } = config
  let icon: IconType | undefined

  if (plank.iconProperties && plank.iconProperties.length > 0) {
    plank.iconProperties.forEach((iconItem: IIconItem) => {
      if (iconItem.key === key) {
        if (Object.values(IconType).includes(iconItem.value)) {
          icon = IconType[iconItem.value]
        }
      }
    })
  }

  if (includeMissing && !icon) {
    icon = IconType.ErrorTriangle
    newrelicLogger(`${config.key} not found in getPlankIcon`, { level: 'warn' })
  }

  return includeMissing ? icon : undefined
}

export const getEpiServicePage = (parent: IEpiPage, serviceId: string) => {
  let foundPage: any = {}

  if (parent?.children?.length > 0) {
    foundPage = _.find(parent.children, (child: IServiceEpiPage) => {
      if (child.data.servicePageId === serviceId) {
        return child.data
      }
    })

    if (!foundPage) {
      newrelicLogger(`Found no service page of ID: ${serviceId}`, { level: 'warn' })
    }
  } else {
    newrelicLogger(`Found no children in epi service page parent: ${parent.type}`, { level: 'warn' })
  }

  return foundPage.data
}

export const AllowFeature = (key: string, rootPage: IHomePage, user: any, customerServiceFeature: boolean) => {
  let allow = false

  if (rootPage.featureStateRules && rootPage.featureStateRules.length > 0) {
    rootPage.featureStateRules.forEach((featureStateRule: IFeatureStateRule) => {
      if (key === featureStateRule.key && featureStateRule.enabled) {
        if (featureStateRule.customerServiceOnly && customerServiceFeature) {
          allow = true
        } else if (featureStateRule.whitelistOnly && isWhiteListed(user)) {
          allow = true
        } else if (!featureStateRule.customerServiceOnly) {
          allow = true
        } else {
          allow = false
        }
      }
    })
  }

  return allow
}

function logMissingTranslation(key?: string, textsKey?: string, foundList?: ITranslationItem[]) {
  newrelicLogger('Missing translation', {
    level: 'warn',
    customAttributes: {
      key: key ?? '',
      textsKey: textsKey ?? '',
      foundList: foundList?.length ?? 0
    }
  })
}
