import { useEffect, useMemo, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import {
  AddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext
} from '../../contexts'
import { type IAddonData, ServiceTypesEnum } from '../../models'
import { getParsedAddonStates } from '../../services'
import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'

export const ServiceWrapperPage = () => {
  // ************************************
  // Lifecycle
  // ************************************

  const { epiChildren } = useApplicationCoreDataContext()
  const { GET } = useApplicationServicehandlerContext()
  const { serviceId } = useParams()

  // ************************************
  // Lifecycle
  // ************************************

  const [addonStates, setAddonStates] = useState<IAddonData[]>()

  useEffect(() => {
    _setAddonStates()
  }, [epiChildren])

  const updateAddonStates = async () => {
    let addonData: IAddonData[] | undefined = undefined

    if (epiChildren) {
      addonData = await getParsedAddonStates(epiChildren, GET, true)
    }

    if (addonData !== addonStates) {
      setAddonStates(addonData)
    }
  }

  const _setAddonStates = () => {
    if (!addonStates) {
      updateAddonStates()
    }
  }

  const context = useMemo(() => {
    return {
      addonStates,
      updateAddonStates
    }
  }, [addonStates])

  // ************************************
  // Render
  // ************************************

  if (serviceId === ServiceTypesEnum.TRUMF) {
    return <Outlet />
  }

  return (
    <AddonServicesContext.Provider value={context}>
      <SubPageLayout autoAdjust={false} />
    </AddonServicesContext.Provider>
  )
}
