import type { getRequest } from '../../contexts'
import type { ICustomerInstallation } from '../../models'

export const fetchSpotPrice = async (
  GET: getRequest,
  installation: ICustomerInstallation,
  detail: 'current' | 'today' | 'tomorrow'
) => {
  const resp = await GET(`SpotPrice/${installation.address.priceArea}/${detail}`)

  return resp
}

export const fetchSpotPriceHistory = async (GET: getRequest, installation: ICustomerInstallation) => {
  const toDate = new Date()
  const fromDate = new Date()
  fromDate.setMonth(toDate.getMonth() - 12)

  const resp = await GET(
    `SpotPrice/${installation.meterId}/history?from=${fromDate.toDateString()}&to=${toDate.toDateString()}`
  )

  return resp.data
}
