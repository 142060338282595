import { createContext, useContext } from 'react'
import type { IAddonData } from '../../models'

interface IAddonServicesContext {
  addonStates?: IAddonData[]
  updateAddonStates: () => void
}

export const AddonServicesContext = createContext<IAddonServicesContext>({
  addonStates: undefined,
  updateAddonStates: () => {}
})

export const useAddonServicesContext = () => useContext(AddonServicesContext)
