import { Text, type Theme } from '@fjordkraft/fjordkraft.component.library'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid4 } from 'uuid'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'
import { NotificationBlock } from '../../../blocks/NotificationBlock/NotificationBlock'
import { ActionButton } from '../../../components/Buttons/ActionButton/ActionButton'
import {
  useApplicationAddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext,
  useDefaultPageContext
} from '../../../contexts'
import { Constants } from '../../../data'
import { type IAddonData, type ServiceOrderSteps, ServiceOrderStepsEnum, ServiceStatusEnum } from '../../../models'
import { getServiceStatus, getText } from '../../../services'
import { getTranslations } from '../../pageUtils'
import {
  type ITrumfTransactions,
  type TrumfData,
  fetchTrumfTransactions,
  getPageData,
  getTrumfCustomerInstallationStatusData,
  getTrumfHouse,
  orderOrTerminateTrumf
} from './Datahandling/UniqueServices/TrumfServicePage'
import { RenderCoreDetails, RenderDescription, RenderPitchCards, RenderSections } from './ServiceBasePage'
import './TrumfServicesPage.scss'
import FjordkraftTrumf from '../../../assets/images/trumf/fjordkraft-trumf.svg?react'
import TrumfPower from '../../../assets/images/trumf/trumf-power.svg?react'
import { DynamicEpiContentBlock, PlankHouseBlock } from '../../../blocks'
import { type LocationNavHeaderProps, PageV3 } from '../../PageV3'
import { ServicePopup } from './ServicePopup'

const getLocalBrand = (translations: any): string => {
  return `brand-${translations.servicePageType?.toLocaleLowerCase() ?? Constants.uiBrand}`
}

const Banner = ({
  classPrefix,
  theme,
  descriptions,
  title,
  pageType
}: {
  classPrefix: string
  theme: Theme
  descriptions: any
  title: string
  pageType: string
}) => {
  return (
    <div className='banner'>
      <div className='banner__content'>
        <div className='banner__content__pageType'>{pageType}</div>
        <div className='banner__content__title'>{title}</div>
        {RenderDescription(classPrefix, 'brand-trumf', theme, descriptions)}
      </div>
      <div className='banner__images'>
        <TrumfPower />
        <FjordkraftTrumf />
      </div>
    </div>
  )
}

export const TrumfServicePage = () => {
  const [queryParams, setQueryParams] = useSearchParams()
  const {
    defaultProps,
    defaultProps: {
      epiChildren,
      user,
      services: { GET, POST },
      activeBrand,
      activeTheme
    }
  } = useApplicationDefaultContext()

  const translations = getTranslations(epiChildren, Constants.epiServerPageNames.services.type, null, 'trumf')
  const localBrand = getLocalBrand(translations)

  const { setTranslation } = useApplicationCoreDataContext()
  const { setContentLoading, contentLoading, setShowBottomEditorialContent } = useDefaultPageContext()
  const { addonStates, updateAddonStates } = useApplicationAddonServicesContext()

  const queryParamState = queryParams?.get('orderStatus') as ServiceOrderStepsEnum | undefined
  const [inProgress, setInProgress] = useState<boolean>(!!queryParamState)
  const [isEditing, setIsEditing] = useState<boolean>(inProgress)
  const [isOrder, setIsOrder] = useState<boolean>(inProgress)
  const [config, setConfig] = useState<TrumfData>()
  const [house, setHouse] = useState<any>()
  const [transactions, setTransactions] = useState<ITrumfTransactions | null | undefined>()

  const installationStatus = getTrumfCustomerInstallationStatusData(user, translations, addonStates, inProgress)

  const onClose = (currentStep: ServiceOrderSteps) => {
    setQueryParams(undefined)
    setIsEditing(false)
    setIsOrder(false)

    if (currentStep === ServiceOrderStepsEnum.SUCCESS) {
      setInProgress(true)
      setConfig(undefined)
      updateAddonStates()
    }
  }

  useEffect(() => {
    setShowBottomEditorialContent(false)
    setTranslation(translations)
    if (!config) {
      setContentLoading(true)
    }

    const fetchTransactions = async () => {
      const trumfTransactions = await fetchTrumfTransactions(GET)
      setTransactions(trumfTransactions)
    }

    const fetchData = async (translations: any, addonStates: IAddonData[]) => {
      const data = await getPageData(defaultProps, translations, addonStates)
      setConfig(data)
      setContentLoading(false)
    }

    if (transactions === undefined) fetchTransactions()

    if (translations && addonStates && !config) {
      fetchData(translations, addonStates)
    }
  }, [
    GET,
    addonStates,
    config,
    contentLoading,
    defaultProps,
    inProgress,
    isEditing,
    setContentLoading,
    setTranslation,
    transactions,
    translations
  ])

  useEffect(() => {
    setConfig(undefined)
  }, [addonStates, inProgress])

  useEffect(() => {
    const { desktopView, activeTheme, relationship, user, services } = defaultProps

    if (transactions !== undefined && addonStates && !house) {
      const house = getTrumfHouse({
        desktopView,
        activeTheme,
        relationship,
        user,
        services,
        addonStates: addonStates ?? ([] as IAddonData[]),
        inProgress: inProgress,
        translations,
        transactions,
        onClickService: () => {
          setIsEditing(!isEditing)
        }
      })
      setHouse(house)
    }
  }, [defaultProps, addonStates, translations, inProgress, isEditing, transactions, house])

  const classPrefix = 'trumf-services-page'

  const memoizedHouse = useMemo(() => {
    // Building the house takes time, so we want to avoid re-rendering it unless the house data has changed
    return house && house.plankWalls?.length > 0 ? (
      <PlankHouseBlock className={`${classPrefix}__main__house`} {...house} />
    ) : null
  }, [house])

  let status: ServiceStatusEnum | undefined
  if (isOrder) {
    status = ServiceStatusEnum.INACTIVE
  } else if (transactions !== undefined) {
    status = getServiceStatus(translations, addonStates, user.installation) as ServiceStatusEnum
  }

  if (!status || !config) return null

  const header = (config: TrumfData): LocationNavHeaderProps => {
    return {
      title: config.sub.title,
      back: config.sub.back
    }
  }

  return (
    <PageV3 header={header(config)}>
      <div className='trumf-services-page'>
        <Banner
          title={config.sub.title}
          pageType={config.sub.back.text}
          classPrefix={classPrefix}
          theme={activeTheme}
          descriptions={config.descriptions?.top}
        />
        <div className='content'>
          {RenderCoreDetails(config.coreDetails, activeBrand, activeTheme, classPrefix)}
          {RenderDescription(classPrefix, localBrand, activeTheme, config.descriptions?.middle)}
          {RenderPitchCards(config.pitchCards, localBrand, activeTheme)}
          {RenderDescription(classPrefix, localBrand, activeTheme, config.descriptions?.bottom)}
          {RenderSections(config.sections, localBrand, classPrefix)}
          {memoizedHouse}
          {!inProgress && installationStatus?.activeAndInactiveInstallations && (
            <NotificationBlock
              className={`${classPrefix}__notifications`}
              notifications={[
                {
                  type: 'Note',
                  title: getText('installationNotActiveTitle', translations),
                  children: [
                    <Text {...paragraphTextPrefab()} align={'align-center'} key={uuid4()}>
                      <ActionButton
                        template={MS_ButtonTemplate(undefined, 'link')}
                        action={{
                          onClick: () => {
                            setIsOrder(true)
                            setIsEditing(true)
                          },
                          text: getText('installationNotActiveText', translations),
                          disabled: false
                        }}
                        padding={'small'}
                        iconPlacement='Right'
                      />
                    </Text>
                  ]
                }
              ]}
            />
          )}
          <DynamicEpiContentBlock
            theme={activeTheme}
            brand={activeBrand}
            epiItems={translations.bottomEditorialContent}
          />
        </div>
      </div>
      {isEditing && (
        <ServicePopup
          translations={translations}
          isTrumf
          status={status}
          steps={[ServiceOrderStepsEnum.DEFAULT]}
          initialStep={queryParamState ?? ServiceOrderStepsEnum.DEFAULT}
          onConfirm={async action => {
            const result = await orderOrTerminateTrumf(action, GET, POST)
            if (result === ServiceOrderStepsEnum.SUCCESS) {
              setInProgress(true)
              setHouse(undefined)
            }
            return result
          }}
          onClose={onClose}
        />
      )}
    </PageV3>
  )
}
