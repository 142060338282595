import { createContext, useContext } from 'react'
import type { CallState } from '../../models'

export interface IMoveAddress {
  address: string
  meterId: string
  physicalMeterNumber: string
  status: 'none' | 'establishing' | 'ending'
}

export interface IMoveInformation {
  hasInfo: boolean
  movingInName?: string
  movingInPhone?: string
  movingInRole?: string
}

export interface IMoveResponseContent {
  moveOutId: string
  pending: boolean
}

interface IMoveContext {
  address: IMoveAddress
  setAddress: (address: IMoveAddress) => void
  terminationDate: Date | null
  setTerminationDate: (date: Date) => void
  information: IMoveInformation
  setInformation: (info: IMoveInformation) => void
  requestResult: CallState
  setRequestResult: (callstate: CallState) => void
  responseContent: IMoveResponseContent
  setResponseContent: (content: IMoveResponseContent) => void
}

export const MoveContext = createContext<IMoveContext>({
  address: { address: '', meterId: '', physicalMeterNumber: '', status: 'none' },
  setAddress: () => {},
  terminationDate: null,
  setTerminationDate: () => {},
  information: { hasInfo: false },
  setInformation: () => {},
  requestResult: 'idle',
  setRequestResult: () => {},
  responseContent: { moveOutId: '', pending: false },
  setResponseContent: () => {}
})

export const useMoveContext = () => useContext(MoveContext)
