import { BrandColors, type IAction, IconType } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import _ from 'lodash'
import type { INotificationNote } from '../../blocks/NotificationBlock/NotificationBlock'
import type { IInvoiceCard } from '../../components'
import type { getRequest, putRequest, typedGetRequest } from '../../contexts'
import type {
  ICustomer,
  ICustomerInvoice,
  ICustomerInvoiceResponse,
  IEstimatedInvoice,
  IGridInvoice,
  IInvoiceDemand,
  InvoiceType
} from '../../models'
import { getText } from './EpiServices/EpiService'
import { createString } from './HelperService'

// ************************************
// Types & Interfaces
// ************************************

type InvoiceFilterTypes = 'All' | 'Mobile' | 'Account'

export interface IFetchParsedInvoices {
  GETTYPED: typedGetRequest
  userData?: ICustomer
  token?: string
  hidePaid?: boolean
  filter?: InvoiceFilterTypes
  accountId?: string
  steddiAccountId?: number
}

interface IParseInvoicesIntoCards {
  invoices: ICustomerInvoice[]
  invoicesDemands: IInvoiceDemand[][]
  translations: any
  desktopView: boolean
  GET: getRequest
}

interface IInvoiceStatusData {
  color: BrandColors
  text: string
}

interface ITranslateInvoiceStatus {
  invoice: ICustomerInvoice
  translation: any
  useDate?: boolean
  usesAutoPayment?: boolean
}

interface IParsedInvoiceData {
  invoices: ICustomerInvoice[]
  estimatedElectricityInvoices: IEstimatedInvoice[]
  noPreviousInvoices: boolean
}

// ************************************
// GET
// ************************************

export const fetchInvoiceDemands = async (GET: getRequest, invoiceNumber: string) => {
  const resp = await GET(`invoices/demand/${invoiceNumber}`)
  return resp.data
}

export const fetchParsedInvoices = async (config: IFetchParsedInvoices): Promise<IParsedInvoiceData> => {
  const { GETTYPED } = config

  let invoices: ICustomerInvoice[] = []
  let estimatedElectricityInvoices: IEstimatedInvoice[] = []

  const resp = await GETTYPED<ICustomerInvoiceResponse>('Invoices')

  let noPreviousInvoices = true

  if (resp.callState === 'success' && resp.data && resp.data.invoices.length > 0) {
    invoices = _parseInvoice(config, resp.data.invoices)
    estimatedElectricityInvoices = resp.data.estimatedElectricityInvoices
    noPreviousInvoices = false
  }

  return { invoices, estimatedElectricityInvoices, noPreviousInvoices }
}

const _parseInvoice = (config: IFetchParsedInvoices, invoices: ICustomerInvoice[]): ICustomerInvoice[] => {
  const { userData, token, accountId, steddiAccountId, hidePaid = false, filter = 'All' } = config

  return _.filter(invoices, (invoice: ICustomerInvoice) => {
    const includeInvoice = hidePaid ? !_isPaid(invoice) : _isPaid(invoice)
    if (userData) invoice.userData = userData
    if (token) invoice.token = token

    if (filter === 'Account' && accountId !== undefined) {
      return (
        includeInvoice &&
        (`${invoice.electricityAccountNumber}` === `${accountId}` ||
          invoice.electricityAccountNumber === `${steddiAccountId}` ||
          (invoice.sender === 'Steddi Payments AS' &&
            !!invoice?.gridInvoices?.some(gridInvoice => gridInvoice.accountNo === `${accountId}`)))
      )
    }
    return includeInvoice
  })
}

// ************************************
// POST
// ************************************

export const setFixedDueDate = async (accountId: string, action: 'Order' | 'Cancel', PUT: putRequest) => {
  const response = await PUT(`account/${accountId}/setFixedDueDate`, {
    action: action,
    dueDate: 20
  })
  return response
}

// ************************************
// PARSING
// ************************************

export const parseInvoiceIntoCard = (
  invoice: ICustomerInvoice,
  invoiceDemands: IInvoiceDemand[],
  translation: any,
  desktopView: boolean,
  GET: getRequest
) => {
  const gridInvoicePdfActions: IAction[] = []

  if (invoice.gridInvoices && invoice.gridInvoices.length > 0) {
    invoice.gridInvoices.forEach((gridInvoice: IGridInvoice) => {
      // which one to use?

      gridInvoicePdfActions.push({
        text: getText('gridRentInvoice', translation),
        link: gridInvoice.pdfPath,
        icon: IconType.PdfIcon
      })
    })
  }

  // Notification handler

  const notifications: INotificationNote[] = []

  if (invoice.delayedDays > 0) {
    // Delayed Notification
    notifications.push({
      type: 'Note',
      title: createString(getText('dueDateExtendedNote', translation), {
        days: invoice.delayedDays
      })
    })
  }

  return {
    invoice,
    desktopView,
    GET,
    translations: translation,
    invoiceAction:
      invoice.type === 'Mobile'
        ? {
            link: invoice.pdfPath,
            text: getText('mobileInvoice', translation),
            icon: IconType.PdfIcon
          }
        : null,
    electricityInvoiceAction:
      invoice.type !== 'Mobile'
        ? {
            link: invoice.pdfPath,
            text: getText('electricityInvoice', translation),
            icon: IconType.PdfIcon
          }
        : null,
    gridRentInvoiceAction: gridInvoicePdfActions,
    notifications,
    demands: invoiceDemands
  } as IInvoiceCard
}

export const parseInvoicesIntoCards = (config: IParseInvoicesIntoCards) => {
  const { invoices, invoicesDemands, translations, desktopView, GET } = config
  let parsedInvoices: IInvoiceCard[] = []

  if (invoices?.length > 0) {
    parsedInvoices = invoices.map((invoice: ICustomerInvoice, i) => {
      return parseInvoiceIntoCard(invoice, invoicesDemands[i], translations, desktopView, GET)
    })
  }

  return parsedInvoices
}

// ************************************
// HELPER FUNCTIONALITY
// ************************************

export const getCorrectInvoicePeriodDate = (periodDate: string): Date => {
  const splitDate: string[] = periodDate.split('-')
  const date: Date = new Date(Number.parseInt(splitDate[0]), Number.parseInt(splitDate[1]) - 1, 1)
  return date
}

export const translateInvoiceCardTitle = (type: InvoiceType, translation: any) => {
  switch (type) {
    case 'Electricity':
      return `${getText('electricity', translation)}`
    case 'Mobile':
      return `${getText('mobile', translation)}`
    case 'GridRent':
      return `${getText('gridRent', translation)}`
    default:
      return ''
  }
}

export const translateInvoiceTitle = (type: InvoiceType, translation: any) => {
  switch (type) {
    case 'Electricity':
      return `${getText('electricityInvoice', translation)}`
    case 'Mobile':
      return `${getText('mobileInvoice', translation)}`
    case 'GridRent':
      return `${getText('gridRentInvoice', translation)}`
    default:
      return ''
  }
}

export const translateInvoiceStatus = (config: ITranslateInvoiceStatus) => {
  const { invoice, translation, useDate = true, usesAutoPayment = false } = config

  let color: BrandColors = BrandColors['status-shade-light-1']
  let text = ''
  const currentDate: Date = new Date()
  const dueDate: Date = new Date(invoice.dueDate)
  let statusKey: string = invoice.paymentStatus
  let dateString: string | undefined = undefined

  if (invoice.paymentStatus === 'Overdue' || invoice.paymentStatus === 'Unpaid') {
    dateString = format(dueDate, 'dd.MM.yyyy')

    if (currentDate > dueDate) {
      statusKey = 'stillUnpaid'
    }
  }

  switch (invoice.paymentStatus) {
    case 'Paid':
      color = BrandColors['status-shade-light-1']
      break
    case 'Credit':
      color = BrandColors['status-shade-light-2']
      break
    case 'Overdue':
    case 'PartlyPaid':
    case 'Unpaid':
      color = BrandColors['status-shade-light-3']
      break
  }

  if (useDate) {
    text = createString(getText(statusKey.toLocaleLowerCase() ?? '', translation), { date: dateString })
  } else if (usesAutoPayment) {
    text = getText('automaticPayment', translation)
    color = BrandColors['status-shade-light-1']
  } else {
    text = getText(`status_${statusKey.toLocaleLowerCase()}`, translation)
  }

  return { text, color } as IInvoiceStatusData
}

// ************************************
// HELPER PRIVATE
// ************************************

const _isPaid = (invoice: ICustomerInvoice) => {
  return invoice.paymentStatus === 'Paid'
}
