import { BrandColors, type IChartHighlightIndicator } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import type { IAreaChartCard } from '../../components'
import { Constants } from '../../data'
import type { ICustomerInstallation, ICustomerSpotPrice, ICustomerTimeSeries, IDefaultProps } from '../../models'
import { createString, getText, tNumber } from '../../services'

export interface ISpotPricePageData extends IDefaultProps {
  includeSubsidy: boolean
  selectedDay: string
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: ISpotPricePageData) => {
  const { user, translations, services, includeSubsidy, selectedDay } = config
  const { installation } = user
  const { GET } = services
  if (installation && GET !== undefined) {
    const priceArea: string = installation?.address?.priceArea ?? ''
    const resp = await GET(`SpotPrice/${priceArea}/${selectedDay}`)
    let chartData: IAreaChartCard = {
      cardHeader: {
        title: getText('pageTitle', translations),
        subTitle: getText('description', translations)
      },
      cardTopLeftText: {
        title: '',
        titleSuffix: getText('currency', translations)
      },
      currentValue: undefined,
      series: [],
      highlights: []
    }

    let stats: any = {
      average: '--',
      max: {
        hour: '',
        value: '--'
      },
      min: {
        hour: '',
        value: '--'
      }
    }

    if (installation.address.priceArea) {
      chartData = _getChartData(translations, resp.data, installation, includeSubsidy, selectedDay)
      if (!chartData.fallback?.show) {
        stats = _getStats(translations, chartData, includeSubsidy)
      }
    }

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: installation?.address.streetAddress,
          link: Constants.paths.energyPage
        },
        subTitle: getText('description', translations)
      },
      chartData: chartData,
      stats: stats
    }
  }
}

// ************************************
// PRIVATE
// ************************************

const _getChartData = (
  translation: any,
  spotPrices: ICustomerSpotPrice,
  installation: ICustomerInstallation,
  includeSubsidy: boolean,
  selectedDay: string
) => {
  const currentTime = new Date().getHours()
  let currentValue: number | undefined = 0

  const seriesUntilNow: any = {
    data: []
  }

  const seriesAllDay: any = {
    data: []
  }

  const seriesInclSubsidyUntilNow: any = {
    data: []
  }

  const seriesInclSubsidyAllDay: any = {
    data: []
  }

  const categories = [...Array(24)].map((_, i) => _toHour(i))
  const tomorrow = selectedDay === 'tomorrow'

  let fallback = { show: false, content: '' }

  if (spotPrices?.timeSeries?.length > 0) {
    spotPrices.timeSeries.forEach((timeSerie: ICustomerTimeSeries) => {
      const xVal: number = new Date(timeSerie.startTime).getHours()

      if (xVal === currentTime) {
        currentValue = timeSerie.cost
      }

      if (xVal <= currentTime && !tomorrow) {
        seriesUntilNow.data.push(timeSerie.cost)
      }

      seriesAllDay.data.push(timeSerie.cost)

      if (includeSubsidy) {
        const costIncludedSubsidy = timeSerie.cost - timeSerie.subsidy
        if (xVal === currentTime) {
          currentValue = costIncludedSubsidy
        }

        if (xVal <= currentTime && !tomorrow) {
          seriesInclSubsidyUntilNow.data.push(costIncludedSubsidy)
        }

        seriesInclSubsidyAllDay.data.push(costIncludedSubsidy)
      }

      if (tomorrow) {
        currentValue = undefined
      }
    })
  }

  let chartTitle = getText('notAvailable', translation)

  if (installation?.address.priceArea) {
    chartTitle = getText(`region${[installation.address.priceArea]}`, translation)
  }

  if (seriesAllDay.data.length === 0 && tomorrow && currentTime < 14) {
    fallback = { show: true, content: getText('fallbackTomorrow', translation) }
  } else if (seriesAllDay.data.length === 0) {
    fallback = { show: true, content: getText('fallback', translation) }
  }

  return {
    cardHeader: {
      title: chartTitle,
      subTitle: createString(getText('chartDescription', translation), {
        address: installation.address.streetAddress
      })
    },
    currentValue: currentValue,
    fallback: fallback,
    highlights: [
      {
        type: 'annotation',
        x: !tomorrow ? `${format(new Date(), 'HH')}:00` : undefined,
        color: includeSubsidy ? BrandColors['chart-shade-light-2'] : BrandColors['primary-shade-light-2']
      } as IChartHighlightIndicator,
      {
        type: 'point',
        x: `${format(new Date(), 'HH')}:00`,
        y: currentValue,
        color: includeSubsidy ? BrandColors['chart-shade-light-2'] : BrandColors['primary-shade-light-2'],
        outlineColor: includeSubsidy ? BrandColors['chart-shade-light-1'] : BrandColors['primary-shade-light-1']
      } as IChartHighlightIndicator
    ],
    categories: categories,
    series: includeSubsidy
      ? [seriesUntilNow, seriesAllDay, seriesInclSubsidyUntilNow, seriesInclSubsidyAllDay]
      : [seriesUntilNow, seriesAllDay]
  }
}

const _getStats = (translations: any, chartData: IAreaChartCard, includeSubsidy: boolean) => {
  const seriesAllDay = includeSubsidy ? chartData.series[3] : chartData.series[1]

  const currency = getText('currency', translations)
  const average =
    seriesAllDay.data.reduce((sum: number, currentValue: number) => sum + currentValue, 0) / seriesAllDay.data.length
  const maxValue = Math.max(...seriesAllDay.data)
  const maxHour = seriesAllDay.data.indexOf(maxValue)
  const minValue = Math.min(...seriesAllDay.data)
  const minHour = seriesAllDay.data.indexOf(minValue)

  return {
    average: `${tNumber(average, 'no-NO', 2)} ${currency}`,
    max: {
      hour: _toHourInterval(maxHour),
      value: `${tNumber(maxValue, 'no-NO', 2)} ${currency}`
    },
    min: {
      hour: _toHourInterval(minHour),
      value: `${tNumber(minValue, 'no-NO', 2)} ${currency}`
    }
  }
}

const _toHourInterval = (i: number) => {
  if (i < 23) return `${_toHour(i)} - ${_toHour(i + 1)}`
  return `${_toHour(i)} - 00:00`
}

const _toHour = (i: number) => {
  const prefix = i <= 9 ? `0${i}` : `${i}`
  return `${prefix}:00`
}
