import {
  BrandColors,
  type IComponentTemplate,
  type IComponentTransform,
  type IThemedColors,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'

const _transform = () => {
  return {
    width: '100vw',
    height: '100vh',
    grid: {
      tagType: 'section',
      direction: 'column',
      alignment: 'center',
      gap: 1,
      boxSizing: 'border-box'
    }
  } as IComponentTransform
}

const _palette = () => {
  return {
    Light: {
      background: {
        color: BrandColors['background-shade-light-2'],
        opacity: 1
      },
      text: { color: BrandColors['text-shade-dark-3'] },
      icon: { color: BrandColors['primary-shade-light-2'] }
    },
    Dark: {
      background: {
        color: BrandColors['background-shade-dark-3'],
        opacity: 1
      },
      text: { color: BrandColors['text-shade-light-1'] },
      icon: { color: BrandColors['primary-shade-light-2'] }
    }
  } as IThemedColors
}

export const AuthenticationLoadingModalTemplate = (theme: Theme = 'Light') => {
  return {
    transform: _transform(),
    palette: _palette()[theme]
  } as IComponentTemplate
}
