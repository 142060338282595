import {
  BrandColors,
  type IComponentTemplate,
  type IComponentTransform,
  type IThemedColors,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: 'auto',
  padding: '0',
  margin: '0',
  outline: {
    type: 'dashed',
    thickness: 2
  },
  grid: {
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center',
    gap: 1
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  Light: {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['primary-shade-light-2'] },
    border: { color: BrandColors['none'] },
    icon: { color: BrandColors['primary-shade-light-2'] },
    extra: [
      {
        key: 'loaderFill',
        value: { color: BrandColors['action-shade-light-1'] }
      },
      {
        key: 'loaderBackground',
        value: { color: BrandColors['action-shade-light-1'] }
      }
    ],
    hover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    focus: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] },
      outline: { color: BrandColors['primary-shade-light-2'] }
    },
    disabled: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      outline: { color: BrandColors['transparent'] }
    }
  },
  Dark: {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['primary-shade-light-2'] },
    border: { color: BrandColors['none'] },
    icon: { color: BrandColors['primary-shade-light-2'] },
    extra: [
      {
        key: 'loaderFill',
        value: { color: BrandColors['action-shade-light-1'] }
      },
      {
        key: 'loaderBackground',
        value: { color: BrandColors['action-shade-light-1'] }
      }
    ],
    hover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    focus: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] },
      outline: { color: BrandColors['primary-shade-light-2'] }
    },
    disabled: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      outline: { color: BrandColors['transparent'] }
    }
  }
} as IThemedColors

export const TextAndIconButtonTemplate = (theme: Theme) => {
  return {
    palette: _palette[theme],
    transform: _transform
  } as IComponentTemplate
}
