import { useParams } from 'react-router-dom'
import { type IInvoiceCard, InvoiceCard } from '../../../components'
import { Constants } from '../../../data'
import type { ICustomerInvoice } from '../../../models'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './InvoiceSelectedPageData'

export interface IInvoiceSelectedPage extends IDefaultViewProps {
  invoice: ICustomerInvoice
  selectedInvoice: IInvoiceCard
}

export const InvoiceSelectedPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { invoiceId } = useParams<any>()

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.invoice.type,
      usesSubPage: true
    },
    dependencies: [{ invoiceId }],
    handleData: getPageContent,
    render: (config: IInvoiceSelectedPage) => {
      const { activeBrand, activeTheme, selectedInvoice } = config

      if (selectedInvoice) {
        return <InvoiceCard {...selectedInvoice} simplified={false} theme={activeTheme} brand={activeBrand} />
      }
    }
  })
}
