import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'
import './EnergyConsumptionPage.scss'

export const EnergyConsumptionPage = () => {
  // ************************************
  // Render
  // ************************************

  return <SubPageLayout autoAdjust={false} />
}
