import {
  BrandColors,
  DatePicker,
  type IComponent,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ActionButton, ArrowButton, Card } from '../../../components'
import {
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useDefaultPageContext,
  useMoveContext
} from '../../../contexts'
import './MoveFormPages.scss'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { Constants } from '../../../data'
import { ErrorModal } from '../../../modals'
import { getText } from '../../../services'

export interface IMoveFormPageDate extends IComponent {
  nextPage: () => void
  prevPage: () => void
  setPage: (page: number) => void
}

const parseDateToLocalMidnight = (dateString: string) => {
  const dateParts = dateString.split('-')
  const date = new Date(
    Number.parseInt(dateParts[0]), // year
    Number.parseInt(dateParts[1]) - 1, // month (0-indexed)
    Number.parseInt(dateParts[2]) // day
  )

  return new Date(date)
}

/**
 *    The MoveFormPageDate is used to -FILL IN-
 *    @type {string} text
 */
export const MoveFormPageDate = (props: IMoveFormPageDate) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { translation } = useApplicationCoreDataContext()
  const { customerServiceFeature } = useApplicationServicehandlerContext()
  const { address, terminationDate, setTerminationDate } = useMoveContext()
  const { setContentLoading } = useDefaultPageContext()
  const { GET } = useApplicationServicehandlerContext()
  const { id, nextPage, prevPage, setPage } = props
  const classPrefix = 'move-form-page-date'

  // ************************************
  // Lifecycle
  // ************************************
  const [enableNext, setEnableNext] = useState<boolean>(false)
  const [firstInteractableDate, setFirstInteractableDate] = useState<Date>()
  const [error, setError] = useState<boolean>()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (firstInteractableDate && terminationDate && terminationDate >= firstInteractableDate) {
      setEnableNext(true)
    } else {
      setEnableNext(false)
    }
  }, [terminationDate, firstInteractableDate])

  useEffect(() => {
    if (_.isEmpty(address)) setPage(1)
  }, [address])

  // ************************************
  // Async functionality
  // ************************************

  const getData = async () => {
    if (!firstInteractableDate) {
      setContentLoading(true)

      if (address.meterId) {
        const result = await GET(`MoveOut/deliveryPoint/${address.meterId}/earliestMoveOutDate`)

        if (result.callState === 'success') {
          const date = parseDateToLocalMidnight(result.data.earliestPossibleMoveOutDate)
          setFirstInteractableDate(date)
        } else setError(true)
      }

      setContentLoading(false)
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const renderDatePicker = useMemo(() => {
    if (firstInteractableDate) {
      return (
        <DatePicker
          id={`${classPrefix}__date-picker`}
          className={`${classPrefix}__date-picker`}
          theme={activeTheme}
          brand={activeBrand}
          interactableStartDate={firstInteractableDate}
          selected={terminationDate ?? null}
          onSelect={(date: Date) => {
            setTerminationDate(date)
          }}
          placeholderText={translation.datePage.datePickerPlaceholder}
        />
      )
    }
  }, [firstInteractableDate, activeBrand, activeTheme, terminationDate])

  // ************************************
  // Render
  // ************************************
  return (
    <>
      {error && (
        <ErrorModal
          mode={'fit'}
          text={getText('unableToFetchEarliestMoveOutDateHeader', translation)}
          description={getText('unableToFetchEarliestMoveOutDateDescription', translation)}
          action={{
            text: getText('unableToFetchEarliestMoveOutDateButtonText', translation),
            link: Constants.paths.moveOutPage
          }}
          brand={Constants.uiBrand}
        />
      )}
      {!error && firstInteractableDate && (
        <StyleGrid
          id={id}
          className={`${classPrefix}`}
          brand={activeBrand}
          theme={activeTheme}
          alignment={'center-left'}
          direction={'row'}
          boxSizing='border-box'
        >
          <Card className={`${classPrefix}__card`} theme={activeTheme} brand={activeBrand} alignment={'center'} gap={3}>
            <Text
              type={'h3'}
              weight={700}
              color={BrandColors['primary-shade-light-2']}
              brand={activeBrand}
              theme={getCounterTheme(activeTheme)}
            >
              {address?.address}
            </Text>
            <Text type={'h4'} weight={600} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
              {customerServiceFeature ? translation.datePage.titleCustomerService : translation.datePage.title}
            </Text>

            {renderDatePicker}

            <Text type={'p'} brand={activeBrand} theme={getCounterTheme(activeTheme)} align={'align-center'}>
              {translation.datePage.moreInfo}
            </Text>
            <ActionButton
              template={MS_ButtonTemplate(activeTheme, 'primary')}
              brand={activeBrand}
              padding='default'
              action={{
                text: translation.datePage.nextPageButtonTitle,
                onClick: nextPage,
                disabled: !enableNext
              }}
            />
            <ArrowButton
              action={{
                onClick: prevPage,
                text: translation.datePage.backButtonTitle
              }}
              iconLocation='left'
            />
          </Card>
        </StyleGrid>
      )}
    </>
  )
}
