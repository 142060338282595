import type { typedGetRequest } from '../../contexts'
import type { IGridTariffResponse } from '../../models/interfaces/pages/IConsumptionCostPage'

export const fetchGridTariff = async (meterId: string, selectedDate: Date, GETTYPED: typedGetRequest) => {
  const year = selectedDate.getFullYear()
  const month = selectedDate.getMonth() + 1
  const fetchUrl = `Consumption/${meterId}/gridtariff/${year}/${month}`
  const resp = await GETTYPED<IGridTariffResponse>(fetchUrl)

  if (resp?.callState === 'success' && resp?.data) {
    return resp.data
  }
  return null
}
