import type { IText } from '@fjordkraft/fjordkraft.component.library'
import { getBrand } from '../../services'

export const downloadLinkTextPrefab = () => {
  return {
    brand: getBrand(),
    family: 'main',
    weight: 500,
    type: 'label',
    align: 'align-left',
    underline: false
  } as IText
}
