import {
  AsyncStateButton,
  BrandColors,
  ContentGrid,
  Icon,
  IconType,
  type LocationType,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { format } from 'date-fns'
import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import {
  MS_ButtonTemplate,
  h3TextPrefab,
  h4TextPrefab,
  paragraphTextPrefab,
  smallParagraphTextPrefab
} from '../../Prefabs'
import { ActionButton, BackButton, MSRichText, MessageItem } from '../../components'
import { useApplicationServicehandlerContext } from '../../contexts'
import { Constants } from '../../data'
import { PopupCard } from '../../modals'
import type { ICustomerMessage, ICustomerMessageAttachment } from '../../models'
import { getText } from '../../services'
import { type IDefaultViewProps, PageV2 } from '../PageV2'
import { getPageContent } from './InboxPageData'
import './InboxPage.scss'

export interface IInboxPage extends IDefaultViewProps {
  messages: ICustomerMessage[]
}

export const InboxPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'inbox-page'
  const { BLOB, DELETE, GET, PUT } = useApplicationServicehandlerContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [selectedMessage, setSelectedMessage] = useState<ICustomerMessage>()
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false)

  // ************************************
  // Helper Functionality
  // ************************************

  const _getUnreadMessagesCount = (config: IInboxPage) => {
    let amount = 0

    if (config?.messages && config?.messages.length > 0) {
      config?.messages.forEach((msg: ICustomerMessage) => {
        if (!msg.opened) {
          amount++
        }
      })
    }

    return amount
  }

  const _updateMessages = async (id: string, type: 'delete' | 'put') => {
    switch (type) {
      case 'delete':
        await DELETE(`Messages/${id}`, {})
        GET('Messages', true)
        break
      case 'put':
        await PUT(`Messages/read/${id}`, {})
        GET('Messages', true)
        break
    }
  }

  const _hasAttachments = () => {
    if (selectedMessage?.content.attachments && selectedMessage.content.attachments.length > 0) {
      return true
    }
    return false
  }

  // ************************************
  // Render Functionality
  // ************************************

  const renderAttachments = (message: ICustomerMessage, config: IInboxPage) => {
    if (_hasAttachments()) {
      return message.content.attachments.map((a: ICustomerMessageAttachment) => {
        return (
          <AsyncStateButton
            key={uuid4()}
            theme={config.activeTheme}
            brand={config.activeBrand}
            asyncOnClick={async () => {
              return BLOB(`Messages/pdf/${a.reference}`, a.name)
            }}
            action={{
              text: a.name
            }}
          />
        )
      })
    }
  }

  const _renderMessages = (config: IInboxPage) => {
    const { messages, activeBrand, activeTheme, relationship, translations, services } = config
    const { isGuest } = relationship
    const { customerServiceFeature } = services

    if (messages && messages.length > 0) {
      return messages.map((msg: ICustomerMessage, index: number) => {
        let placement: LocationType = 'middle'

        if (index === 0) {
          placement = 'top'
        } else if (index >= messages.length - 1) {
          placement = 'bottom'
        }

        return (
          <MessageItem
            key={uuid4()}
            placement={placement}
            item={msg}
            theme={activeTheme}
            brand={activeBrand}
            active={selectedMessage?.id === msg.id}
            onClick={(item: ICustomerMessage) => {
              setSelectedMessage(item)

              if (!item.opened && !customerServiceFeature && !isGuest) {
                _updateMessages(item.id.toString(), 'put')
              }
            }}
            deletedText={getText('deletedText', translations)}
          />
        )
      })
    }
  }

  const _renderStatusContainer = (config: IInboxPage) => {
    const noMessages: boolean = !config?.messages || config?.messages.length === 0

    return (
      <ContentGrid
        className={`${classPrefix}__inbox__right__empty`}
        direction='column'
        alignment='center'
        tagType='section'
        gap={2}
      >
        <Icon
          type={noMessages ? IconType.ConfusedFace : IconType.EnvelopeLowDetail}
          brand={config.activeBrand}
          color={BrandColors['icon-shade-light-2']}
          width={8}
          height={8}
        />
        <Text {...h4TextPrefab()} brand={config.activeBrand} theme={getCounterTheme(config.activeTheme)} faded>
          {noMessages ? getText('empty', config.translations) : getText('welcome', config.translations)}
        </Text>
      </ContentGrid>
    )
  }

  const _renderMessagesContainer = (config: IInboxPage) => {
    if (config?.messages && config?.messages.length > 0) {
      return (
        <StyleGrid className={`${classPrefix}__inbox__left`} direction={'column'} alignment={'top-left'} scaling={2}>
          <ContentGrid
            className={`${classPrefix}__inbox__left__nav`}
            direction={'column'}
            alignment={'top-left'}
            tagType={'nav'}
          >
            {config?.messages && _renderMessages(config)}
          </ContentGrid>
        </StyleGrid>
      )
    }
    return <></>
  }

  const _renderMessageContainer = (config: IInboxPage) => {
    const { desktopView, activeBrand, activeTheme, translations, relationship } = config
    const { isGuest } = relationship

    return (
      <ContentGrid
        className={`${classPrefix}__inbox__right`}
        direction={'column'}
        alignment={'top-left'}
        tagType={'article'}
        scaling={desktopView ? 4 : 0}
        wrap={false}
      >
        {selectedMessage && (
          <>
            <ContentGrid
              className={`${classPrefix}__inbox__right__nav`}
              tagType='nav'
              direction='row'
              alignment='center'
              spaceBetween
            >
              <Text {...paragraphTextPrefab()} theme={getCounterTheme(activeTheme)} brand={activeBrand} faded>
                {format(new Date(selectedMessage.content.dateCreated), 'dd.MM.yyyy HH:mm')}
              </Text>
              <ActionButton
                className={`${classPrefix}__inbox__right__delete-button`}
                brand={activeBrand}
                template={MS_ButtonTemplate(activeTheme, 'urgent')}
                padding={'medium'}
                action={{
                  icon: IconType.TrashFilled,
                  text: getText('delete', translations),
                  disabled: isGuest,
                  onClick: () => {
                    setShowDeletePrompt(true)
                  }
                }}
              />
            </ContentGrid>
            <StyleGrid className={`${classPrefix}__inbox__right__view`} direction='column' alignment='top-left'>
              <ContentGrid
                className={`${classPrefix}__inbox__right_content`}
                direction='column'
                alignment='top-left'
                tagType='section'
              >
                <Text {...h3TextPrefab()} theme={getCounterTheme(activeTheme)} brand={activeBrand}>
                  {selectedMessage.content.subject}
                </Text>
                <MSRichText
                  className={`${classPrefix}__inbox__right__content__text`}
                  text={selectedMessage.content.messageText}
                  theme={getCounterTheme(activeTheme)}
                  alignment='lawful'
                />
              </ContentGrid>
            </StyleGrid>
            {_hasAttachments() && (
              <StyleGrid
                className={`${classPrefix}__inbox__right__attachments`}
                direction='row'
                alignment='center-left'
                gap={2}
              >
                <span
                  className={classnames(`${classPrefix}__inbox__right__attachments__seperator`, {
                    [`${classPrefix}__inbox__right__attachments__seperator--${activeTheme}`]: activeTheme
                  })}
                />
                {renderAttachments(selectedMessage, config)}
              </StyleGrid>
            )}
          </>
        )}
        {!selectedMessage && _renderStatusContainer(config)}
      </ContentGrid>
    )
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.inbox.type,
      usesSubPage: true
    },
    dependencies: [{ showDeletePrompt }],
    dependenciesOptional: [{ selectedMessage }],
    handleData: getPageContent,
    render: (config: IInboxPage) => {
      const { translations, desktopView, activeTheme, activeBrand } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          direction='column'
          alignment={desktopView ? 'top-center' : 'top-left'}
          brand={activeBrand}
        >
          {translations && (
            <>
              {showDeletePrompt && selectedMessage && (
                <PopupCard
                  theme={activeTheme}
                  brand={activeBrand}
                  desktopView={desktopView}
                  contentTitle={getText('deletePromptTitle', translations)}
                  primaryActionStyle='urgent'
                  primaryAction={{
                    onClick: () => {
                      _updateMessages(selectedMessage.id.toString(), 'delete')
                      setSelectedMessage(undefined)
                      setShowDeletePrompt(false)
                    },
                    text: getText('delete', translations),
                    icon: IconType.TrashFilled
                  }}
                  secondaryAction={{
                    onClick: () => {
                      setShowDeletePrompt(false)
                    },
                    text: getText('cancel', translations)
                  }}
                  onClose={() => {
                    setShowDeletePrompt(false)
                  }}
                >
                  <MSRichText
                    brand={activeBrand}
                    theme={getCounterTheme(activeTheme)}
                    text={getText('deletePromptDesc', translations)}
                    alignment='lawful'
                  />
                </PopupCard>
              )}
              <StyleGrid
                className={`${classPrefix}__header`}
                direction='row'
                alignment='center-left'
                spaceBetween={!desktopView}
              >
                <ContentGrid tagType='section' direction='row' alignment='center-left' gap={1}>
                  <Text
                    {...smallParagraphTextPrefab()}
                    weight={600}
                    brand={activeBrand}
                    color={BrandColors['primary-shade-light-2']}
                  >
                    ({_getUnreadMessagesCount(config)})
                  </Text>
                  <Text
                    {...smallParagraphTextPrefab()}
                    weight={600}
                    theme={getCounterTheme(activeTheme)}
                    brand={activeBrand}
                  >
                    {getText('unread', translations)}
                  </Text>
                </ContentGrid>
                {!desktopView && selectedMessage && (
                  <ContentGrid tagType='section' direction='row' alignment='center-right'>
                    <BackButton
                      brand={activeBrand}
                      theme={getCounterTheme(activeTheme)}
                      customization={{
                        text: {
                          weight: 400,
                          size: 'small'
                        }
                      }}
                      action={{
                        text: getText('goBack', translations),
                        onClick: () => {
                          setSelectedMessage(undefined)
                        }
                      }}
                    />
                  </ContentGrid>
                )}
              </StyleGrid>
              <StyleGrid
                className={classnames(`${classPrefix}__inbox`, {
                  [`${classPrefix}__inbox--${activeTheme}`]: activeTheme
                })}
                brand={activeBrand}
                direction='row'
                alignment='top-left'
              >
                {desktopView && (
                  <>
                    {_renderMessagesContainer(config)}
                    {_renderMessageContainer(config)}
                  </>
                )}
                {!desktopView && selectedMessage && _renderMessageContainer(config)}
                {!desktopView && !selectedMessage && _renderMessagesContainer(config)}
              </StyleGrid>
            </>
          )}
        </StyleGrid>
      )
    }
  })
}
