import type { IAction } from '@fjordkraft/fjordkraft.component.library'
import { type ReactNode, useEffect } from 'react'
import { useDefaultPageContext } from '../contexts'
import type { ILocationNavigationInfoBoxContent } from '../models'
import { ApplicationReview } from '../shared/ApplicationReview/ApplicationReview'
import { ApplicationUserEdit } from '../shared/ApplicationUserEdit/ApplicationUserEdit'
import { LocationNavHeaderV2 } from '../shared/LocationNavHeader/LocationNavHeaderV2'
import './PageV3.scss'

export type PageProps = {
  header: LocationNavHeaderProps
  children: ReactNode
}

export type LocationNavHeaderProps = {
  title: string
  back?: IAction
  subTitle?: string
  description?: string
  descriptionTooltip?: string
  infoBoxContent?: ILocationNavigationInfoBoxContent
  showInstallationSelector?: boolean
  subPageType?: string
}

export const PageV3 = (props: PageProps) => {
  const {
    header: { title, subTitle, description, descriptionTooltip, infoBoxContent, back, showInstallationSelector }
  } = props
  const { setMainContentClamping } = useDefaultPageContext()

  useEffect(() => {
    setMainContentClamping('stretch')
  }, [setMainContentClamping])

  return (
    <div className='page'>
      <LocationNavHeaderV2
        title={title}
        subTitle={subTitle}
        description={description}
        descriptionTooltip={descriptionTooltip}
        infoBoxContent={infoBoxContent}
        back={back}
        showInstallationSelector={showInstallationSelector}
      />
      {props.children}
      <ApplicationReview />
      <ApplicationUserEdit />
    </div>
  )
}
