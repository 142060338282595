import {
  BaseComponent,
  ContentGrid,
  type IComponent,
  IconType,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs'
import { ActionButton } from '../../components'
import { useApplicationContext } from '../../contexts'
import type { IHomePage } from '../../models'
import { isExternalUrl } from '../../services'
import { FooterLinkItemGridBlock, type IFooterLinkItem } from '../index'
import { FooterTemplate } from './FooterTemplate'
import './Footer.scss'

export interface IFooter extends IComponent {
  rootPageData: IHomePage
}

export const Footer = (props: IFooter) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, rootPageData } = props
  const classPrefix = 'footer'
  const { activeTheme, activeBrand, desktopView } = useApplicationContext()

  // ************************************
  // Helper Functionality
  // ************************************

  const _getGridContent = () => {
    return rootPageData.footerItems.map((item: any) => {
      item.actionButton.link = item.actionButton.url
      return item as IFooterLinkItem
    })
  }

  // ************************************
  // Render
  // ************************************

  return (
    <BaseComponent
      id={id}
      brand={activeBrand}
      theme={activeTheme}
      className={classnames(classPrefix, {
        [`${activeBrand}`]: activeBrand,
        [`${className}`]: className,
        [`${classPrefix}--${activeTheme}`]: activeTheme
      })}
      template={FooterTemplate(activeTheme, desktopView)}
    >
      {rootPageData.footerItems && (
        <FooterLinkItemGridBlock
          theme={activeTheme}
          brand={activeBrand}
          alignment={'center'}
          items={_getGridContent()}
        />
      )}
      <ContentGrid
        className={`${classPrefix}__proxy-form`}
        tagType='section'
        alignment='center'
        direction={desktopView ? 'row' : 'column'}
        gap={2}
      >
        <Text
          {...paragraphTextPrefab()}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
          faded
          align='align-center'
        >
          {rootPageData.proxyFormDescription}
        </Text>
        {rootPageData.proxyFormAction && (
          <ActionButton
            brand={activeBrand}
            action={{
              text: rootPageData.proxyFormAction.text,
              icon: (rootPageData.proxyFormAction.iconType as IconType) ?? IconType.PdfIcon,
              link: rootPageData.proxyFormAction.url ?? '',
              useRouterLink: !isExternalUrl(rootPageData.proxyFormAction.url)
            }}
            template={MS_ButtonTemplate(activeTheme, 'link')}
          />
        )}
      </ContentGrid>
    </BaseComponent>
  )
}
