import {
  Button,
  ContentGrid,
  type IComponent,
  Icon,
  IconType,
  StyleGrid,
  type StyleLoopLimit
} from '@fjordkraft/fjordkraft.component.library'
import { useEffect, useState } from 'react'
import { useApplicationContext } from '../../contexts'
import './SliderSelector.scss'
import classNames from 'classnames'
import _ from 'lodash'
import { v4 as uuid4 } from 'uuid'
import { MS_ButtonTemplate } from '../../Prefabs'
import { type ISliderItemData, SliderItem } from './SliderItem'

export interface ISliderSelector extends IComponent {
  data: ISliderItemData[]
  uniqueId: string
  onValueChange: (option: any) => void
  defaultOption?: any
  slideToSelect?: boolean
  width?: number
  mobileWidth: number
  sliderItemGap?: StyleLoopLimit
}

/**
 *    The SliderSelector is used to slide between options
 *    @type {any[]} data - array of data to slide between
 *    @type {string} uniqueId - used to not confuse multiple sliders
 *    @type {Function} SliderItem - (item) => ReactElement - Renders the slider items. Data props are within the item.data property.
 *    @type {Function} onValueChange - Triggers when value has changed
 *    @type {any} defaultOption (optional) - takes an option from data, defaults to the first
 *    @type {boolean} slideToSelect (optional) - when set to true, behaviour is changed to using arrows to select, and the slider will only show one element at all times.
 *    @type {number} width (optional) - can be used to set a hard width on the slider, should always be used when slideToSelect is false.
 *    @type {number} mobileWidth - used to set width of slider in mobile view.
 *    @type {string} buttonType - used when slideToSelect is false - defaults to clean
 */
export const SliderSelector = (props: ISliderSelector) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const {
    onValueChange,
    theme = 'Light',
    data,
    className,
    uniqueId,
    defaultOption,
    mobileWidth,
    slideToSelect = false,
    width,
    sliderItemGap = 4
  } = props
  const classPrefix = 'slider-selector'

  // ************************************
  // Lifecycle
  // ************************************

  const [childrenMidPoints, setChildrenMidPoints] = useState<number[]>()
  const [showArrows, setShowArrows] = useState<boolean>(true)
  const [sliderWidth, setSliderWidth] = useState<number>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [activeScrollButton, setActiveScrollButton] = useState<{
    left: boolean
    right: boolean
  }>({ left: false, right: true })

  useEffect(() => {
    if (!slideToSelect) {
      _smoothScrollToMid(activeIndex)
    }
  }, [activeIndex])

  useEffect(() => {
    const element = document.querySelector(`.${classPrefix}__items`)

    let maxWidth = 0
    const mids: number[] = []

    if (slideToSelect) {
      setShowArrows(true)
      if (desktopView) {
        if (!width) {
          const children = document.querySelectorAll(`.${uniqueId}-slider-item`)
          // get width of element for proper sliding = max width of child elements
          children.forEach((child: Element) => {
            if (child) {
              const childWidth = child.getBoundingClientRect().width
              maxWidth = childWidth > maxWidth ? childWidth : maxWidth
            }
          })
          setSliderWidth(maxWidth)
        } else {
          maxWidth = width
          setSliderWidth(maxWidth)
        }
      } else {
        maxWidth = mobileWidth - 2 * 39
        setSliderWidth(maxWidth) //the width of the arrows (1.4rem))
      }
    } else {
      maxWidth = 0
      setShowArrows(true)
      if (desktopView && element && width) {
        maxWidth = width
        if (element.scrollWidth < width) {
          setShowArrows(false)
        }
      } else if (element && mobileWidth) {
        setShowArrows(false)
        maxWidth = element.scrollWidth > mobileWidth ? mobileWidth : element.scrollWidth
      }

      let cummulativeWidths = 0
      const children = document.querySelectorAll(`.${uniqueId}-slider-item`)

      // get points to slide to, to present element in mid of element
      children.forEach((child: Element) => {
        if (child) {
          const childWidth = child.getBoundingClientRect().width
          mids.push(cummulativeWidths - (maxWidth - childWidth) / 2)
          cummulativeWidths += childWidth + sliderItemGap * 8
        }
      })
      setChildrenMidPoints(mids)
      setSliderWidth(maxWidth)
    }
    // scroll to active installation when returning to the page
    if (defaultOption) {
      let index = _.findIndex(data, data => data.value === defaultOption)
      index = index < 0 ? 0 : index

      element?.scrollTo({
        left: slideToSelect ? maxWidth * index : mids[index],
        behavior: 'auto'
      })

      setActiveIndex(index)
      onValueChange(data[index].value)
    }

    // enable/disable scroll buttons when scroll is/isn't at either limit
    // add eventlistener to categories button element
    element?.addEventListener('scroll', _toggleCategoryScrollButtons)
    return () => {
      // and clean it up
      element?.removeEventListener('scroll', _toggleCategoryScrollButtons)
    }
  }, [])

  // ************************************
  // Helpers
  // ************************************

  const _smoothScrollToMid = (index: number) => {
    const element = document.querySelector(`.${classPrefix}__items`)
    if (element && childrenMidPoints) {
      element.scrollTo({
        left: childrenMidPoints[index],
        behavior: 'smooth'
      })
    }
  }

  const _smoothScroll = (direction: 'left' | 'right') => {
    const element = document.querySelector(`.${classPrefix}__items`)
    const children = document.querySelectorAll(`.${uniqueId}-slider-item`)
    if (element !== null && children.length > 0) {
      const amount = element.clientWidth
      let index: number
      switch (direction) {
        case 'left':
          element.scroll({
            left: element.scrollLeft - amount,
            behavior: 'smooth'
          })
          index = activeIndex - 1

          break
        case 'right':
          element.scroll({
            left: element.scrollLeft + amount,
            behavior: 'smooth'
          })
          index = activeIndex + 1
      }
      if (slideToSelect) {
        setActiveIndex(index)
        onValueChange(data[index].value)
      }
    }
  }

  const _toggleCategoryScrollButtons = () => {
    const element = document.querySelector(`.${classPrefix}__items`)
    if (element !== null) {
      setActiveScrollButton({
        left: element.scrollLeft > 0,
        right: element.scrollLeft + element.clientWidth < element.scrollWidth - 1
      })
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderItems = () => {
    return data.map((sliderItem: ISliderItemData, index) => {
      return (
        <SliderItem
          {...sliderItem}
          key={uuid4()}
          className={classNames(`${uniqueId}-slider-item`, {
            [`${sliderItem.className}`]: className
          })}
          width={sliderWidth}
          slideToSelect={slideToSelect}
          action={{
            onClick: () => {
              setActiveIndex(index)

              if (sliderItem.value) {
                onValueChange(sliderItem.value)
              }

              if (sliderItem.onClick) {
                sliderItem.onClick()
              }
            }
          }}
        >
          {sliderItem.children}
        </SliderItem>
      )
    })
  }

  const _renderNavButton = (direction: 'right' | 'left', disabled: boolean) => {
    return (
      <Button
        className={`${classPrefix}__arrow`}
        template={MS_ButtonTemplate(theme, 'icon')}
        action={{
          onClick: () => _smoothScroll(direction)
        }}
        disabled={disabled}
      >
        <Icon
          brand={activeBrand}
          theme={activeTheme}
          type={direction === 'right' ? IconType.ChevronRight : IconType.ChevronLeft}
          width={1.4}
          height={1.4}
        />
      </Button>
    )
  }

  // ************************************
  // Render
  // ************************************
  return (
    <StyleGrid
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      alignment={'center'}
      direction={'row'}
    >
      {showArrows && _renderNavButton('left', !activeScrollButton?.left)}
      <ContentGrid
        className={classNames(`${classPrefix}__items`, {
          [`${classPrefix}__items--pointer-events-none`]: slideToSelect === true,
          [`${classPrefix}__items--pointer-events-auto`]: slideToSelect === false
        })}
        alignment={showArrows ? 'center-left' : 'center'}
        direction={'row'}
        tagType='nav'
        spaceBetween={data.length <= 2}
        gap={slideToSelect ? 0 : sliderItemGap}
        wrap={false}
        style={{ width: `${sliderWidth}px` }}
      >
        {_renderItems()}
      </ContentGrid>
      {showArrows && _renderNavButton('right', !activeScrollButton?.right)}
    </StyleGrid>
  )
}
