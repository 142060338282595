import { type IAction, StyleGrid, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { paragraphTextPrefab } from '../../../Prefabs'
import { type IPlankHouse, PlankHouseBlock } from '../../../blocks'
import { MSRichText } from '../../../components'
import { Constants } from '../../../data'
import { getText } from '../../../services'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './MoveOverviewPageData'
import './MoveOverviewPage.scss'

export interface IMoveOverviewPage extends IDefaultViewProps {
  house: IPlankHouse
  title: string
  back: IAction
}

export const MoveOverviewPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'move-overview-page'

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.move.type,
      usesSubPage: true
    },
    handleData: getPageContent,
    render: (config: IMoveOverviewPage) => {
      const { activeBrand, activeTheme, translations, house } = config

      return (
        <StyleGrid className={classPrefix} direction='column' alignment='top-left' gap={4}>
          <MSRichText
            {...paragraphTextPrefab()}
            alignment='lawful'
            theme={getCounterTheme(activeTheme)}
            brand={activeBrand}
            text={getText('moveIntroduction', translations)}
          />
          <PlankHouseBlock {...house} theme={activeTheme} brand={activeBrand} />
        </StyleGrid>
      )
    }
  })
}
