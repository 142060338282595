import type { IServiceManagementPopup } from '../../modals'

interface IWindowWithDataLayer extends Window {
  dataLayer: Record<string, any>[]
}

export const handleCreateServiceDataLayerEvent = (serviceData: IServiceManagementPopup): void => {
  const serviceDataLayer = createDataLayerObjectFromService(serviceData)
  pushToLayer(serviceDataLayer)
}

export const createDataLayerObjectFromService = (serviceData: IServiceManagementPopup) => {
  const event = {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: `${serviceData.page.serviceStatus?.orderId}`,
          affiliation: 'My Page - Services',
          revenue: '0',
          tax: '0',
          shipping: '0'
        },
        products: [
          {
            name: `${serviceData.page.pageId}`,
            id: `${serviceData.page.productDefinitionId}`,
            price: '0',
            category: 'Min Side Strøm tilleggsprodukt',
            affiliation: 'My Page - Power',
            quantity: '1'
          }
        ]
      }
    }
  }
  return event
}

export const handleCreateDataLayerJSON = (serviceData: IServiceManagementPopup) => {
  return {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: `${serviceData.page.serviceStatus?.orderId}`,
          affiliation: 'My Page - Service',
          revenue: '0',
          tax: '0',
          shipping: '0'
        },
        products: [
          {
            name: `${serviceData.page.pageId}`,
            id: serviceData.page.productDefinitionId,
            price: '0',
            category: 'My Page - Power Extra Product',
            affiliation: 'My Page - Service',
            quantity: '1'
          }
        ]
      }
    }
  }
}

export const pushToLayer = (dataLayerEvent?: any) => {
  ;(window as unknown as IWindowWithDataLayer).dataLayer.push(dataLayerEvent)
}
