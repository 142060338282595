import type { IAction } from '@fjordkraft/fjordkraft.component.library'
import { createContext, useContext } from 'react'
import type { ILocationNavigationInfoBoxContent } from '../../models'
import type { IStatusToast } from '../../pages'

interface ISubPageLayoutContext {
  title?: string
  setTitle: (title: string | undefined) => void
  subTitle?: string
  setSubTitle: (text: string | undefined) => void
  back?: IAction
  setBack: (action: IAction | undefined) => void
  infoBoxContent: ILocationNavigationInfoBoxContent | null
  setInfoBoxContent: (data: ILocationNavigationInfoBoxContent | null) => void
  showInstallationSelector: boolean
  setShowInstallationSelector: (state: boolean) => void
  statusToast: IStatusToast | undefined
  setStatusToast: (data: IStatusToast | undefined) => void
  description?: string
  setDescription: (desc?: string) => void
  descriptionTooltip?: string
  setDescriptionTooltip: (desc?: string) => void
}

export const SubPageLayoutContext = createContext<ISubPageLayoutContext>({
  title: undefined,
  setTitle: () => {},
  subTitle: undefined,
  setSubTitle: () => {},
  back: undefined,
  setBack: () => {},
  infoBoxContent: null,
  setInfoBoxContent: () => {},
  showInstallationSelector: false,
  setShowInstallationSelector: () => {},
  statusToast: undefined,
  setStatusToast: () => {},
  description: undefined,
  setDescription: () => {},
  descriptionTooltip: undefined,
  setDescriptionTooltip: () => {}
})

export const useSubPageLayoutContext = () => useContext(SubPageLayoutContext)
