import type { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useApplicationDefaultContext } from '../../contexts'

export interface IPowerUserGuardedRouteProps {
  redirectPath?: string
  children: ReactElement
}

export const PowerCustomerGuard = (props: IPowerUserGuardedRouteProps) => {
  const { defaultProps } = useApplicationDefaultContext()
  const { user } = defaultProps
  return user.userData?.customerId ? props.children : <Navigate to={props.redirectPath ?? '/'} />
}
