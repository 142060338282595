import { BrandColors, Text } from '@fjordkraft/fjordkraft.component.library'
import { useMemo } from 'react'
import { h2TextPrefab } from '../../Prefabs'
import { type IPlankDropdown, type IPlankDropdownItem, PlankDropdown } from '../../components'
import { useApplicationDefaultContext, useApplicationOverlayWrapperContext } from '../../contexts'
import type { IGuestRelationship, IPerson } from '../../models'
import type { IDefaultViewProps } from '../../pages/PageV2'
import { createString, getText, isNonCustomer } from '../../services'

export interface IMainTitleProps extends IDefaultViewProps {
  guestDropdown?: IPlankDropdown
}

export const MainTitle = () => {
  const classPrefix = 'main-title'
  const { defaultProps } = useApplicationDefaultContext()

  const { user, desktopView, activeTheme, activeBrand, translations, relationship } = defaultProps
  const { setHostIdForCustomerDataRequests, setChosenHost, chosenHost, hosts, isGuest } = relationship
  const { setGlobalLoading } = useApplicationOverlayWrapperContext()
  const { mainUser } = user

  const _getTitle = (userData: IPerson): string => {
    if (userData) {
      return createString(getText('pageTitleExtended', translations), {
        name: `${userData.firstName.split(' ')[0]}`
      })
    }
    return ''
  }

  const preActiveItem = useMemo((): IPlankDropdownItem | undefined => {
    if (chosenHost) {
      return {
        activeTitle: isGuest
          ? `${getText('guest', translations)} ${chosenHost.firstName.split(' ')[0]}`
          : _getTitle(chosenHost),
        left: {
          title: `${chosenHost.firstName.split(' ')[0]}`
        },
        value: chosenHost
      } as IPlankDropdownItem
    }
  }, [chosenHost, isGuest])

  const guestDropDown = useMemo((): IPlankDropdown | undefined => {
    if (hosts && hosts.length > 0) {
      const items: IPlankDropdownItem[] = []

      // 1. Add main user to drop down with title "Hei {name}!".
      if (
        mainUser && // null check
        chosenHost?.customerId !== mainUser.customerId && // mainUser is not the current user
        !isNonCustomer(hosts, mainUser) // mainUser is not a non-customer
      ) {
        items.push({
          value: mainUser,
          activeTitle: _getTitle(mainUser),
          left: {
            title: `${mainUser.firstName.split(' ')[0]}`
          }
        })
      }

      // 2. Add other hosts to dropdown
      for (const host of hosts) {
        items.push({
          value: host,
          activeTitle: `${getText('guest', translations)} ${host.firstName.split(' ')[0]}`,
          left: {
            description: getText('guest', translations)
          },
          right: {
            title: `${host.firstName.split(' ')[0]}`
          }
        })
      }

      if (items.length > 1) return { items } as IPlankDropdown
    }
  }, [chosenHost])

  if (guestDropDown) {
    return (
      <PlankDropdown
        {...guestDropDown}
        id={`${classPrefix}-dropdown-userData-select`}
        className={`${classPrefix}__dropdown-userData-select`}
        theme={activeTheme}
        brand={activeBrand}
        genericDropdownStyle={true}
        gridPlacement={!desktopView && hosts?.length === 0 ? 'bottom-left' : 'bottom-center'}
        preActiveItem={preActiveItem}
        onSelectedChange={(value: any) => {
          const chosenUser = value as IGuestRelationship
          setGlobalLoading(true)

          if (mainUser && mainUser.customerId !== chosenUser.customerId) {
            setChosenHost(chosenUser)
            setHostIdForCustomerDataRequests(chosenUser.customerId)
          } else {
            setChosenHost(undefined)
            setHostIdForCustomerDataRequests(undefined)
          }
        }}
        customization={{
          text: {
            type: 'label',
            weight: 700,
            size: desktopView ? 'huge' : 'large'
          },
          iconBoxed: true,
          gap: 2,
          gapType: 'px'
        }}
      />
    )
  }
  return (
    <Text
      className={`${classPrefix}__top__title`}
      {...h2TextPrefab()}
      weight={700}
      size={desktopView ? 'huge' : 'large'}
      color={BrandColors['primary-shade-light-2']}
    >
      {_getTitle(mainUser)}
    </Text>
  )
}
