import { BrandColors, type Theme, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import { MS_ButtonTemplate, getPlankPrefab } from '../../../../Prefabs'
import type { IDescriptionBlock, IDetailsDescriptionBlock } from '../../../../blocks'
import type { ICardPoint, IStatePlank } from '../../../../components'
import type { typedGetRequest } from '../../../../contexts'
import { Constants } from '../../../../data'
import {
  type IAdditonalAddonService,
  type IAddonData,
  type IAgreementMinimumTermTypeDefinition,
  type ICustomerInstallation,
  type ICustomerProductPrice,
  type IDataPoint,
  type IFuturePrice,
  type IProductPrice,
  type IServicePage,
  type IServicePrices,
  type IServiceStatusDescriptions,
  type IServices,
  type IUser,
  OrderMethodTypeEnum,
  type ServiceStatus,
  ServiceStatusDescriptionEnum,
  ServiceStatusEnum
} from '../../../../models'
import {
  type ITypedResponse,
  createString,
  daysToMonths,
  getClampedStatusFromInstallation,
  getSpecificAddonStateForInstallation,
  getText,
  getTextV2,
  narrowDownResultStatusForPage,
  narrowDownResultStatusForPlank,
  parseActiveExtraProducts,
  tNumber
} from '../../../../services'
import type { IServiceBasePageData } from '../ServiceBasePageData'

// ************************************
// PUBLIC
// ************************************

export const fetchCoreDetails = async (
  translations: any,
  activeTheme: Theme,
  activeBrand: string,
  user: IUser,
  status: ServiceStatus,
  services: IServices,
  setInstallation?: (installation?: ICustomerInstallation) => void,
  addonStates?: IAddonData[]
): Promise<IDetailsDescriptionBlock[]> => {
  const { installation } = user

  let dynamicInfoBlock: IDescriptionBlock | undefined = undefined
  const detailCards: IDetailsDescriptionBlock[] = []

  if (translations?.serviceDescriptions && translations.serviceDescriptions.length > 0) {
    for (const statusDesc of translations.serviceDescriptions) {
      const desc = shouldUseDescription(statusDesc, status) ? statusDesc : undefined

      if (desc?.infoBlocks && desc.infoBlocks.length > 0) {
        for (const info of desc.infoBlocks) {
          if (info.blockId === Constants.services.dynamicInfoDetailsId) {
            dynamicInfoBlock = info
            break
          }
        }
      }
    }
  }

  const { points, hasCampaign } = await getCoreDetailPoints(translations, services, user, addonStates, dynamicInfoBlock)
  const campaignPlanks: IStatePlank[] = await _getCampaignPlanks(
    services,
    translations,
    user,
    activeTheme,
    setInstallation,
    addonStates
  )
  const additionalAddonPoints = await getAdditionalAddonServicesPoints(
    status,
    translations,
    user,
    services,
    addonStates
  )
  let pointsToUse: ICardPoint[] = points

  if (additionalAddonPoints && additionalAddonPoints.length > 0) {
    pointsToUse = additionalAddonPoints.concat(pointsToUse)
  }

  if (installation) {
    const activeFromDate = getAddonActiveFutureFromDate(installation, addonStates, translations.productDefinitionId)
    if (activeFromDate) {
      pointsToUse.push({
        leftText: getText('activeFrom', translations),
        rightText: activeFromDate
      })
    }
  }

  if (dynamicInfoBlock && pointsToUse) {
    detailCards.push({
      title: dynamicInfoBlock.title,
      description: dynamicInfoBlock.descriptionText,
      cardTheme: hasCampaign ? getCounterTheme(activeTheme) : activeTheme,
      brand: activeBrand,
      points: {
        title: getText('pageTitle', translations),
        points: pointsToUse,
        planks: campaignPlanks
      }
    })
  }

  return detailCards
}

export const getAdditionalAddonServicesPointsWrapper = async (
  config: IServiceBasePageData,
  status: ServiceStatus
): Promise<ICardPoint[] | undefined> => {
  return await getAdditionalAddonServicesPoints(
    status,
    config.translations,
    config.user,
    config.services,
    config.addonStates
  )
}

export const getAdditionalAddonServicesPoints = async (
  status: ServiceStatus,
  translations: any,
  user: IUser,
  services: IServices,
  addonStates?: IAddonData[]
): Promise<ICardPoint[] | undefined> => {
  const { installation } = user
  if (
    translations.serviceAdditionalAddonBlocks &&
    translations.serviceAdditionalAddonBlocks.length > 0 &&
    installation
  ) {
    return await _handleAdditionalAddonPoints(status, translations, services, user, addonStates)
  }
}

export const getAddonActiveFutureFromDate = (
  installation: ICustomerInstallation,
  addonStates: any,
  productDefinitionId: string
): string | undefined => {
  const addonState = getSpecificAddonStateForInstallation(productDefinitionId, installation?.meterId, addonStates)

  if (addonState?.state === 'ACTIVE_FUTURE' && addonState?.fromDate) {
    return format(new Date(addonState?.fromDate), 'dd.MM.yyyy')
  }
}

const _handleAdditionalAddonPoints = async (
  status: ServiceStatus,
  translations: any,
  services: IServices,
  user: IUser,
  addonStates?: IAddonData[]
): Promise<ICardPoint[]> => {
  const { installation } = user
  const { GETTYPED } = services

  let points: ICardPoint[] = []

  if (installation) {
    const activeExtraProductIds = parseActiveExtraProducts(installation, translations, addonStates)

    for (const additional of translations.serviceAdditionalAddonBlocks as IAdditonalAddonService[]) {
      if (additional.serviceId && additional.serviceId !== 'none') {
        const resp = await GETTYPED<IServicePrices>(`Services/prices/${additional.serviceId}/${installation.meterId}`)

        if (resp.data && resp.callState === 'success') {
          const subscribedToAdditionalProduct = activeExtraProductIds?.includes(Number.parseInt(additional.serviceId))
          const subscribedToPrimaryProduct: boolean = narrowDownResultStatusForPlank(status) === 'ACTIVE'

          const customerPrices: ICardPoint[] | undefined = _getCustomerPricePoints(resp.data)
          const productPrices: ICardPoint[] | undefined = _getProductPricePoints(resp.data)

          const { points: epiPoints, minimumTermsPoints } = _getCombinedEpiServerAndMimimumTermsPoints(
            translations,
            additional.dataPoints,
            resp.data.minimumTermDefinitions,
            true
          )
          if (subscribedToPrimaryProduct) {
            if (subscribedToAdditionalProduct) {
              points = points.concat(epiPoints)
              if (customerPrices) {
                points = points.concat(customerPrices)
              }
              if (minimumTermsPoints) {
                points = points.concat(minimumTermsPoints)
              }
            } else {
              // If we are not subscribed to the additional product,
              // but we ARE subscribed to the primary product,
              // we don't show anything related to the additional.
            }
          } else if (!subscribedToPrimaryProduct) {
            points = points.concat(epiPoints)
            if (subscribedToAdditionalProduct) {
              points = points.concat(minimumTermsPoints)
            } else {
              if (productPrices) points = points.concat(productPrices)
              points = points.concat(minimumTermsPoints)
            }
          }
        } else {
          points.push({
            leftText: getText('noDataFound', translations),
            rightText: '',
            customization: { color: BrandColors['status-shade-light-3'] }
          })
        }
      }
    }
  }

  return points
}

const _getCustomerPricePoints = (data: IServicePrices): ICardPoint[] | undefined => {
  if (data?.customerPrices && data.customerPrices.length > 0) {
    const points: ICardPoint[] = []

    for (const value of data.customerPrices) {
      points.push({
        subDetail: true,
        leftText: value.name,
        rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`
      })
    }

    return points
  }
}

const _getProductPricePoints = (data: IServicePrices): ICardPoint[] | undefined => {
  if (data?.productPrices && data.productPrices.length > 0) {
    const points: ICardPoint[] = []

    for (const value of data.productPrices) {
      points.push({
        subDetail: true,
        leftText: value.name,
        rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`
      })
    }

    return points
  }
}

/**
 * This function combines EpiServer data points and minimum term definitions to create an array of card points.
 * Minimum term points can both be
 * @param translations - An object containing translation strings
 * @param dataPoints - Optional array of IDataPoint objects from EpiServer
 * @param minimumTerms - Optional array of IAgreementMinimumTermTypeDefinition objects
 * @param subDetail - Boolean flag to indicate if the points are sub-details (default: false)
 * @returns An array of ICardPoint objects
 */
const _getCombinedEpiServerAndMimimumTermsPoints = (
  translations: any,
  dataPoints?: IDataPoint[],
  minimumTerms?: IAgreementMinimumTermTypeDefinition[],
  subDetail = false
) => {
  const points: ICardPoint[] = []
  const minimumTermsPoints: ICardPoint[] = []

  // Handle minimum term definitions
  if (minimumTerms && minimumTerms.length > 0) {
    for (const term of minimumTerms) {
      if (term.type === 'START') {
        minimumTermsPoints.push({
          id: 'bindingTime',
          subDetail,
          leftText: getText('bindingTime', translations),
          rightText: createString(getText('bindingTimeTermLength', translations), {
            months: daysToMonths(term.termDays),
            days: term.termDays
          }),
          extraLeftText: createString(getText('bindingTimeEnds', translations), {
            date: format(new Date(term.expiresAt), 'dd.MM.yyyy')
          })
        })
      } else if (term.type === 'TERMINATION') {
        minimumTermsPoints.push({
          id: 'cancellationTime',
          subDetail,
          leftText: getText('cancellationTime', translations),
          rightText: createString(getText('cancellationTimeTermLength', translations), {
            months: daysToMonths(term.termDays),
            days: term.termDays
          }),
          extraLeftText: createString(getText('cancellationTimeEnds', translations), {
            date: format(new Date(term.expiresAt), 'dd.MM.yyyy')
          })
        })
      }
      if (term.breachFee) {
        minimumTermsPoints.push({
          id: 'breachFee',
          subDetail,
          leftText: getText('breachFee', translations),
          rightText: `${tNumber(term.breachFee, 'no-NO')} ${term.breachFeeDenomination}`
        })
      }
    }
  }

  // Handle EpiServer data points
  if (dataPoints && dataPoints.length > 0) {
    dataPoints.forEach((point: IDataPoint) => {
      switch (point.key) {
        case 'title':
          points.push({
            leftText: point.name,
            rightText: undefined,
            subDetail
          })
          break
        case 'bindingTime':
        case 'cancellationTime':
        case 'breachFee':
          if (!minimumTermsPoints?.find(term => term.id === point.key)) {
            minimumTermsPoints.push({
              id: point.key,
              leftText: point.name,
              rightText: `${point.amount} ${point.amountSuffix}`,
              subDetail
            })
          }
          break
        default:
          points.push({
            leftText: point.name,
            rightText: point.amount ? `${point.amount} ${point.amountSuffix}` : `${point.amountSuffix}`,
            subDetail
          })
      }
    })
  }

  // add extra breach fee information text if breachfee is present
  if (minimumTermsPoints?.find(p => p.id === 'breachFee')) {
    const extraBreachFeeInformationText = getTextV2({
      key: 'breachFeeInformation',
      translations: translations,
      includeMissing: false
    })
    if (extraBreachFeeInformationText) {
      minimumTermsPoints.push({
        subDetail,
        extraLeftText: extraBreachFeeInformationText
      })
    }
  }

  return { points: points ?? [], minimumTermsPoints: minimumTermsPoints ?? [] }
}

// ************************************
// PRIVATE
// ************************************

const _getCampaignPlanks = async (
  services: IServices,
  translations: any,
  user: IUser,
  activeTheme: Theme,
  setInstallation?: (installation?: ICustomerInstallation) => void,
  addonStates?: IAddonData[]
) => {
  if (!addonStates && translations.orderMethodType !== OrderMethodTypeEnum.INSTALLATION) return []
  return await _getCampaignOnOtherInstallationsPlanks(
    services,
    translations,
    user,
    activeTheme,
    addonStates,
    setInstallation
  )
}

const _getCampaignOnOtherInstallationsPlanks = async (
  services: IServices,
  translations: any,
  user: IUser,
  activeTheme: Theme,
  addonStates?: IAddonData[],
  setInstallation?: (installation?: ICustomerInstallation) => void
): Promise<IStatePlank[]> => {
  const { userData } = user
  const { GETTYPED } = services

  const planks: IStatePlank[] = []

  for (const addon of addonStates ?? []) {
    if (addon.id === translations.productDefinitionId) {
      for (const inst of userData.installations.filter(inst => inst.meterId !== user.installation?.meterId)) {
        const installationAddonState = narrowDownResultStatusForPage(addon.state[inst.meterId]?.state)
        const resp = await GETTYPED<IServicePrices>(
          `Services/prices/${translations.productDefinitionId}/${inst.meterId}?contentId=${translations.epiContentId}`
        )

        if (installationAddonState && installationAddonState === 'INACTIVE' && resp.data?.campaignEnd) {
          planks.push(
            getPlankPrefab('Action', {
              theme: getCounterTheme(activeTheme),
              right: {
                template: MS_ButtonTemplate(activeTheme, 'secondary')
              },
              left: {
                title: getText('plankCampaignAvailableTitle', translations),
                description: inst.address.streetAddress
              },
              action: {
                text: getText('plankCampaignAvailableValue', translations),
                onClick: () => {
                  if (setInstallation) {
                    setInstallation(inst)
                  }
                }
              }
            })
          )
        }
      }
    }
  }
  return planks
}

export const getCoreDetailPointsWrapper = async (config: IServiceBasePageData, infoBlock?: IDescriptionBlock) =>
  await getCoreDetailPoints(config.translations, config.services, config.user, config.addonStates, infoBlock)

export const getCoreDetailPoints = async (
  translations: any,
  services: {
    GETTYPED: typedGetRequest
  },
  user: {
    installation?: ICustomerInstallation
  },
  addonStates?: IAddonData[],
  infoBlock?: IDescriptionBlock
) => {
  const { installation } = user
  const { GETTYPED } = services

  let points: ICardPoint[] = []
  let data: IServicePrices | null = null
  let resp: ITypedResponse<IServicePrices> = { callState: 'idle', data: null }
  let hasCampaign = false

  if (translations.servicePageId !== 'trumf' && translations.servicePageId !== 'forutsigbar' && installation) {
    resp = await GETTYPED<IServicePrices>(
      `Services/prices/${translations.productDefinitionId}/${installation.meterId}?contentId=${translations.epiContentId}`
    )

    points.push({
      id: 'installationAddressPlank',
      leftText: getText('address', translations),
      rightText: installation?.address?.streetAddress ?? ''
    })

    if (resp.callState === 'success' && resp.data) {
      data = resp.data

      const campaignEnd: string | null | undefined = data?.campaignEnd
      hasCampaign = !!campaignEnd

      points = points.concat(
        _getCostPoints(
          translations,
          _getPriceRightTextParsed({
            translations,
            addonStates,
            data,
            end: campaignEnd,
            installation
          })
        )
      )

      const { points: additionalPoints, minimumTermsPoints } = _getCombinedEpiServerAndMimimumTermsPoints(
        translations,
        infoBlock?.dataPoints,
        resp.data.minimumTermDefinitions,
        false
      )

      points = points.concat(additionalPoints)
      points = points.concat(minimumTermsPoints)
    } else if (resp.callState === 'error') {
      points = [
        {
          leftText: getText('noDataFound', translations),
          rightText: '',
          customization: { color: BrandColors['status-shade-light-3'] }
        }
      ]
    }
  }

  return { points, hasCampaign }
}

interface IPriceRightTextParsed {
  translations: any
  addonStates?: IAddonData[]
  data: IServicePrices | null
  end?: string | null
  installation?: ICustomerInstallation
}

const _getPriceRightTextParsed = (props: IPriceRightTextParsed) => {
  const { translations, addonStates, data, end, installation } = props

  let rightText = ''
  const campaignEnd: string | undefined = end ?? undefined
  let futureTextRight = ''
  let futurePriceStarts: string | undefined = undefined
  const status: 'ACTIVE' | 'INACTIVE' = getClampedStatusFromInstallation({
    installationOnly: translations.orderMethodType === 'installation',
    translations,
    installation,
    addonStates
  })

  if (status === 'ACTIVE' && data?.customerPrices && data.customerPrices.length > 0) {
    data.customerPrices.forEach((p: ICustomerProductPrice) => {
      rightText += `${tNumber(_getParsedCustomerPointPrice(p, end), 'no-NO')} ${p.denomination}\n`

      const { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination)

      futureTextRight += text
      futurePriceStarts = from
    })
  } else {
    data?.productPrices?.forEach((p: IProductPrice) => {
      rightText += `${tNumber(p.vatInclusivePrice, 'no-NO')} ${p.denomination}\n`

      const { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination)

      futureTextRight += text
      futurePriceStarts = from
    })
  }

  return { rightText, campaignEnd, futureTextRight, futurePriceStarts }
}

const _getParsedCustomerPointPrice = (priceData: ICustomerProductPrice, ends?: string | null) => {
  if (ends && new Date(ends) > new Date()) {
    return priceData.vatInclusiveDiscountedPrice
  }
  return priceData.vatInclusivePrice
}

const _getFuturePriceTexts = (futurePrices: IFuturePrice[], denomination: string) => {
  let text = ''
  let from = ''

  if (futurePrices && futurePrices.length > 0) {
    futurePrices.forEach((fp: IFuturePrice) => {
      text += `${tNumber(fp.vatInclusivePrice, 'no-NO')} ${denomination}\n`
      from = fp.from
    })
  }

  return { text, from }
}
const _getCostPoints = (
  servicePage: IServicePage,
  right: {
    rightText: string
    futureTextRight: string
    campaignEnd?: string
    futurePriceStarts?: string
  },
  isSub = false
) => {
  const { rightText, futureTextRight, campaignEnd, futurePriceStarts } = right
  const points: ICardPoint[] = []
  const now = new Date()

  // Campaign
  if (campaignEnd && new Date(campaignEnd) > now) {
    points.push({
      subDetail: isSub,
      leftText: getText('campaignPrice', servicePage),
      rightText,
      extraLeftText: createString(getText('campaignPriceEnds', servicePage), {
        date: format(new Date(campaignEnd), 'dd.MM.yyyy')
      })
    } as ICardPoint)
  }

  // Future price
  if (futurePriceStarts) {
    const futurePriceStartsDate: Date = new Date(futurePriceStarts)

    points.push({
      subDetail: isSub,
      leftText: getText('futurePrice', servicePage),
      rightText: futureTextRight,
      extraLeftText: createString(getText('futurePriceStarts', servicePage), {
        date: format(futurePriceStartsDate, 'dd.MM.yyyy')
      })
    } as ICardPoint)
  }

  // General price
  if (rightText.length > 0 && (!campaignEnd || new Date(campaignEnd) <= now)) {
    points.push({
      subDetail: isSub,
      leftText: getText('cost', servicePage),
      rightText
    } as ICardPoint)
  }

  return points
}

export const shouldUseDescription = (desc: IServiceStatusDescriptions, status: ServiceStatus) => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return desc.status === ServiceStatusDescriptionEnum.SUBSCRIBED
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
    case ServiceStatusEnum.ACTIVATING:
      return desc.status === ServiceStatusDescriptionEnum.UNSUBSCRIBED
  }
}
