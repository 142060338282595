import { ContentGrid, IconType, StyleGrid, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { MS_ButtonTemplate, h4TextPrefab, paragraphTextPrefab } from '../../../../Prefabs'
import WebshopSvg from '../../../../assets/art/benefits/usePoints-webshop.svg?react'
import { ActionButton, Card } from '../../../../components'
import { Constants } from '../../../../data'
import { getText } from '../../../../services'
import { type IDefaultViewProps, PageV2 } from '../../../PageV2'
import { getPageContent } from './UsePointsWebshopPageData'
import './UsePointsWebshopPage.scss'

export interface IUsePointsWebshopPage extends IDefaultViewProps {}

export const UsePointsWebshopPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'use-points-webshop-page'

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.benefits.type,
      usesSubPage: true
    },
    handleData: getPageContent,
    render: (config: IUsePointsWebshopPage) => {
      const { activeBrand, activeTheme, translations } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          alignment={'center'}
          direction={'row'}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          <Card className={`${classPrefix}__card`} brand={activeBrand} theme={activeTheme} alignment={'center'}>
            <ContentGrid
              className={`${classPrefix}__card__content`}
              alignment={'top-center'}
              direction={'column'}
              tagType={'section'}
              gap={4}
            >
              <Text {...h4TextPrefab()} brand={activeBrand} align={'align-center'} theme={getCounterTheme(activeTheme)}>
                {getText('webshopPageTitle', translations)}
              </Text>
              <WebshopSvg width={100} height={100} />
              <Text
                {...paragraphTextPrefab()}
                align={'align-center'}
                brand={activeBrand}
                theme={getCounterTheme(activeTheme)}
              >
                {getText('webshopPageDescription', translations)}
              </Text>
              <ContentGrid direction='column' alignment='center' gap={2} tagType='nav'>
                <Text {...paragraphTextPrefab()} weight={500} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
                  {getText('webshopPageRememberLogin', translations)}
                </Text>
                <ActionButton
                  template={MS_ButtonTemplate(activeTheme, 'primary')}
                  brand={activeBrand}
                  iconPlacement={'Right'}
                  padding='medium'
                  action={{
                    link: getText('webshopPageButtonLink', translations),
                    icon: IconType.ArrowRight,
                    text: getText('webshopPageButtonText', translations)
                  }}
                />
              </ContentGrid>
            </ContentGrid>
          </Card>
        </StyleGrid>
      )
    }
  })
}
