import { useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAddonServicesContext, useApplicationContext } from '../../../contexts'
import { type IServiceManagementPopup, type IServiceOrderOrCancel, ServiceManagementPopup } from '../../../modals'
import { type ServiceOrderSteps, ServiceOrderStepsEnum, type ServiceStatus, ServiceTypesEnum } from '../../../models'
import { getStatusWrapper } from '../../../services'
import { type IServiceBasePageView, ServiceBasePage } from './ServiceBasePage'
import { type IServiceBasePageData, getServiceBasePageData } from './ServiceBasePageData'
import { SteddiServicePage } from './SteddiServicePage'
import { TrumfServicePage } from './TrumfServicesPage'

export const ServicePage = () => {
  // ************************************
  // Properties
  // ************************************

  const { activeBrand, activeTheme } = useApplicationContext()
  const { updateAddonStates } = useAddonServicesContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [popupData, setPopupData] = useState<IServiceManagementPopup>()
  const [queryParams, setQueryParams] = useSearchParams()
  const { serviceId } = useParams()

  // ************************************
  // Data handling
  // ************************************

  // Function: Handles data based on various properties and parses into view data
  const _handlePageData = async (config: IServiceBasePageData) => {
    const viewData: IServiceBasePageView | undefined = await getServiceBasePageData({
      ...config,
      ...{
        onClickServiceHandling: (value: IServiceOrderOrCancel) => {
          setPopupData(value)
        }
      }
    })

    if (viewData) {
      await _handleQueryParamStatus(viewData, config)
    }

    return viewData
  }

  // Function: If returning from or has set parameters, popup will appear
  const _handleQueryParamStatus = async (viewData: IServiceBasePageView, config: IServiceBasePageData) => {
    let queryParamState = queryParams?.get('orderStatus') as ServiceOrderSteps | undefined

    if (
      (queryParamState === ServiceOrderStepsEnum.SUCCESS || queryParamState === ServiceOrderStepsEnum.FAILURE) &&
      viewData
    ) {
      const { translations, user, account } = config
      const { userData, installation } = user
      const status: ServiceStatus = await getStatusWrapper(config)

      if (queryParamState === ServiceOrderStepsEnum.SUCCESS) queryParamState = ServiceOrderStepsEnum.REDIRECT_SUCCESS

      setPopupData({
        status,
        installation,
        userData,
        account,
        page: translations,
        predefinedStatusStep: queryParamState
      })
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderServiceManagementPopup = useMemo(() => {
    if (popupData) {
      return (
        <ServiceManagementPopup
          {...popupData}
          brand={activeBrand}
          theme={activeTheme}
          onClose={() => {
            setQueryParams(undefined)
            setPopupData(undefined)
            updateAddonStates()
          }}
        />
      )
    }
  }, [popupData, activeBrand, activeTheme])

  // ************************************
  // Render
  // ************************************

  const _getServicePage = () => {
    if (serviceId === ServiceTypesEnum.TRUMF) return <TrumfServicePage />
    if (serviceId === ServiceTypesEnum.FORUTSIGBAR) return <SteddiServicePage />
    return (
      <>
        <ServiceBasePage handleData={_handlePageData} />
        {_renderServiceManagementPopup}
      </>
    )
  }

  return <>{_getServicePage()}</>
}
