import { ContentGrid, type Theme } from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { MSCheckInputTemplate, getPlankPrefab } from '../../../Prefabs'
import type { ICheckInputPlankPrefab } from '../../../Prefabs/Planks/CheckInputPlankPrefab/CheckInputPlankPrefab'
import { RadioPlankWall } from '../../../blocks'
import {
  type IAddonData,
  type ITranslationItem,
  type OnClickServiceOrderAction,
  OnClickServiceOrderActionEnum
} from '../../../models'
import { hasText } from '../../../services'
import { ContentText } from './ContentText'

export const OrderOrCancelStep = ({
  theme,
  brand,
  texts,
  classPrefix,
  productDefinitionId,
  addonStates,
  selectedOption,
  onClick
}: {
  theme: Theme
  brand?: string
  texts: ITranslationItem[]
  classPrefix: string
  productDefinitionId: string
  addonStates?: IAddonData[]
  selectedOption: OnClickServiceOrderAction
  onClick: (value: OnClickServiceOrderAction) => void
}) => {
  const _canOrderOrCancelBasedOnInstallations = (productDefinitionId: string, addonStates?: IAddonData[]) => {
    const foundAddon: IAddonData | undefined = _.find(addonStates, (state: IAddonData) => {
      return state.id === productDefinitionId
    })

    let canOrder = true
    let canCancel = false

    if (foundAddon) {
      if (!canCancel) {
        canCancel = foundAddon.hasActiveInstallation
      }

      if (canOrder && foundAddon.allInstallationsActive) {
        canOrder = !foundAddon.allInstallationsActive
      }
    }

    return { canOrder, canCancel }
  }

  const getOrderPlank = (isActive: boolean, texts: ITranslationItem[]) => {
    return getPlankPrefab('CheckInput', {
      left: {
        title: hasText('confirmOrderPlankTitle', texts),
        description: hasText('confirmOrderPlankDesc', texts)
      },
      value: OnClickServiceOrderActionEnum.ORDER,
      id: '0',
      disabled: !isActive,
      radioTemplate: MSCheckInputTemplate(theme, 'radio')
    } as ICheckInputPlankPrefab) as ICheckInputPlankPrefab
  }

  const getCancelPlank = (isActive: boolean, texts: ITranslationItem[]) => {
    return getPlankPrefab('CheckInput', {
      left: {
        title: hasText('confirmCancelPlankTitle', texts),
        description: hasText('confirmCancelPlankDesc', texts)
      },
      value: OnClickServiceOrderActionEnum.CANCEL,
      id: '1',
      disabled: !isActive,
      radioTemplate: MSCheckInputTemplate(theme, 'radio')
    } as ICheckInputPlankPrefab) as ICheckInputPlankPrefab
  }

  const { canOrder, canCancel } = _canOrderOrCancelBasedOnInstallations(productDefinitionId, addonStates)
  const orderPlank = getOrderPlank(canOrder, texts)
  const cancelPlank = getCancelPlank(canCancel, texts)

  return (
    <ContentGrid
      className={`${classPrefix}__content`}
      alignment='top-left'
      direction='column'
      gap={2}
      tagType='section'
    >
      <ContentText textKey={'confirmDescription'} texts={texts} />
      <RadioPlankWall
        theme={theme}
        brand={brand}
        planks={[orderPlank, cancelPlank]}
        initialActiveIndex={selectedOption === OnClickServiceOrderActionEnum.ORDER ? 0 : 1}
        usePlankDisabled={true}
        onSelectItem={onClick}
      />
    </ContentGrid>
  )
}
