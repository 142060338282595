import type { CallState } from '@fjordkraft/fjordkraft.component.library'
import { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { CallStateToast } from '../../components/Toastify/Toastify'
import { useApplicationCoreDataContext, useApplicationDefaultContext } from '../../contexts'
import { Constants } from '../../data'
import {
  CustomerInfoEditorModal,
  DigitVerificationModal,
  type ICustomerEditorInformation,
  type IDigitVerificationModal
} from '../../modals'
import { capitalizeFirstLetter, getText } from '../../services'

export const ApplicationUserEdit = (props: any) => {
  // ************************************
  // Properties
  // ************************************

  const { defaultProps, activeAccount } = useApplicationDefaultContext()
  const { updateCustomerData, setCustomerDataToEdit, customerDataToEdit } = useApplicationCoreDataContext()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  // ************************************
  // Lifecycle
  // ************************************

  const [digitPopupData, setDigitPopupData] = useState<IDigitVerificationModal>()
  const [changeState, setChangeState] = useState<CallState>('idle')

  // ************************************
  // userData Info Handling
  // ************************************

  const onSubmitFormChange = (customerInformation: ICustomerEditorInformation) => {
    if (customerInformation) {
      setChangeState('pending')

      const invoiceSettingsFromMyPagePath = `${Constants.paths.userPage}${Constants.paths.invoiceSettings}`
      const invoiceSettingsFromInvoicePagePath = `${Constants.paths.invoicePage}${Constants.paths.invoiceSettings}`

      if (pathname === invoiceSettingsFromMyPagePath || pathname === invoiceSettingsFromInvoicePagePath) {
        if (customerInformation.email && customerInformation.email.length > 0) {
          _updateInvoiceEmail(customerInformation.email)
        } else if (
          customerInformation.address &&
          customerInformation.postalCode &&
          customerInformation.postalLocation
        ) {
          _updateInvoiceAgreementAddress(
            customerInformation.address,
            customerInformation.postalCode,
            customerInformation.postalLocation
          )
        }
      } else if (customerInformation.email && customerInformation.email.length > 0) {
        _updateEmail(customerInformation.email)
      } else if (customerInformation.phoneNumber && customerInformation.phoneNumber.length > 0) {
        _updatePhone(customerInformation.phoneNumber)
      } else if (customerInformation.residenceName && customerInformation.residenceName.length > 0) {
        _updateResidenceName(customerInformation.residenceName)
      }
    }
  }

  // ************************************
  // Field Handlers
  // ************************************

  const _updateEmail = async (newValue: string) => {
    const { PUT } = defaultProps.services

    const resp = await PUT('Customers/email/update', newValue)
    setChangeState('idle')
    await updateCustomerData(true)
    _handleToasts(resp.callState, 'Email')
    setCustomerDataToEdit(undefined)
  }
  const _updateResidenceName = async (residenceName: string) => {
    const meterId = searchParams.get('meterId')
    const { PUT } = defaultProps.services
    const resp = await PUT('Residence/updateResidenceName', { meterId, residenceName })

    setChangeState('idle')
    await updateCustomerData(true)
    _handleToasts(resp.callState, 'residenceName')
    setCustomerDataToEdit(undefined)
  }

  const _updateInvoiceEmail = async (newValue: string) => {
    const { POST } = defaultProps.services

    const resp = await POST(`Account/${_getAccountId()}/updateCustomerData`, {
      email: newValue
    })

    setChangeState('idle')
    await updateCustomerData(true)
    _handleToasts(resp.callState, 'InvoiceEmail')
  }

  const _updateInvoiceAgreementAddress = async (address: string, postalCode: string, postalLocation: string) => {
    const { POST } = defaultProps.services

    const addressInfo: string[] = address.split(/(?<street>\D+)?\s*?(?<house>\d+)?\s?(?<letter>\D+)?/)

    const resp = await POST(`Account/${_getAccountId()}/updateCustomerData`, {
      streetName: addressInfo[1],
      streetNumber: addressInfo[2],
      streetletter: addressInfo[3],
      zip: postalCode,
      city: postalLocation
    })

    setChangeState('idle')
    await updateCustomerData(true)
    _handleToasts(resp.callState, 'InvoiceAddress')
  }

  const _updatePhone = async (newValue: string) => {
    const { translations, services } = defaultProps
    const { PUT } = services

    await PUT('Customers/initiateChangePhoneNumber', newValue)

    setDigitPopupData({
      translation: translations,
      verified: async (callState: CallState) => {
        await updateCustomerData(true)
        setChangeState('idle')
        _handleToasts(callState, 'Phone')
        setDigitPopupData(undefined)
      },
      onClose: () => {
        setDigitPopupData(undefined)
      },
      changedValue: newValue
    })

    setCustomerDataToEdit(undefined)
  }

  const _handleToasts = (callState: CallState, type: string) => {
    const { translations } = defaultProps

    CallStateToast({
      text: getText(`update${type}${capitalizeFirstLetter(callState)}`, translations),
      callState: callState
    })

    setCustomerDataToEdit(undefined)
  }

  // ************************************
  // Helpers
  // ************************************

  const _getAccountId = () => {
    const { userData } = defaultProps.user

    if (activeAccount?.accountId) {
      return activeAccount?.accountId
    }
    if (userData?.accounts[0].accountId) {
      return userData?.accounts[0].accountId
    }
    return ''
  }

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {customerDataToEdit ? (
        <CustomerInfoEditorModal
          theme={defaultProps.activeTheme}
          brand={defaultProps.activeBrand}
          title={customerDataToEdit.title}
          topText={customerDataToEdit.topText}
          bottomText={customerDataToEdit.bottomText}
          fields={customerDataToEdit.fields}
          callState={changeState}
          onClose={() => {
            setChangeState('idle')
            setCustomerDataToEdit(undefined)
          }}
          onSubmitFormChange={(fields: ICustomerEditorInformation) => {
            onSubmitFormChange(fields)
          }}
        />
      ) : null}
      {digitPopupData && defaultProps.translations && <DigitVerificationModal {...digitPopupData} />}
      {props.children}
    </>
  )
}
