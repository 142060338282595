import {
  BrandColors,
  ContentGrid,
  type IAction,
  IconType,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import {
  LocationNavigationInfoBoxTemplate,
  MS_ButtonTemplate,
  h1TextPrefab,
  h3TextPrefab,
  paragraphTextPrefab
} from '../../Prefabs'
import { ActionButton, CrumbButton, LocationNavigationInfoBox, MSRichText, MSTooltip } from '../../components'
import type { ISliderItemData } from '../../components/SliderSelector/SliderItem'
import { SliderSelector } from '../../components/SliderSelector/SliderSelector'
import { useApplicationDefaultContext } from '../../contexts'
import type { ICustomerInstallation, ILocationNavigationInfoBoxContent } from '../../models'
import { isExternalUrl } from '../../services'
import './LocationNavHeaderV2.scss'

export type LocationNavHeaderProps = {
  title?: string
  subTitle?: string
  description?: string
  descriptionTooltip?: string
  infoBoxContent?: ILocationNavigationInfoBoxContent
  back?: IAction
  showInstallationSelector?: boolean
}

const _handleBackButtonFunctionality = (back: IAction) => {
  return {
    ...back,
    ...({
      icon: IconType.ArrowLeft,
      useRouterLink: !isExternalUrl(back.link)
    } as IAction)
  }
}

const _getInstallationSelection = (
  inst: ICustomerInstallation[],
  classPrefix: string,
  activeInstallation?: ICustomerInstallation | null,
  defaultProps?: any
) => {
  const selection: any[] = []

  if (defaultProps) {
    inst.forEach((installation: ICustomerInstallation) => {
      selection.push({
        children: (
          <ContentGrid
            alignment={'center'}
            direction={'row'}
            wrap={false}
            gap={2}
            tagType='section'
            boxSizing='border-box'
          >
            <Text
              className={`${classPrefix}__slider-selector-text`}
              type={'label'}
              weight={500}
              size={'regular'}
              brand={defaultProps.activeBrand}
              theme={getCounterTheme(defaultProps.activeTheme)}
              wrap={'nowrap'}
              faded={installation !== activeInstallation}
            >
              {installation.address.streetAddress}
            </Text>
          </ContentGrid>
        ),
        value: installation
      } as ISliderItemData)
    })
  }

  return selection
}

const _renderAddressSlider = (classPrefix: string, defaultProps: any, showInstallationSelector?: boolean) => {
  if (defaultProps) {
    const { user, activeTheme } = defaultProps
    const { installation, userData, setInstallation } = user

    const installations: ICustomerInstallation[] | undefined = userData?.installations
    if (!installations) return null

    if (showInstallationSelector) {
      if (installations.length > 1) {
        return (
          <SliderSelector
            className={`${classPrefix}__slider-selector`}
            uniqueId={`${classPrefix}__slider-selector`}
            data={_getInstallationSelection(installations, classPrefix, installation, defaultProps)}
            defaultOption={installation}
            slideToSelect={false}
            width={550}
            mobileWidth={document.body.clientWidth * 0.9}
            onValueChange={value => {
              if (value) {
                setInstallation(value)
              }
            }}
          />
        )
      }
      return (
        <Text
          className={`${classPrefix}__top__address`}
          {...paragraphTextPrefab()}
          weight={500}
          theme={getCounterTheme(activeTheme)}
          wrap={'nowrap'}
        >
          {installations[0].address.streetAddress}
        </Text>
      )
    }
  }
}

export const LocationNavHeaderV2 = (props: LocationNavHeaderProps) => {
  const { title, subTitle, description, descriptionTooltip, infoBoxContent, back, showInstallationSelector } = props
  const { defaultProps } = useApplicationDefaultContext()
  const [fadingInInfoBox, setFadingInInfoBox] = useState<boolean>(false)
  const [displayingInfoBox, setDisplayingInfoBox] = useState<boolean>(false)

  const infoBoxGap: number = 25
  const fadeTimeInSeconds = 0.5

  let infoBoxHeight = 0
  const infoBox = document.querySelector('.location-navigation-info-box')

  if (infoBox && fadingInInfoBox) {
    infoBoxHeight = infoBox.clientHeight + infoBoxGap
  }

  const toggleInfoBox = (instant = false) => {
    // This function sets the fadingIn and hide classes to the infobox.
    // We need to "hide" it, after the animation is done, so that the buttons can't be clicked.
    if (displayingInfoBox) {
      if (instant) setDisplayingInfoBox(false)
      else setTimeout(() => setDisplayingInfoBox(false), fadeTimeInSeconds * 1000)
    } else setDisplayingInfoBox(true)
    setFadingInInfoBox(!fadingInInfoBox)
  }

  useEffect(() => {
    if (displayingInfoBox) {
      toggleInfoBox(true)
    }
  }, [infoBoxContent])

  const baseTemplate = LocationNavigationInfoBoxTemplate(defaultProps.activeTheme)

  const template = {
    ...baseTemplate,
    transform: {
      ...baseTemplate.transform,
      padding: undefined,
      width: undefined,
      transition: undefined,
      height: undefined
    }
  }

  const classPrefix = 'location-nav-header'
  return (
    <div className={classPrefix} style={{ marginBottom: infoBoxHeight }}>
      {back?.text && back?.link && (
        <CrumbButton
          className={`${classPrefix}__header__back-button`}
          theme={getCounterTheme(defaultProps.activeTheme)}
          brand={defaultProps.activeBrand}
          action={_handleBackButtonFunctionality(back)}
        />
      )}
      <div className={`${classPrefix}__title`}>
        <Text
          className={`${classPrefix}__title__text`}
          {...h1TextPrefab()}
          size={defaultProps.desktopView ? 'huge' : 'extra-large'}
          brand={defaultProps.activeBrand}
          color={BrandColors['primary-shade-light-2']}
          align={defaultProps.desktopView ? 'align-center' : 'align-left'}
        >
          {title}
        </Text>
        {infoBoxContent?.hasInfo && (
          <ActionButton
            className={`${classPrefix}__title__icon`}
            template={MS_ButtonTemplate(defaultProps.activeTheme, 'icon')}
            brand={defaultProps.activeBrand}
            action={{
              onClick: () => {
                toggleInfoBox()
              },
              icon: IconType.MoreInfo
            }}
          />
        )}
      </div>
      {subTitle && subTitle.length > 0 && (
        <Text
          className={`${classPrefix}__sub-title`}
          {...h3TextPrefab()}
          size='medium'
          brand={defaultProps.activeBrand}
          theme={getCounterTheme(defaultProps.activeTheme)}
          align={defaultProps.desktopView ? 'align-center' : 'align-left'}
        >
          {subTitle}
        </Text>
      )}
      {description && (
        <div className={`${classPrefix}__description-container`}>
          {description && description.length > 0 && (
            <MSRichText
              className={`${classPrefix}__description-container__description`}
              brand={defaultProps.activeBrand}
              theme={getCounterTheme(defaultProps.activeTheme)}
              text={description}
              alignment='lawful'
              customization={{
                type: 'p',
                align: 'align-center',
                weight: 500
              }}
            />
          )}
          {descriptionTooltip && (
            <MSTooltip
              className={`${classPrefix}__description-container__info`}
              brand={defaultProps.activeBrand}
              theme={getCounterTheme(defaultProps.activeTheme)}
              text={descriptionTooltip}
              alignment={defaultProps.desktopView ? 'bottom-center' : 'bottom-left'}
            />
          )}
        </div>
      )}
      {_renderAddressSlider(classPrefix, defaultProps, showInstallationSelector)}
      {infoBoxContent?.hasInfo && (
        <div
          className={classnames('info-box-container', {
            ['info-box-container--fadingIn']: fadingInInfoBox,
            ['info-box-container--hide']: !displayingInfoBox
          })}
        >
          <LocationNavigationInfoBox
            template={template}
            className={`${classPrefix}__info-box`}
            text={infoBoxContent.infoText}
            title={infoBoxContent.infoTitle}
            buttons={infoBoxContent.buttons}
            closeButtonOnClickFunc={toggleInfoBox}
            displayingInfoBox={displayingInfoBox}
            fadingInInfoBox={fadingInInfoBox}
          />
        </div>
      )}
    </div>
  )
}
