import {
  Button,
  type IAction,
  type IComponent,
  type IComponentTemplate,
  Icon,
  IconType,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'
import { useApplicationContext } from '../../../contexts'

export interface IArrowButton extends IComponent {
  useTextUnderline?: boolean
  iconLocation: 'left' | 'right'
  action: IAction
  template?: IComponentTemplate
}

/**
 *    The ArrowButton is used to -FILL IN-
 *    @type {string} text
 */
export const ArrowButton = (props: IArrowButton) => {
  // ************************************
  // Properties
  // ************************************

  const { activeBrand } = useApplicationContext()
  const {
    className,
    useTextUnderline,
    iconLocation,
    action,
    disabled,
    theme = 'Light',
    template = MS_ButtonTemplate(theme, 'arrow')
  } = props

  const _renderIcon = (icon: IconType) => {
    return <Icon brand={activeBrand} type={icon} />
  }

  // ************************************
  // Render
  // ************************************
  return (
    <Button
      className={className}
      brand={activeBrand}
      action={action ? action : {}}
      disabled={disabled}
      template={template}
    >
      {iconLocation === 'left' && _renderIcon(IconType.ArrowLeft)}
      <Text brand={activeBrand} {...paragraphTextPrefab()} weight={600} underline={useTextUnderline}>
        {action.text}
      </Text>
      {iconLocation === 'right' && _renderIcon(IconType.ArrowRight)}
    </Button>
  )
}
