import { BrandColors, type IComponentTransform } from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: 'auto',
  outline: {
    type: 'dashed',
    thickness: 2
  },
  grid: {
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center',
    gap: 1
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-dark-2'], opacity: 0.8 },
      icon: { color: BrandColors['text-shade-dark-2'], opacity: 0.8 },
      hover: {
        background: { color: BrandColors['transparent'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['text-shade-dark-3'] }
      },
      focus: {
        background: { color: BrandColors['transparent'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['text-shade-dark-3'] },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: { color: BrandColors['transparent'] },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        outline: { color: BrandColors['transparent'] }
      }
    },
    Dark: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-light-1'] },
      icon: { color: BrandColors['text-shade-light-1'] }
    }
  }
}

// ************************************
// Export
// ************************************

export const BUTTON_TEMPLATE_ARROW = {
  themedPalette: _palette,
  transform: _transform
}
