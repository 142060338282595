import type { IAction, IComponent, IOption } from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import type { IPlankHouse } from '../../blocks'
import { useAddonServicesContext, useApplicationContext, useApplicationServicehandlerContext } from '../../contexts'
import {
  type IAdditonalAddonService,
  type ICustomer,
  type ICustomerAccountInformation,
  type ICustomerInstallation,
  type IServicePage,
  type IServicePopupBlock,
  type IServicePopupCheckbox,
  type IServiceStatusDescriptions,
  type OnClickServiceOrderAction,
  type ServiceOrderSteps,
  ServiceOrderStepsEnum,
  type ServiceStatus,
  ServiceStatusDescriptionEnum
} from '../../models'
import { getText, handleServiceOrderAndCancel } from '../../services'
import { PopupCard } from '../PopupCard/PopupCard'
import './ServiceManagementPopup.scss'
import { PopupContent } from './components/PopupContent'
import {
  getInformationStepHouse,
  getInitialStep,
  getServiceDescriptionForStatus,
  initialSelectOption,
  serviceStatusToDescription
} from './utils'

export interface IServiceOrderOrCancel extends Omit<IServiceManagementPopup, 'onClose'> {}

export interface IServiceManagementPopup extends IComponent {
  installation?: ICustomerInstallation
  userData?: ICustomer
  account?: ICustomerAccountInformation
  status: ServiceStatus
  page: IServicePage
  addonStateResponse?: any
  predefinedStatusStep?: ServiceOrderSteps
  selectedSubService?: IOption
  onClose?: () => void
}

export const ServiceManagementPopup = (props: IServiceManagementPopup) => {
  // ************************************
  // Properties
  // ************************************

  const {
    page,
    theme = 'Light',
    brand,
    status,
    predefinedStatusStep,
    installation,
    selectedSubService,
    onClose
  } = props
  const classPrefix = 'service-stage-popup'
  const { GET, GETTYPED, POST } = useApplicationServicehandlerContext()
  const { addonStates, updateAddonStates } = useAddonServicesContext()
  const { desktopView } = useApplicationContext()

  // ************************************
  // Initial setup
  // ************************************

  const _getInitialClickAllowState = (step: ServiceOrderSteps): boolean =>
    step === ServiceOrderStepsEnum.SELECT || !checkboxStates

  // ************************************
  // Lifecycle
  // ************************************

  const [currentStep, setCurrentStep] = useState<ServiceOrderSteps>(getInitialStep(predefinedStatusStep))
  const [selectedOption, setSelectedOption] = useState<OnClickServiceOrderAction>(initialSelectOption[status])
  const [popupStatusData, setPopupStatusData] = useState<IServicePopupBlock>()
  const [checkboxStates, setCheckboxStates] = useState<IServicePopupCheckbox[]>()
  const [allowOrderOrCancelClick, setAllowOrderOrCancelClick] = useState<boolean>(
    _getInitialClickAllowState(currentStep)
  )

  useEffect(() => {
    if (page && status) {
      _handlePopupData(page, status)
    }
  }, [page, status])

  // Checks if all checkboxes are active before enabling onclick
  useEffect(() => {
    if (checkboxStates && checkboxStates.length > 0 && currentStep === ServiceOrderStepsEnum.DEFAULT) {
      let allActive = false
      checkboxStates.forEach((checkbox: IServicePopupCheckbox) => {
        allActive = checkbox.alreadyChecked
      })
      setAllowOrderOrCancelClick(allActive)
    } else if (currentStep === ServiceOrderStepsEnum.SELECT) {
      setAllowOrderOrCancelClick(true)
    }
  }, [checkboxStates, currentStep])

  const _handlePopupData = async (page: IServicePage, status: ServiceStatus) => {
    if (page && status) {
      const data: IServiceStatusDescriptions | undefined = getServiceDescriptionForStatus(
        status,
        page.serviceDescriptions
      )

      if (data?.servicePopupBlock) {
        const isSubbed: boolean = serviceStatusToDescription(status) === ServiceStatusDescriptionEnum.SUBSCRIBED
        const hasSubscription = serviceStatusToDescription(status) === ServiceStatusDescriptionEnum.SUBSCRIBED
        const house: IPlankHouse = await getInformationStepHouse(hasSubscription, {
          serviceDescriptions: page.serviceDescriptions,
          status,
          page,
          installation,
          GETTYPED,
          addonStates,
          selectedSubService
        })

        if (house) {
          data.servicePopupBlock.house = house
        }

        if (selectedSubService) {
          const relevantExtraDesc = _getRelevantExtraService(selectedSubService.value)

          if (relevantExtraDesc) {
            data.servicePopupBlock.selectedExtraProductDesc = isSubbed
              ? relevantExtraDesc.textSubbed
              : relevantExtraDesc.textUnSubbed
          }
        }

        setPopupStatusData(data?.servicePopupBlock)

        if (data?.servicePopupBlock?.checkboxes && !checkboxStates) {
          // The data should rather be reset on close of component instead of on open, but choosing easy fix for now
          const notAcceptedCheckboxes = data.servicePopupBlock.checkboxes.map(e => ({
            id: uuid4(),
            ...e,
            alreadyChecked: false
          }))
          setCheckboxStates(notAcceptedCheckboxes)
        }
      }
    }
  }

  // ************************************
  // Getters
  // ************************************

  const _getRelevantExtraService = (id: string): IAdditonalAddonService | undefined => {
    return _.find(page.serviceAdditionalAddonBlocks, (serv: IAdditonalAddonService) => {
      return serv.serviceId === id
    })
  }

  const _getTitle = useMemo(() => {
    if (popupStatusData) {
      switch (currentStep) {
        case ServiceOrderStepsEnum.FAILURE:
        case ServiceOrderStepsEnum.ERROR:
          return getText('failureResultTitle', popupStatusData, 'texts')
        case ServiceOrderStepsEnum.IN_PROGRESS:
          return getText('waitingResultTitle', popupStatusData, 'texts')
        case ServiceOrderStepsEnum.SUCCESS:
          return getText('successResultTitle', popupStatusData, 'texts')
        case ServiceOrderStepsEnum.REDIRECT_SUCCESS:
          return getText('successResultTitleOrder', popupStatusData, 'texts')
        case ServiceOrderStepsEnum.SELECT:
          return getText('confirmTitle', popupStatusData, 'texts')
        default:
          return getText('title', popupStatusData, 'texts')
      }
    }
    return ''
  }, [popupStatusData, currentStep])

  const _getPrimaryButton = useMemo(() => {
    if (
      (currentStep === ServiceOrderStepsEnum.DEFAULT || currentStep === ServiceOrderStepsEnum.SELECT) &&
      popupStatusData
    ) {
      return {
        disabled: !allowOrderOrCancelClick,
        text: getText('continueButton', popupStatusData, 'texts'),
        onClick: () => {
          if (currentStep === ServiceOrderStepsEnum.SELECT) {
            setCurrentStep(ServiceOrderStepsEnum.DEFAULT)
          } else if (currentStep === ServiceOrderStepsEnum.DEFAULT) {
            _onConfirmClick()
          }
        }
      }
    }
  }, [allowOrderOrCancelClick, currentStep, popupStatusData])

  const _getSecondaryButton = () => {
    if (currentStep !== ServiceOrderStepsEnum.LOADING) {
      return {
        text: getText('closePopupButton', popupStatusData, 'texts'),
        onClick: () => {
          if (onClose) {
            onClose()
          }
        }
      } as IAction
    }
  }

  // ************************************
  // Order Handling
  // ************************************

  const _onConfirmClick = async () => {
    setCurrentStep(ServiceOrderStepsEnum.LOADING)
    setCurrentStep(await handleServiceOrderAndCancel(props, GET, POST))
    updateAddonStates()
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderStateResult = useMemo(() => {
    if (popupStatusData) {
      return (
        <PopupContent
          currentStep={currentStep}
          classPrefix={classPrefix}
          selectedOption={selectedOption}
          isTrumf={false}
          theme={theme}
          brand={brand}
          checkboxStates={checkboxStates}
          setCheckboxStates={setCheckboxStates}
          productDefinitionId={page.productDefinitionId}
          data={{
            dataPoints: popupStatusData.dataPoints,
            texts: popupStatusData.texts,
            house: popupStatusData.house,
            selectedExtraProductDesc: popupStatusData.selectedExtraProductDesc,
            description: popupStatusData.description,
            links: popupStatusData.links
          }}
          onClickSelectOption={setSelectedOption}
        />
      )
    }
  }, [popupStatusData, currentStep])

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {popupStatusData && (
        <PopupCard
          theme={theme}
          brand={brand}
          contentTitle={_getTitle}
          contentTitleAlignment={
            currentStep === ServiceOrderStepsEnum.SELECT || currentStep === ServiceOrderStepsEnum.DEFAULT
              ? 'align-left'
              : 'align-center'
          }
          contentGap={4}
          onClose={onClose}
          primaryAction={_getPrimaryButton}
          secondaryAction={_getSecondaryButton()}
          desktopView={desktopView}
        >
          {_renderStateResult}
        </PopupCard>
      )}
    </>
  )
}
