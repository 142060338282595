import { useState } from 'react'
import { MS_ButtonTemplate } from '../../Prefabs'
import { useApplicationContext } from '../../contexts'
import { ActionButton } from '../Buttons/ActionButton'
import './ToggleButton.scss'

export type ToggleButtonElement = {
  text: string
  value: string
}

export type ToggleButtonProps = {
  defaultValue: string
  elements: ToggleButtonElement[]
  onChange: (value: string) => void
}

export const ToggleButton = ({ elements, defaultValue, onChange }: ToggleButtonProps) => {
  const { activeTheme, activeBrand } = useApplicationContext()
  const [active, setActive] = useState(defaultValue)

  const handleChange = (value: string) => {
    setActive(value)
    onChange(value)
  }

  return (
    <div className='toggle-buttons'>
      {elements.map(element => {
        return (
          <ActionButton
            key={element.value}
            template={MS_ButtonTemplate(activeTheme, 'toggle')}
            brand={activeBrand}
            disabled={active === element.value}
            action={{
              text: element.text,
              onClick: () => handleChange(element.value),
              active: active === element.value
            }}
          />
        )
      })}
    </div>
  )
}
