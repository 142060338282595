import {
  BrandColors,
  type IComponentTemplate,
  type IComponentTransform,
  type IThemedColors,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = () => {
  return {
    width: '100%',
    height: 'auto',
    border: {
      radiusType: 'curved',
      type: 'solid',
      thickness: 3
    },
    padding: '2rem',
    margin: undefined,
    grid: {
      boxSizing: 'border-box',
      direction: 'row',
      alignment: 'top-left',
      tagType: 'article',
      gap: 2,
      wrap: false,
      inline: true
    }
  } as IComponentTransform
}

// ************************************
// Palette
// ************************************

const _palette = () => {
  return {
    Light: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['primary-shade-light-1'] },
      icon: { color: BrandColors['primary-shade-light-2'] }
    },
    Dark: {
      background: { color: BrandColors['background-shade-dark-3'] },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['primary-shade-light-1'] },
      icon: { color: BrandColors['primary-shade-light-2'] }
    }
  } as IThemedColors
}

export const TipCardTemplate = (theme: Theme = 'Light') => {
  return {
    palette: _palette()[theme],
    transform: _transform()
  } as IComponentTemplate
}
