import type { IAction, IComponent, IComponentTemplate } from '@fjordkraft/fjordkraft.component.library'
import { useLocation, useNavigate } from 'react-router'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { isExternalUrl } from '../../../services'
import { ActionButton } from '../ActionButton/ActionButton'

export interface IMenuButton extends IComponent {
  action: IAction
  template?: IComponentTemplate
  navigating: (path: string) => void
}

export const MenuButton = (props: IMenuButton) => {
  // ************************************
  // Properties
  // ************************************

  const { brand, theme = 'Light', action, navigating, template = MS_ButtonTemplate(theme, 'menu') } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // ************************************
  // Action handling
  // ************************************

  const _handleAction = () => {
    if (action?.link && !isExternalUrl(action.link)) {
      return {
        ...action,
        ...({
          icon: undefined,
          onClick: () => {
            const link: string = action.link ?? '/'

            if (link !== pathname) {
              navigating(link)
              navigate(link)
            }
          },
          link: undefined,
          overrideTagEnforcer: true
        } as IAction)
      }
    }
    return action
  }

  // ************************************
  // Render
  // ************************************

  return <ActionButton template={template} brand={brand} disabled={action.disabled} action={_handleAction()} />
}
