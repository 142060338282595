import {
  Button,
  ContentGrid,
  type IAction,
  type IComponent,
  type IComponentTemplate,
  type IToast,
  Icon,
  type IconType,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { MSRichText } from '../../components'
import { MS_ButtonTemplate } from '../Templates'
import { h4TextPrefab } from '../Texts/Header4TextPrefab'
import '../../styling/styling.scss'
import { paragraphTextPrefab } from '../Texts/ParagraphTextPrefab'

export interface IMessageToastPrefab extends IComponent {
  title?: string
  description?: string
  icon?: IconType
  template: IComponentTemplate
  action?: IAction
}

export const MessageToastPrefab = (props: IMessageToastPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { theme, brand, title, description, icon, action, template } = props

  // ************************************
  // Render Functionality
  // ************************************

  const _renderTitle = () => {
    if (title) {
      return (
        <Text {...h4TextPrefab()} brand={brand} theme={theme} palette={template.palette}>
          {title}
        </Text>
      )
    }
  }

  const _renderDescription = () => {
    if (description) {
      return <MSRichText brand={brand} theme={getCounterTheme(theme)} text={description} palette={template.palette} />
    }
  }

  const _renderIcon = (allow: boolean) => {
    if (icon && allow) {
      return <Icon type={icon} width={1} height={1} brand={brand} palette={template.palette} />
    }
  }

  const _renderAction = () => {
    if (action) {
      return (
        <Button action={action} brand={brand} theme={theme} template={MS_ButtonTemplate(theme, 'link', brand)}>
          <Text {...paragraphTextPrefab()} weight={500}>
            {action.text}
          </Text>
        </Button>
      )
    }
  }

  const _renderTop = () => {
    if (title || icon) {
      return (
        <ContentGrid
          direction='row'
          alignment='top-left'
          boxSizing='border-box'
          wrap={false}
          tagType='section'
          style={{
            width: '100%'
          }}
          gap={1}
        >
          {_renderIcon(!!title)}
          {_renderTitle()}
        </ContentGrid>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    ...props,
    ...{
      content: {
        top: _renderTop(),
        middle: (
          <>
            {_renderIcon(!title)}
            {_renderDescription()}
          </>
        ),
        bottom: _renderAction()
      },
      action: undefined
    }
  } as IToast
}
