import { IconType, type Theme } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import {
  type IPointPlankPrefab,
  type ITextPlankPrefab,
  MS_ButtonTemplate,
  TrumfPlankTemplate,
  getPlankPrefab
} from '../../../../../Prefabs'
import type { IDetailsDescriptionBlock, IMSPlankWall, ISectionBlock } from '../../../../../blocks'
import type { IStatePlank } from '../../../../../components'
import type { getRequest, postRequest } from '../../../../../contexts'
import { Constants } from '../../../../../data'
import type { IServiceOrderOrCancel } from '../../../../../modals'
import {
  type IAddonData,
  type ICustomerInstallation,
  type IRelationship,
  type IServicePage,
  type IServices,
  type IUser,
  type OnClickServiceOrderAction,
  OnClickServiceOrderActionEnum,
  OrderMethodTypeEnum,
  type ServiceOrderSteps,
  ServiceOrderStepsEnum,
  type ServiceStatus,
  ServiceStatusEnum
} from '../../../../../models'
import {
  type IResponse,
  createString,
  getServiceStatusColor,
  getSpecificAddonStateForInstallation,
  getStatusFromInstallationOrGlobal,
  getText,
  mapServiceStatusToOrderResult,
  narrowDownPopupState,
  narrowDownResultStatusForPlank
} from '../../../../../services'
import { getCoreDetails, getPitchCards, getSections } from '../../ServiceBasePageData'
import { type IServicePageDataDescriptions, getServicePageDataDescriptions } from '../ServicePageDataDescriptions'
import type { ServicePageDataPitchCards } from '../ServicePageDataPitchCards'

export interface ITrumfTransactions {
  sumPoints: number
  periodStart: string
  periodEnd: string
  monthly: ITrumfMonth[]
  transactions: ITrumfTransaction[]
}

export interface ITrumfMonth {
  sumPoints: number
  accumulatedPointsForYear: number
  year: number
  month: number
}

export interface ITrumfTransaction {
  id: string
  trumfId: string
  points: number
  date: string
}

export type TrumfData = {
  sub: {
    title: string
    back: {
      text: string
      link: string
    }
  }
  sections?: ISectionBlock[]
  pitchCards?: ServicePageDataPitchCards
  coreDetails?: IDetailsDescriptionBlock[]
  descriptions?: IServicePageDataDescriptions
}

export const orderOrTerminateTrumf = async (
  orderAction: OnClickServiceOrderAction,
  GET: getRequest,
  POST: postRequest
): Promise<ServiceOrderSteps> => {
  if (orderAction === OnClickServiceOrderActionEnum.ORDER) {
    const resp: IResponse = await GET('Services/order/trumf')

    if (resp.callState === 'success' && resp.data) {
      window.location.href = resp.data
    }
    return ServiceOrderStepsEnum.LOADING
  }
  const resp: IResponse = await POST('Services/terminate/trumf')
  return resp.callState === 'success' ? ServiceOrderStepsEnum.SUCCESS : ServiceOrderStepsEnum.FAILURE
}

export const fetchTrumfTransactions = async (GET: getRequest): Promise<ITrumfTransactions | null> => {
  const resp: IResponse = await GET('trumf/transactions')

  if (resp.callState === 'success' && resp.data) {
    const trumfData: ITrumfTransactions = resp.data
    return trumfData
  }
  return null
}

const getDescriptions = (
  user: IUser,
  translations: any,
  addonStates: IAddonData[]
): IServicePageDataDescriptions | undefined => {
  const status = getStatusFromInstallationOrGlobal({
    installationOnly: translations.orderMethodType === OrderMethodTypeEnum.INSTALLATION,
    translations: translations,
    installation: user.installation,
    addonStates
  })
  return getServicePageDataDescriptions(translations, status)
}

export const getPageData = async (
  defaultProps: any,
  translations: any,
  addonStates: IAddonData[]
): Promise<TrumfData> => {
  const { user, services, activeTheme, activeBrand, setInstallation } = defaultProps
  return {
    sub: {
      title: getText('pageTitle', translations),
      back: {
        text: getText('back', translations),
        link: `${Constants.paths.servicesPage}`
      }
    },
    sections: await getSections(translations, user, services, addonStates),
    pitchCards: await getPitchCards(translations, user, services, addonStates),
    coreDetails: await getCoreDetails(
      activeTheme,
      activeBrand,
      translations,
      user,
      services,
      addonStates,
      setInstallation
    ),
    descriptions: getDescriptions(defaultProps, translations, addonStates)
  }
}

export interface IgetTrumfPageHouseData {
  onClickService: (value: IServiceOrderOrCancel) => void
  addonStates: IAddonData[]

  services: IServices
  user: IUser
  relationship: IRelationship
  translations: any
  activeTheme: Theme
  desktopView: boolean
  inProgress: boolean
  transactions: ITrumfTransactions | null
}

export const getTrumfHouse = (config: IgetTrumfPageHouseData) => {
  const walls = _getTrumfPlankWalls(config)
  return {
    plankWalls: [...(walls ?? [])]
  }
}

const _getTrumfPlankWalls = (config: IgetTrumfPageHouseData) => {
  const walls: IMSPlankWall[] = []

  const detailPlanks = config.transactions ? _getTrumfDetailPlanks(config.transactions, config.translations) : undefined
  const orderAndCancelPlanks = _getOrderAndCancelPlanks(config)

  if (detailPlanks) walls.push({ planks: detailPlanks })

  return walls.concat({ planks: orderAndCancelPlanks })
}

const _getOrderAndCancelPlanks = (config: IgetTrumfPageHouseData): IStatePlank[] => {
  const { user, relationship, translations, onClickService, activeTheme, desktopView, addonStates, inProgress } = config
  const { userData } = user
  const { isGuest } = relationship

  const statusData = getTrumfCustomerInstallationStatusData(user, translations, addonStates, inProgress)
  const actionTextKey = statusData.status === 'INACTIVE' ? 'order' : 'cancel'
  const actionText = getText(actionTextKey, translations)
  const title: string = `${userData.firstName} ${userData.lastName}`
  const description: string = createString(getText('customerNumber', translations), {
    customerNumber: userData.customerId
  })

  const actionPlank = getPlankPrefab('Action', {
    action: {
      text: actionText,
      icon: IconType.ExternalLinkThick,
      disabled: !statusData.allowOrderOrCancel || isGuest || inProgress,
      onClick: () => {
        onClickService({
          status: statusData.status,
          userData,
          page: translations
        } as IServiceOrderOrCancel)
      }
    },
    actionButtonPadding: desktopView ? 'small' : 'default',
    actionIconPlacement: 'Right',
    left: {
      title,
      description
    },
    right: {
      template: MS_ButtonTemplate(activeTheme, statusData.status === 'INACTIVE' ? 'primary' : 'secondary')
    }
  })

  const textPlank = getPlankPrefab('Text', {
    left: {
      title,
      description
    },
    right: translations.allowOrder && {
      title: undefined,
      customization: {
        title: {
          color: getServiceStatusColor(statusData.status)
        }
      }
    }
  } as ITextPlankPrefab)

  const editTrumfPlank = _showActionButton(statusData.status, translations) ? actionPlank : textPlank
  const installationStatusPlanks = _getInstallationStatusPlanks(statusData.installationStatuses)

  return [editTrumfPlank, ...installationStatusPlanks]
}

interface IInstallationStatus {
  status: ServiceStatus
  text: string
  streetAddress: string
}

export const getTrumfCustomerInstallationStatusData = (
  user: IUser,
  translations: any,
  addonStates: IAddonData[] | undefined,
  inProgress: boolean
) => {
  const { userData } = user

  if (!translations || !addonStates)
    return {
      allowOrderOrCancel: false,
      status: ServiceStatusEnum.INACTIVE,
      installationStatuses: [],
      activeAndInactiveInstallations: false
    }

  let installationStatuses: IInstallationStatus[] = []

  if (userData.installations && userData.installations.length > 0) {
    installationStatuses = userData.installations
      .map((installation: ICustomerInstallation) => {
        let status = narrowDownResultStatusForPlank(
          getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
            ?.state ?? ServiceStatusEnum.INACTIVE
        )

        if (inProgress) {
          status = ServiceStatusEnum.ORDER_IN_PROGRESS
        }

        return {
          text: _getInstallationStatusText(status, translations),
          installationStatus: installation.status,
          status: status,
          streetAddress: installation.address.streetAddress
        }
      })
      // Return Trumf status for installations that are active. Include Trumf status for inactive installations if there are Active Trumf status.
      // This will disable ordering for inactive installations, and enable termination if there are active products on the inactive installation.
      .filter(inst => inst.installationStatus === 'Active' || inst.status === ServiceStatusEnum.ACTIVE)
  }

  const status: ServiceStatus = installationStatuses.find(
    instStatus => mapServiceStatusToOrderResult(instStatus.status) === ServiceOrderStepsEnum.SUCCESS
  )
    ? ServiceStatusEnum.ACTIVE
    : ServiceStatusEnum.INACTIVE

  const allowOrderOrCancel = _allowOrderOrCancelTrumfBasedOnInstallationStatuses(installationStatuses) && !inProgress
  const activeAndInactiveInstallations =
    !inProgress &&
    status === ServiceStatusEnum.ACTIVE &&
    installationStatuses.filter(inst => inst.status === ServiceStatusEnum.INACTIVE).length > 0

  return { allowOrderOrCancel, status, installationStatuses, activeAndInactiveInstallations }
}

const _allowOrderOrCancelTrumfBasedOnInstallationStatuses = (installationStatuses: IInstallationStatus[]): boolean => {
  if (installationStatuses && installationStatuses.length > 0) {
    for (const installationStatus of installationStatuses) {
      const statusAsStep: ServiceOrderSteps = narrowDownPopupState(installationStatus.status)
      if (statusAsStep === ServiceOrderStepsEnum.IN_PROGRESS) {
        return false
      }
    }
    return true
  }

  return false
}

const _getInstallationStatusText = (status: ServiceStatus, transactions: IServicePage) => {
  if (status === ServiceStatusEnum.ACTIVE) {
    return getText('plankActivationStatusActive', transactions)
  }
  if (status === ServiceStatusEnum.INACTIVE) {
    return getText('plankActivationStatusNotActive', transactions)
  }
  return getText('plankActivationStatusInProgress', transactions)
}

const _getInstallationStatusPlanks = (statuses: IInstallationStatus[]): IStatePlank[] => {
  const planks: IStatePlank[] = []

  statuses.forEach((status: IInstallationStatus) => {
    planks.push(
      getPlankPrefab('Text', {
        left: {
          title: status.streetAddress
        },
        right: {
          title: status.text,
          customization: {
            title: {
              color: getServiceStatusColor(status.status)
            }
          }
        }
      })
    )
  })

  return planks
}

const _getTrumfDetailPlanks = (trumfData: ITrumfTransactions, translations: any) => {
  return [
    getPlankPrefab('Point', {
      brand: 'brand-trumf',
      theme: 'Dark',
      template: TrumfPlankTemplate('Dark', 'top'),
      useDecimals: true,
      left: {
        title: getText('plankOverviewTitle', translations),
        description: `${format(new Date(trumfData.periodStart), 'dd.MM.yyyy')} - ${format(
          new Date(trumfData.periodEnd),
          'dd.MM.yyyy'
        )}`,
        dontUseCounterTheme: true
      },
      points: trumfData.sumPoints,
      pointsLabel: getText('currency', translations)
    } as IPointPlankPrefab),
    getPlankPrefab('Text', {
      left: {
        title: getText('plankTransactionsTitle', translations),
        description: getText('plankTransactionsDesc', translations)
      },
      right: {
        icon: IconType.ChevronRight
      },
      action: {
        link: 'transaksjoner',
        useRouterLink: true
      }
    })
  ] as IStatePlank[]
}

const _showActionButton = (status: ServiceStatus, servicePage: IServicePage): boolean => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return true
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.ORDER_FAILED:
      return servicePage.allowOrder ?? true
  }
  return false
}
