import benefitsImg from '../../../assets/art/benefits/benefits.png'
import creditImg from '../../../assets/art/benefits/intro-step2-creditcard.svg'
import coinImg from '../../../assets/art/benefits/intro-step3-coin.svg'
import walletImg from '../../../assets/art/benefits/intro-step4-wallet.svg'
import { Constants } from '../../../data'
import type { IDefaultProps } from '../../../models'
import { getText } from '../../../services'
import { fetchKickbackSummary } from '../../../services/collection/BenefitService'

export interface IBenefitsIntroductionPageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IBenefitsIntroductionPageData) => {
  const { translations, services } = config
  const { GET } = services

  if (translations && GET !== undefined) {
    const summary = await fetchKickbackSummary(GET)

    return {
      ...config,
      points: summary.amount,
      benefitIntroductionData: getIntroductionPages(translations),
      sub: {
        title: undefined,
        subTitle: undefined,
        back: undefined
      }
    }
  }
}

// ************************************
// PRIVATE
// ************************************

const getIntroductionPages = (translation: any) => {
  const pages = []

  pages.push({
    title: getText('introductionTitlePage1', translation),
    description: getText('introductionDescriptionPage1', translation),
    imageUrl: benefitsImg,
    imageAlt: 'Fjordkraft Fordeler. Trenings- og teknologiprodukter.'
  })
  pages.push({
    title: getText('introductionTitlePage2', translation),
    description: getText('introductionDescriptionPage2', translation),
    imageUrl: creditImg,
    imageAlt: 'Kredittkort og handlekurv.'
  })
  pages.push({
    title: getText('introductionTitlePage3', translation),
    description: getText('introductionDescriptionPage3', translation),
    imageUrl: coinImg,
    imageAlt: 'Penger som faller inn i lommeboken.'
  })
  pages.push({
    title: getText('introductionTitlePage4', translation),
    description: getText('introductionDescriptionPage4', translation),
    buttonText: 'Gå til Fordeler',
    imageUrl: walletImg,
    imageAlt: 'Få 1% rabat på markedspass.'
  })

  return {
    pages,
    endUrl: Constants.paths.benefitsPage
  }
}
