import {
  type IMasterPlank,
  type ITextCustomization,
  LoaderGraphic,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { v4 as uuid4 } from 'uuid'

export interface ILoadingPlankPrefabCustomization {
  title?: ITextCustomization
  description?: ITextCustomization
}

export interface ILoadingPlankPrefab extends IMasterPlank {
  title?: string
  description?: string
  textCustomization?: ILoadingPlankPrefabCustomization
}

export const LoadingPlankPrefab = (props: ILoadingPlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { theme = 'Light', brand = 'brand-fjordkraft', title, description, textCustomization } = props

  // ************************************
  // Render Functionality
  // ************************************

  const _renderTitle = () => {
    if (title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          theme={getCounterTheme(theme)}
          {...textCustomization?.title}
        >
          {title}
        </Text>
      )
    }
  }

  const _renderDescription = () => {
    if (description) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'small'}
          family={'main'}
          type={'p'}
          theme={getCounterTheme(theme)}
          faded
          {...textCustomization?.description}
        >
          {description}
        </Text>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    leftColumn2: [_renderTitle(), _renderDescription()],
    rightColumn2: [<LoaderGraphic key={uuid4()} theme={theme} brand={brand} scaleThickness={0.1} size={1} />]
  } as IMasterPlank
}
