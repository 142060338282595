import {
  ContentGrid,
  IconType,
  StyleGrid,
  getCounterTheme,
  useQueryParams
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { subDays } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { GetToastPrefab, MessageToastTemplate, ToastPrefabsType } from '../../../Prefabs'
import {
  CustomerServiceInformationBlock,
  DynamicEpiContentBlock,
  Footer,
  Header,
  type IWebToast
} from '../../../blocks'
import { SmoothLoader } from '../../../components'
import {
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationOverlayWrapperContext,
  useApplicationServicehandlerContext,
  useConsentContext
} from '../../../contexts'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { DefaultPageContext } from '../../../contexts/variations/DefaultPageContext'
import { Constants } from '../../../data'
import { ConsentPopupModal, CustomerInfoModal, type ICustomerInfoModalData } from '../../../modals'
import {
  ConsentStateEnum,
  type ICustomerInstallation,
  type IHomePage,
  type IOperatingMessage,
  type IServicePage,
  MarketingConsentType
} from '../../../models'
import { HomePageData } from '../../../services'
import './DefaultPageLayout.scss'
import _ from 'lodash'
import { Toastify } from '../../../components/Toastify/Toastify'
import { getAddonStates } from '../../HomePage/HomePageData'

export interface ICustomerInfo {
  firstName: string
  lastName: string
  phone: string
  email: string
  address: {
    streetAddress: string
    postalCode: string
    postalLocation: string
  }
}

export const DefaultPageLayout = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'default-page-layout'
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { translation, epiChildren, userData, installation, setInstallation, updateCustomerData } =
    useApplicationCoreDataContext()
  const { GET, GETTYPED, customerServiceFeature, error, user } = useApplicationServicehandlerContext()
  const { setToasts } = useApplicationOverlayWrapperContext()
  const { isGuest, setChosenHost, setHostIdForCustomerDataRequests } = useApplicationGuestsAndHostsContext()
  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  const meterIdParam = queryParams.get('meterId')
  const { useNewConsents, consents, showConsentModalForCustomer, pageHasPopup } = useConsentContext()
  const { get: getHostIdParam, clear: clearHostIdParam } = useQueryParams('hostId', false)

  useEffect(() => {
    fetchAddonStates()
  }, [installation])

  // ************************************
  // Extra / Helpers
  // ************************************

  const ScrollIntoAppView = () => {
    const ele = document.getElementById(`${classPrefix}-id`)

    if (ele) {
      ele.scrollIntoView({ behavior: 'auto' })
    }
  }

  const ScrollIntoMainView = () => {
    const ele = document.getElementById(`${classPrefix}-main-id`)

    if (ele) {
      ele.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const shouldShowConsentModal = () => {
    return !!useNewConsents && !!showConsentModalForCustomer && pageHasPopup(pathname) && !isGuest
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [mainContentClamping, setMainContentClamping] = useState<'clamp' | 'stretch' | 'scale'>('clamp')
  const [rootPageData, setRootPageData] = useState<IHomePage>()
  const [customerInfoModal, setCustomerInfoModal] = useState<ICustomerInfoModalData>()
  const [showBottomEditorialContent, setShowBottomEditorialContent] = useState<boolean>(true)
  const [activePageId, setActivePageId] = useState<string>()
  const [contentLoading, setContentLoading] = useState<boolean>(true)
  const [canShowCustomerInfoModal, setCanShowCustomerInfoModal] = useState<boolean>(true)
  const [showConsentModal, setShowConsentModal] = useState<boolean>(shouldShowConsentModal())
  const [addonStates, setAddonStates] = useState<IServicePage[]>()

  useEffect(() => {
    setShowConsentModal(shouldShowConsentModal())
  }, [useNewConsents, showConsentModalForCustomer, isGuest])

  useEffect(() => {
    // If the ?hostId= query parameter is set, we automatically set the chosenHost to that id after the page has loaded.
    if (userData && getHostIdParam()) {
      const chosenHost = userData.guestRelationships?.hosts.find(host => host.customerId === getHostIdParam())
      if (chosenHost) {
        setChosenHost(chosenHost)
        setHostIdForCustomerDataRequests(chosenHost.customerId)
      }
      clearHostIdParam()
    }
  }, [])

  useEffect(() => {
    if (userData) {
      if (meterIdParam) {
        _assignInstallationBasedOnUrlParam(meterIdParam, userData.installations)
      } else if ((!meterIdParam && !installation) || isGuest) {
        setInstallation(userData.installations[0])
      }
    }
  }, [meterIdParam, userData, isGuest])

  useEffect(() => {
    if (userData && consents) {
      _checkForCustomerDetailsPopup()
    }
  }, [userData, consents])

  useEffect(() => {
    if (epiChildren) {
      if (!rootPageData) {
        const rootPage: IHomePage = HomePageData(epiChildren)
        setRootPageData(rootPage)
      }
    }
  }, [epiChildren, translation])

  const contextData = useMemo(() => {
    return {
      contentLoading,
      setContentLoading,
      mainContentClamping,
      setMainContentClamping,
      setShowBottomEditorialContent,
      ScrollIntoAppView,
      ScrollIntoMainView
    }
  }, [mainContentClamping, contentLoading])

  useEffect(() => {
    if (translation?.pageId && translation.pageId !== activePageId) {
      setActivePageId(translation.pageId)
      _handleOperatingMessages(translation?.pageId)
    }
  }, [translation, error])

  // ************************************
  // Installasjon assignment
  // ************************************

  const _assignInstallationBasedOnUrlParam = (meterId: string, installations: ICustomerInstallation[]) => {
    if (installations?.length > 0) {
      const inst: ICustomerInstallation | undefined = _.find(installations, (installation: ICustomerInstallation) => {
        return meterId === `${installation?.meterId}`
      })

      if (inst) {
        setInstallation(inst)
      } else if (!inst && !installation) {
        setInstallation(installations[0])
      }
    }
  }

  // ************************************
  // Operating Messages / Driftsmeldinger
  // ************************************

  const _handleOperatingMessages = async (pageId: string) => {
    const resp = await GET(`ServiceMessages/${pageId}`)
    const toasts: IWebToast[] = []

    if (resp.data && resp.data.length > 0 && resp.callState === 'success') {
      resp.data.forEach((message: IOperatingMessage, index: number) => {
        const id = `${message.title}_${message.description}_${index}`

        toasts.push({
          ...{
            stay: true,
            message,
            onClose: () => {
              localStorage.setItem(id, 'staticToast')
            },
            ...GetToastPrefab(ToastPrefabsType.MessageToast, {
              id,
              theme: getCounterTheme(activeTheme),
              brand: activeBrand,
              title: message.title,
              description: message.description,
              icon: IconType.WarningBadge,
              template: MessageToastTemplate(getCounterTheme(activeTheme), desktopView),
              action: message.linkUrl
                ? {
                    link: message.linkUrl,
                    text: message.linkName
                  }
                : undefined
            })
          }
        } as IWebToast)
      })
    }

    setToasts(toasts)
  }

  // ************************************
  // Helpers :: userData Popup
  // ************************************

  const fetchAddonStates = async () => {
    const availableServicePages = await getAddonStates(
      epiChildren,
      customerServiceFeature,
      user,
      GETTYPED,
      GET,
      userData,
      installation
    )
    setAddonStates(availableServicePages)
  }

  const _checkForCustomerDetailsPopup = async (): Promise<void> => {
    if (!userData?.customerId) return
    const lastUpdateDateResp = await GETTYPED<string>('Customers/email/lastUpdateDate')

    const oldConsent =
      consents?.find(e => e.consentName === MarketingConsentType.Legacy1)?.value === ConsentStateEnum.ACCEPTED

    let showPopup = false
    const hasVisitedPreviously: boolean = sessionStorage.getItem('customerEmailVisited') === 'true'

    if (lastUpdateDateResp?.callState && !isGuest) {
      if (lastUpdateDateResp.callState === 'success' && consents?.length) {
        showPopup = _checkTimeSinceLastPopup(lastUpdateDateResp.data)
      } else {
        showPopup = !hasVisitedPreviously
      }

      if (showPopup && oldConsent) {
        setCustomerInfoModal({
          translations: HomePageData(epiChildren),
          consent: oldConsent
        })
      }
    }
  }

  const _checkTimeSinceLastPopup = (date: string | null): boolean => {
    const hasVisitedPreviously: boolean = sessionStorage.getItem('customerEmailVisited') === 'true'

    if (date) {
      let showNextPopup = false
      const lastDate = new Date(date)
      const nowDate = new Date()
      const check: Date | typeof NaN = subDays(nowDate, Constants.popupFrequencyDays)

      if (Number.isNaN(lastDate.valueOf())) {
        showNextPopup = true
      } else if (Number.isNaN(check.valueOf())) {
        showNextPopup = false
      } else {
        showNextPopup = check > lastDate
      }

      return showNextPopup
    }
    return !hasVisitedPreviously
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={`${classPrefix}-id`}
      className={classnames(`${classPrefix}__wrapper`, {
        [`${classPrefix}__wrapper--${activeTheme}`]: activeTheme
      })}
      brand={activeBrand}
      direction='column'
      alignment='top-center'
      gap={2}
      boxSizing='border-box'
      spaceBetween
    >
      {rootPageData && addonStates && (
        <Header
          rootPageData={rootPageData}
          navigating={(path: string) => {
            if (path !== pathname) {
              setContentLoading(true)
            }
          }}
        />
      )}
      <SmoothLoader loadingState={contentLoading} />
      <DefaultPageContext.Provider value={contextData}>
        <ContentGrid
          id={`${classPrefix}-main-id`}
          className={classnames(classPrefix, {
            [`${classPrefix}--${mainContentClamping}`]: mainContentClamping
          })}
          direction='column'
          alignment='top-center'
          gap={2}
          boxSizing='border-box'
          tagType='main'
        >
          {customerServiceFeature && <CustomerServiceInformationBlock brand={activeBrand} theme={activeTheme} />}
          <Outlet />
          {showBottomEditorialContent && translation?.bottomEditorialContent?.length > 0 && !contentLoading && (
            <DynamicEpiContentBlock
              className={`${classPrefix}__dynamic-content__bottom`}
              theme={activeTheme}
              brand={activeBrand}
              epiItems={translation.bottomEditorialContent}
              scaling={mainContentClamping}
            />
          )}
        </ContentGrid>
        {customerInfoModal && userData && canShowCustomerInfoModal && (
          <CustomerInfoModal
            {...customerInfoModal}
            closeModal={async (updateCustomer: boolean, resp) => {
              setCustomerInfoModal(undefined)

              sessionStorage.setItem('customerEmailVisited', 'true')

              if (updateCustomer && resp?.callState === 'success') {
                updateCustomerData(true)
                setCanShowCustomerInfoModal(false)
              }
            }}
          />
        )}
        {showConsentModal && epiChildren['HeadlessConsentPageType']?.data && (
          <ConsentPopupModal
            translations={epiChildren['HeadlessConsentPageType'].data}
            onClose={() => {
              sessionStorage.setItem(Constants.keys.consentPopupClosed, 'true')
              setShowConsentModal(false)
            }}
          />
        )}
      </DefaultPageContext.Provider>
      {rootPageData && <Footer rootPageData={rootPageData} />}
      <Toastify />
    </StyleGrid>
  )
}
