import {
  type Alignment,
  type IComponent,
  Icon,
  IconType,
  Text,
  Tooltip,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { MSTooltipButtonTemplate, MSTooltipTemplate, paragraphTextPrefab } from '../../../Prefabs'
import { useApplicationContext } from '../../../contexts'
import { MSRichText } from '../../MSRichText/MSRichText'

export interface IMSTooltipIcon extends IComponent {
  text: string
  alignment: Alignment
  icon?: IconType
  label?: string
  onClick?: () => void
}

export const MSTooltip = (props: IMSTooltipIcon) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, text, alignment, label, icon = IconType.MoreInfo, onClick } = props
  const classPrefix = 'ms-tooltip'
  const { desktopView } = useApplicationContext()

  // ************************************
  // Render
  // ************************************

  return (
    <Tooltip
      id={id}
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      brand={brand}
      theme={theme}
      template={MSTooltipTemplate(theme)}
      buttonTemplate={MSTooltipButtonTemplate(getCounterTheme(theme))}
      alignment={alignment}
      action={
        onClick
          ? {
              onClick: onClick
            }
          : undefined
      }
      animation={{
        delay: 10,
        fadeOut: false,
        lingerTime: 100,
        duration: 100
      }}
      tooltipContent={<MSRichText className={`${classPrefix}__text`} brand={brand} text={text} alignment='lawful' />}
    >
      <Icon brand={brand} type={icon} />
      {label && (
        <Text
          {...paragraphTextPrefab()}
          className={`${classPrefix}__button__text`}
          weight={700}
          brand={brand}
          size={desktopView ? 'regular' : 'small'}
        >
          {label}
        </Text>
      )}
    </Tooltip>
  )
}
