import {
  BrandColors,
  type ITabSelectDropdown,
  type ITabSelectFilterItem,
  IconType
} from '@fjordkraft/fjordkraft.component.library'
import { format, getDaysInMonth } from 'date-fns'
import { nb } from 'date-fns/esm/locale'
import _ from 'lodash'
import { MS_ButtonTemplate, MS_TabSelectDropdownTemplate, getPlankPrefab } from '../../../Prefabs'
import type { IMSPlankWall, IPlankHouse } from '../../../blocks'
import { DefaultColumnChartColors } from '../../../components/Cards/ChartCards/variations/DefaultColumnChartColors'
import { Constants, getConsumptionSeriesData, getMockData } from '../../../data'
import type { ICustomerConsumptionPoint, IDateState, IDefaultProps } from '../../../models'
import type { IGridTariffResponse } from '../../../models/interfaces/pages/IConsumptionCostPage'
import { createString, fetchCustomerConsumption, getText, tNumber } from '../../../services'
import { fetchGridTariff } from '../../../services/collection/GridTariffService'

export interface IEnergyConsumptionCostPageData extends IDefaultProps {
  selectedDate: Date
  includeSupport: boolean
  setShowSupportPopup: (state: boolean) => void
}

interface IConsumptionData {
  state: IDateState
  consumption: ICustomerConsumptionPoint[]
  tariff?: IGridTariffResponse | null
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IEnergyConsumptionCostPageData) => {
  const { user, translations, selectedDate, services } = config
  const { installation } = user
  const { GETTYPED } = services

  if (installation && selectedDate) {
    const fromDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
    const toDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), getDaysInMonth(selectedDate))
    const state: IDateState = {
      from: fromDate,
      to: toDate,
      resolution: 'day'
    }
    const consumption = await fetchCustomerConsumption(installation.meterId, state, GETTYPED)
    const gridTariff = await fetchGridTariff(installation.meterId, selectedDate, GETTYPED)
    let house: IPlankHouse | null = null
    let parsedChartData: any = null

    if (consumption) {
      house = await _getHouse(config, {
        state,
        consumption,
        tariff: gridTariff
      })

      parsedChartData = _getParsedChartData(config, {
        state,
        consumption,
        tariff: gridTariff
      })
    }

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: installation.address.streetAddress,
          link: Constants.paths.energyPage
        },
        description: _getDescription(config),
        tooltipDescription: _getTooltip(config)
      },
      chartData: parsedChartData,
      house: house,
      dateState: state
    }
  }
}

const _getTooltip = (config: IEnergyConsumptionCostPageData) => {
  const { includeSupport, translations } = config

  return includeSupport ? getText('tooltipWithSupport', translations) : getText('tooltipWithoutSupport', translations)
}

const _getDescription = (config: IEnergyConsumptionCostPageData) => {
  const { translations, includeSupport } = config

  return includeSupport
    ? getText('descriptionWithSupport', translations)
    : getText('descriptionWithoutSupport', translations)
}

// ************************************
// Filter options
// ************************************

export const getFilterOptions = (date: Date) => {
  const monthItems: ITabSelectFilterItem[] = []
  const now = new Date()
  const currentMonthDate = new Date(now.getFullYear(), now.getMonth(), 1, 12, 0, 0, 0)
  const previousMonthDate = new Date(new Date(currentMonthDate).setMonth(currentMonthDate.getMonth() - 1))
  const previousMonth = format(previousMonthDate, 'MMMM', { locale: nb })
  const currentMonth = format(currentMonthDate, 'MMMM', { locale: nb })

  monthItems.push({
    id: `${previousMonth}`,
    value: previousMonthDate,
    text: `${_.capitalize(previousMonth)}`,
    active: date.getMonth() === previousMonthDate.getMonth()
  })

  monthItems.push({
    id: `${currentMonth}`,
    value: currentMonthDate,
    text: `${_.capitalize(currentMonth)}`,
    active: date.getMonth() === currentMonthDate.getMonth()
  })

  const months: ITabSelectDropdown = {
    tabScaling: {
      type: 'percentage',
      width: 100
    },
    items: monthItems,
    highlighted: true,
    includeActive: false,
    template: MS_TabSelectDropdownTemplate(
      'Light',
      {
        type: 'percentage',
        width: 100
      },
      false,
      'middle'
    )
  }

  return [months]
}

// ************************************
// PRIVATE
// ************************************

const _getHouse = async (config: IEnergyConsumptionCostPageData, consumptionData: IConsumptionData) => {
  let plankWalls: IMSPlankWall[] = []
  const cost: IMSPlankWall[] = await _getLegendsWalls(config, consumptionData)
  const extra: IMSPlankWall = _getExtraPlankWall(config)

  plankWalls = cost
  plankWalls.push(extra)

  return {
    plankWalls
  } as IPlankHouse
}

const _getLegendsWalls = async (config: IEnergyConsumptionCostPageData, consumptionData: IConsumptionData) => {
  const { translations, includeSupport, setShowSupportPopup, selectedDate } = config

  const { consumption, tariff } = consumptionData

  const supportAmount: number = Number(
    consumption
      .reduce((p: any, c: any) => {
        return p + ((c.cost?.amount ?? 0) - (c.costIncludingSupport?.amount ?? 0))
      }, 0)
      .toFixed(0)
  )

  const plankWalls: IMSPlankWall[] = []

  plankWalls.push({
    planks: [
      getPlankPrefab('Text', {
        left: {
          title: getText('plankSupportTitle', translations),
          description: getText('plankSupportDesc', translations),
          icon: includeSupport ? IconType.Square : undefined,
          customization: {
            icon: {
              type: IconType.Square,
              color: DefaultColumnChartColors[2]
            }
          }
        },
        right: {
          title: includeSupport
            ? createString(getText('plankSupportValue', translations), {
                amount: supportAmount
              })
            : getText('plankSupportOff', translations),
          icon: IconType.ChevronRight
        },
        action: {
          onClick: setShowSupportPopup
        }
      })
    ]
  })

  plankWalls.push({
    planks: [
      getPlankPrefab('Text', {
        left: {
          title: getText('plankCostTitle', translations),
          description: getText('plankCostDesc', translations),
          icon: IconType.Square,
          customization: {
            icon: {
              type: IconType.Square,
              color: DefaultColumnChartColors[1]
            }
          }
        },
        right: {
          title: createString(getText('plankCostValue', translations), {
            amount: consumption
              .reduce((p: any, c: any) => {
                return p + (c.cost?.amount ?? 0)
              }, 0)
              .toFixed(0)
          })
        }
      })
    ]
  })

  const monthText = format(selectedDate, 'MMMM', { locale: nb })

  plankWalls.push({
    planks: [
      getPlankPrefab('Text', {
        left: {
          title: getText('plankGridRentTitle', translations),
          description: getText('plankGridRentDesc', translations),
          icon: IconType.Square,
          customization: {
            icon: {
              type: IconType.Square,
              color: DefaultColumnChartColors[0]
            }
          }
        },
        right: tariff?.totalCost
          ? {
              title: createString(getText('plankGridRentValue', translations), {
                amount: tariff.totalCost.toFixed(0)
              })
            }
          : undefined
      }),
      getPlankPrefab('Text', {
        left: {
          title: getText('plankGridEnergyTitle', translations),
          description: createString(getText('plankGridEnergyDesc', translations), {
            month: monthText
          }),
          icon: IconType.CircleIcon,
          customization: {
            icon: {
              type: IconType.CircleIcon,
              color: BrandColors['background-shade-light-1']
            }
          }
        },
        right: tariff?.energySection?.cost
          ? {
              title: createString(getText('plankCostValue', translations), {
                amount: tariff.energySection.cost.toFixed(0)
              })
            }
          : undefined
      }),
      getPlankPrefab('Text', {
        left: {
          title: getText('fixedRateSoFar', translations),
          description: tariff?.fixedSection?.rateSoFar
            ? createString(getText('plankGridCapacityDesc', translations), {
                step: `${tariff?.fixedSection.startKwh} - ${tariff?.fixedSection.endKwh}`,
                cost: tariff?.fixedSection.rate,
                month: monthText
              })
            : undefined,
          icon: IconType.CircleIcon,
          customization: {
            icon: {
              type: IconType.CircleIcon,
              color: BrandColors['background-shade-light-1']
            }
          }
        },
        right: tariff?.fixedSection?.rateSoFar
          ? {
              title: createString(getText('plankCostValue', translations), {
                amount: tariff.fixedSection.rateSoFar.toFixed(0)
              })
            }
          : undefined
      })
    ]
  } as IMSPlankWall)

  return plankWalls
}

const _getExtraPlankWall = (config: IEnergyConsumptionCostPageData) => {
  const { translations, activeTheme } = config

  return {
    planks: [
      getPlankPrefab('Action', {
        left: {
          title: getText('plankShowInAppTitle', translations)
        },
        right: {
          template: MS_ButtonTemplate(activeTheme, 'primary')
        },
        action: {
          link: getText('plankShowInAppUrl', translations),
          text: getText('readMore', translations),
          icon: IconType.ExternalLinkThick
        },
        actionIconPlacement: 'Right'
      })
    ]
  } as IMSPlankWall
}

const _getParsedChartData = (config: IEnergyConsumptionCostPageData, consumptionData: IConsumptionData) => {
  const { translations, includeSupport, user } = config
  const { installation } = user

  let { consumption } = consumptionData
  const { state, tariff } = consumptionData

  const fromDate = state.from
  let toDate = state.to

  let knownTotal = tariff?.totalCost ?? 0

  const series: any[] = []

  const knownCost: any = {
    name: getText('series1Name', translations),
    data: getConsumptionSeriesData('day', toDate)
  }
  const costSupport: any = {
    name: getText('series3Name', translations),
    data: getConsumptionSeriesData('day', toDate)
  }

  const gridTariff: any = {
    name: getText('series4Name', translations),
    data: getConsumptionSeriesData('day', toDate)
  }

  // For testing purposes
  if (consumption.length === 0 && import.meta.env.DEV) {
    consumption = getMockData({
      resolution: 'day',
      fromDate,
      toDate
    })
  }

  let highestValue = 1

  if (consumption.length > 0) {
    consumption.forEach((con: ICustomerConsumptionPoint, i: number) => {
      if (con.cost && con.costIncludingSupport) {
        const date = new Date(con.startTime)
        const cat = `${format(date, 'd.')}`
        let yValKnown = 0
        let yValSupport = 0
        let yValGridTariff = 0

        if (!con.cost.isEstimated) {
          yValKnown = con.cost.amount
          toDate = new Date(con.startTime)

          if (!includeSupport) {
            knownTotal += con.cost.amount
          }

          if (yValKnown > highestValue) {
            highestValue = yValKnown
          }

          if (includeSupport && !con.costIncludingSupport.isEstimated) {
            const supportedValue = con.cost.amount - con.costIncludingSupport.amount
            yValKnown = con.cost.amount - supportedValue
          }
        }

        knownCost.data.splice(i, 1, {
          x: cat,
          y: yValKnown,
          type: 'known'
        })

        if (includeSupport && !con.costIncludingSupport.isEstimated) {
          if (!con.cost.isEstimated) {
            yValSupport = con.cost.amount - con.costIncludingSupport.amount
          } else {
            yValSupport = 0
          }

          costSupport.data.splice(i, 1, {
            x: cat,
            y: yValSupport,
            type: 'support'
          })

          knownTotal += con.costIncludingSupport.amount

          if (con.cost.amount + yValSupport > highestValue) {
            highestValue = con.cost.amount + yValSupport
          }
        }

        if (con.estimatedGridTariffCost?.energySection && con.estimatedGridTariffCost?.fixedSection) {
          yValGridTariff = con.estimatedGridTariffCost.energySection + (con.estimatedGridTariffCost.fixedSection ?? 0)

          gridTariff.data.splice(i, 1, {
            x: cat,
            y: yValGridTariff,
            type: 'energySection'
          })
        }
      }
    })
  }
  series.push(gridTariff)

  series.push(knownCost)

  if (includeSupport) {
    series.push(costSupport)
  }

  return {
    series,
    max: highestValue,
    header: {
      title: `${installation?.address.streetAddress}`,
      subTitle: `01.${format(fromDate, 'MM')} → ${format(toDate, 'dd.MM')} (${format(fromDate, 'yyyy')})`
    },
    leftDescription: {
      title: `${tNumber(Math.round(knownTotal), 'no-NO')}`,
      titleSuffix: `${getText('currency', translations)}`,
      subTitle: getText('energyCost', translations)
    }
  }
}
