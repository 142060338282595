import {
  BaseComponent,
  type BrandColor,
  BrandColors,
  type IBaseComponent,
  type IPalette,
  getColorCode
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { Constants } from '../../data'
import { ProgressBarTemplate } from './ProgressBarTemplate'
import './ProgressBar.scss'

export interface IProgressBar extends IBaseComponent {
  current: number
  max: number
}

/**
 *    The ProgressBar is used to illustrate current progress based on two values
 *    @type {number} current
 *    @type {number} max
 */
export const ProgressBar = (props: IProgressBar) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    brand = Constants.uiBrand,
    theme = 'Light',
    current = 0,
    max = 1,
    template = ProgressBarTemplate(theme)
  } = props
  const classPrefix = 'progress-bar'

  // ************************************
  // Helper Functionality
  // ************************************

  const _getBarColor = (palette?: IPalette) => {
    const color: BrandColor = {
      color: BrandColors['primary-shade-light-2'],
      opacity: 1
    }

    if (palette?.icon) {
      color.color = palette.icon.color ?? BrandColors['primary-shade-light-2']
      color.opacity = palette.icon.opacity ?? 1
    }

    return color
  }

  // ************************************
  // Render
  // ************************************

  return (
    <BaseComponent
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      brand={brand}
      theme={theme}
      template={template}
    >
      <span
        className={`${classPrefix}__line`}
        style={{
          width: `${(current + 1) * (100 / (max + 1))}%`,
          backgroundColor: getColorCode(_getBarColor(template?.palette))
        }}
      />
      {current !== max && (
        <span
          className={`${classPrefix}__line`}
          style={{
            flex: '1'
          }}
        />
      )}
    </BaseComponent>
  )
}
