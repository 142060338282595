import { type CallState, Icon, IconType } from '@fjordkraft/fjordkraft.component.library'
import { ToastContainer, cssTransition, toast } from 'react-toastify'
import { useApplicationContext } from '../../contexts'
import type { StatusType } from '../../models'
import 'react-toastify/dist/ReactToastify.minimal.css'
import './Toastify.scss'

const animateToast = cssTransition({
  enter: 'toast-in',
  exit: 'toast-out'
})

const calculateOffset = (desktopView: boolean) => {
  const staticToast: any = document.getElementById('application-overlay-wrapper__toaster__static-id')
  const offset = 80
  if (staticToast && desktopView) {
    const bounds = staticToast.getBoundingClientRect()
    return bounds.height + offset
  }
  return offset
}

const callStateToStatusType = (callState: CallState): StatusType => {
  switch (callState) {
    case 'error':
    case 'aborted':
      return 'negative'
    case 'idle':
    case 'pending':
      return 'neutral'
    case 'success':
      return 'positive'
  }
}

const callStateToIcon = (callState: CallState): IconType => {
  switch (callState) {
    case 'error':
    case 'aborted':
      return IconType.WarningBadge
    case 'idle':
    case 'pending':
      return IconType.BellIcon
    case 'success':
      return IconType.CheckmarkThick
  }
}

export const CallStateToast = ({ text, callState }: { text: string; callState: CallState }) => {
  toast(
    <>
      <Icon type={callStateToIcon(callState)} width={1.25} height={1.25} />
      {text}
    </>,
    {
      position: 'top-right',
      autoClose: 2000,
      closeButton: false,
      hideProgressBar: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      bodyClassName: `Toastify__toast-body--${callStateToStatusType(callState)}`
    }
  )
}

export const Toastify = () => {
  const { activeTheme, desktopView } = useApplicationContext()
  return (
    <ToastContainer
      theme={activeTheme.toLowerCase()}
      transition={animateToast}
      style={{ marginTop: calculateOffset(desktopView) }}
    />
  )
}
