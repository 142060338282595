import {
  type IIconAdvanced,
  type IMasterPlank,
  type IRadioPlank,
  type ITextCustomization,
  Icon,
  type IconType,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { h4TextPrefab } from '../../Texts/Header4TextPrefab'
import { paragraphTextPrefab } from '../../Texts/ParagraphTextPrefab'
export interface ITextPlankPrefabCustomization {
  title?: ITextCustomization
  description?: ITextCustomization
  icon?: IIconAdvanced
}

export interface ICheckInputPlankPrefab extends IMasterPlank {
  left?: {
    title?: string
    description?: string
    icon?: IconType
    customization?: ITextPlankPrefabCustomization
  }
  right?: {
    title?: string
    description?: string
    customization?: ITextPlankPrefabCustomization
  }
  value: any
  bottom?: any
  desktopView?: boolean
}

export const CheckInputPlankPrefab = (props: ICheckInputPlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, left, right, bottom, template, active, disabled, value } = props
  const classPrefix = 'ms-text-plank'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderLeftIcon = () => {
    if (left?.icon) {
      return [
        <Icon
          key={uuid4()}
          brand={brand}
          type={left.icon}
          width={left.customization?.icon?.size ?? 1.5}
          height={left.customization?.icon?.size ?? 1.5}
          {...left.customization?.icon}
        />
      ]
    }
  }

  const _renderLeftTitle = () => {
    if (left?.title) {
      return (
        <Text {...h4TextPrefab()} brand={brand} align={'align-left'} {...left.customization?.title}>
          {left.title}
        </Text>
      )
    }
  }

  const _renderLeftDescription = () => {
    if (left?.description) {
      return (
        <Text {...paragraphTextPrefab()} brand={brand} faded align={'align-left'} {...left.customization?.description}>
          {left.description}
        </Text>
      )
    }
  }

  const _renderRightTitle = () => {
    if (right?.title) {
      return (
        <Text {...h4TextPrefab()} brand={brand} align={'align-right'} {...right.customization?.title}>
          {right.title}
        </Text>
      )
    }
  }

  const _renderRightDescription = () => {
    if (right?.description) {
      return (
        <Text
          {...paragraphTextPrefab()}
          brand={brand}
          align={'align-right'}
          theme={getCounterTheme(theme)}
          {...right.customization?.description}
        >
          {right.description}
        </Text>
      )
    }
  }

  const _renderLeft = () => {
    if (left?.title || left?.description) {
      return [_renderLeftTitle(), _renderLeftDescription()]
    }
  }

  const _renderRight = () => {
    if (right?.title || right?.description) {
      return [_renderRightTitle(), _renderRightDescription()]
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    value,
    active,
    disabled,
    brand,
    template,
    leftColumn1: _renderLeftIcon(),
    leftColumn2: _renderLeft(),
    rightColumn1: _renderRight(),
    bottomContent: bottom ? [bottom] : undefined
  } as IRadioPlank
}
