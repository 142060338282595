import type { IMasterPlank } from '@fjordkraft/fjordkraft.component.library'
import { getBrand } from '../../services'
import { AccordionPlankPrefab, type IAccordionPlankPrefab } from './AccordionPlankPrefab/AccordionPlankPrefab'
import { ActionPlankPrefab, type IActionPlankPrefab } from './ActionPlankPrefab/ActionPlankPrefab'
import { CheckInputPlankPrefab, type ICheckInputPlankPrefab } from './CheckInputPlankPrefab/CheckInputPlankPrefab'
import { type IImagePlankPrefab, ImagePlankPrefab } from './ImagePlankPrefab/ImagePlankPrefab'
import { type IInvoicePlankPrefab, InvoicePlankPrefab } from './InvoicePlankPrefab/InvoicePlankPrefab'
import { type ILoadingPlankPrefab, LoadingPlankPrefab } from './LoadingPlankPrefab/LoadingPlankPrefab'
import { type IPlaceholderPlankPrefab, PlaceholderPlankPrefab } from './PlaceholderPlankPrefab/PlaceholderPlankPrefab'
import { type IPointPlankPrefab, PointPlankPrefab } from './PointPlankPrefab/PointPlankPrefab'
import { type IRadioPlankPrefab, RadioPlankPrefab } from './RadioPlankPrefab/RadioPlankPrefab'
import { type ITextPlankPrefab, TextPlankPrefab } from './TextPlankPrefab/TextPlankPrefab'
import { type ITogglePlankPrefab, TogglePlankPrefab } from './TogglePlankPrefab/TogglePlankPrefab'
import { type ITooltipPlankPrefab, TooltipPlankPrefab } from './TooltipPlankPrefab/TooltipPlankprefab'

export * from './TextPlankPrefab/TextPlankPrefab'
export * from './TogglePlankPrefab/TogglePlankPrefab'
export * from './LoadingPlankPrefab/LoadingPlankPrefab'
export * from './InvoicePlankPrefab/InvoicePlankPrefab'
export * from './PointPlankPrefab/PointPlankPrefab'
export * from './AccordionPlankPrefab/AccordionPlankPrefab'

type PlankPrefabType =
  | 'Text'
  | 'Toggle'
  | 'Checkbox'
  | 'Invoice'
  | 'Image'
  | 'Loading'
  | 'Error'
  | 'Point'
  | 'Action'
  | 'Radio'
  | 'Tooltip'
  | 'Accordion'
  | 'CheckInput'
  | 'Placeholder'

export interface TypedPlank {
  type: PlankPrefabType
  data: PlankPrefabInterface
}

export type PlankPrefabInterface =
  | ITextPlankPrefab
  | ITogglePlankPrefab
  | ILoadingPlankPrefab
  | IImagePlankPrefab
  | IInvoicePlankPrefab
  | IPointPlankPrefab
  | IActionPlankPrefab
  | IRadioPlankPrefab
  | ITooltipPlankPrefab
  | IAccordionPlankPrefab
  | ICheckInputPlankPrefab
  | IPlaceholderPlankPrefab

export const getPlankPrefab = (type: PlankPrefabType, data: PlankPrefabInterface) => {
  let plankPrefab: IMasterPlank = {}

  data.brand = data.brand ?? getBrand()
  data.theme = data.theme ?? 'Light'

  switch (type) {
    case 'Text':
      plankPrefab = TextPlankPrefab(data as ITextPlankPrefab)
      break
    case 'Toggle':
      plankPrefab = TogglePlankPrefab(data as ITogglePlankPrefab)
      break
    case 'Loading':
      plankPrefab = LoadingPlankPrefab(data as ILoadingPlankPrefab)
      break
    case 'Invoice':
      plankPrefab = InvoicePlankPrefab(data as IInvoicePlankPrefab)
      break
    case 'Image':
      plankPrefab = ImagePlankPrefab(data as IImagePlankPrefab)
      break
    case 'Point':
      plankPrefab = PointPlankPrefab(data as IPointPlankPrefab)
      break
    case 'Action':
      plankPrefab = ActionPlankPrefab(data as IActionPlankPrefab)
      break
    case 'Radio':
      plankPrefab = RadioPlankPrefab(data as IRadioPlankPrefab)
      break
    case 'Tooltip':
      plankPrefab = TooltipPlankPrefab(data as ITooltipPlankPrefab)
      break
    case 'Accordion':
      plankPrefab = AccordionPlankPrefab(data as IAccordionPlankPrefab)
      break
    case 'CheckInput':
      plankPrefab = CheckInputPlankPrefab(data as ICheckInputPlankPrefab)
      break
    case 'Placeholder':
      plankPrefab = PlaceholderPlankPrefab(data as IPlaceholderPlankPrefab)
      break
  }

  plankPrefab = { ...data, ...plankPrefab }

  return plankPrefab
}
