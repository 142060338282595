import { isEmpty } from 'lodash'
import type { getRequest, postRequest } from '../../contexts'

export const fetchBenefits = async (GET: getRequest) => {
  const resp = await GET('benefits')
  return resp?.data
}

export const fetchEarnPointsCategory = async (GET: getRequest) => {
  const resp = await GET('benefits/earnpointscategory')
  return resp?.data?.replaceAll(' ', '_')
}

export const fetchRecruitLink = async (GET: getRequest) => {
  const resp = await GET('referrerid')
  const baseUrl = 'https://www.fjordkraft.no/strom/verve/bestill-strom/?referrer_id='
  return baseUrl + resp.data
}

export const fetchKickbackSummary = async (GET: getRequest) => {
  const resp = await GET('kickback')

  if (resp.callState === 'success' && resp.data && !isEmpty(resp.data)) {
    return resp.data
  }
  return {
    amount: 0,
    hasOnboarded: false,
    expiringPoints: null
  }
}

export const fetchKickbackTransactions = async (GET: getRequest) => {
  const resp = await GET('kickback/transactions')
  return resp?.data
}

export const postKickbackDeduction = async (POST: postRequest, amount: number, energyAccountId: string | undefined) => {
  const energyAccountQueryParam = energyAccountId ? `&energyAccountId=${energyAccountId}` : ''
  return await POST(`kickback/deduct/invoice?amount=${amount}${energyAccountQueryParam}`, undefined)
}
