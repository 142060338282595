import { createContext, useContext } from 'react'

export type IDefaultPageContentClampType = 'clamp' | 'scale' | 'stretch'

interface IDefaultPageContext {
  setContentLoading: (contentLoading: boolean) => void
  contentLoading: boolean
  mainContentClamping: IDefaultPageContentClampType
  setMainContentClamping: (value: IDefaultPageContentClampType) => void
  setShowBottomEditorialContent: (show: boolean) => void
  ScrollIntoAppView: () => void
  ScrollIntoMainView: () => void
}

export const DefaultPageContext = createContext<IDefaultPageContext>({
  contentLoading: true,
  setContentLoading: () => {},
  mainContentClamping: 'clamp',
  setMainContentClamping: () => {},
  setShowBottomEditorialContent: () => {},
  ScrollIntoAppView: () => {},
  ScrollIntoMainView: () => {}
})

export const useDefaultPageContext = () => useContext(DefaultPageContext)
