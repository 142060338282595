import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { _getTextPlankPrefabTemplate } from '.'
import type { IStatePlank } from '../../../components'
import { Constants } from '../../../data'
import type { IAddonData, ServiceStatus } from '../../../models'
import {
  AllowFeature,
  IsActiveCustomer,
  createString,
  getParsedAddonStates,
  getText,
  hasServiceActivated,
  shouldServiceBeAvailable
} from '../../../services'
import { getSteddiStatus } from '../../ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices'
import type { IHomePageData } from '../HomePageData'

export const ServicesPlank = (props: IHomePageData): IStatePlank | undefined => {
  const { epiChildren, translations, user, services } = props
  const { installation } = user
  const { customerServiceFeature } = services

  if (
    AllowFeature(Constants.features.services, translations, services.user, customerServiceFeature) &&
    IsActiveCustomer(installation)
  ) {
    return {
      promise: fetchServicesPlank(props, epiChildren)
    }
  }
}

const fetchServicesPlank = async (props: IHomePageData, epiChildren: any) => {
  const { activeTheme, activeBrand, translations, services, user } = props
  const { installation, userData } = user
  const { GET, customerServiceFeature } = services

  let addonCount = 0
  let totalCount = 0
  if (epiChildren) {
    const [addonStates, steddiStatus] = await Promise.all([
      getParsedAddonStates(epiChildren, GET),
      getSteddiStatus(userData.accounts)
    ])
    if (addonStates) {
      addonStates.forEach((addon: IAddonData) => {
        let addonState: ServiceStatus | undefined

        if (addon.page.data.servicePageId === 'forutsigbar') {
          addonState = steddiStatus
        } else if (installation) {
          addonState = addon.state[installation.meterId]?.state
        }

        if (addonState && shouldServiceBeAvailable(addon.page.data, user, customerServiceFeature, addonState)) {
          if (hasServiceActivated(addonState)) {
            addonCount++
          }
          totalCount++
        }
      })
    }
  }

  return _getTextPlankPrefabTemplate({
    theme: activeTheme,
    brand: activeBrand,
    title: getText('plankServicesTitle', translations),
    description: getText('plankServicesDesc', translations),
    rightTitle: createString(getText('plankServicesValuePlural', translations), {
      amount: `${addonCount} / ${totalCount}`
    }),
    link: Constants.paths.servicesPage,
    iconRight: IconType.ChevronRight
  })
}
