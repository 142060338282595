import { type CallState, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { useState } from 'react'
import { CommercialBlock, type IPlankHouse, InvoiceCardBlock, PlankHouseBlock } from '../../../blocks'
import { FixedInvoiceDueDateSelector, type IInvoiceCard } from '../../../components'
import { useApplicationCoreDataContext } from '../../../contexts'
import { Constants } from '../../../data'
import { InvoiceDueDateModal } from '../../../modals'
import type { ICustomerInvoice } from '../../../models'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './CurrentInvoicesPageData'
import './CurrentInvoicesPage.scss'
import { CallStateToast } from '../../../components/Toastify/Toastify'

export interface ICurrentInvoicePage extends IDefaultViewProps {
  invoiceBlockData?: IInvoiceCard[]
  invoiceNavigationData: IPlankHouse
  fixedDueDateOptions: any
  nextAndPreviousInvoices?: IPlankHouse
  noPreviousInvoices: boolean
}

export const CurrentInvoicesPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { updateCustomerData } = useApplicationCoreDataContext()
  const classPrefix = 'invoice-page'

  // ************************************
  // Lifecycle
  // ************************************

  const [showDelayPopup, setShowDelayPopup] = useState<ICustomerInvoice>()

  // ************************************
  // Render Functionality
  // ************************************

  const _renderInvoiceDateModal = (config: ICurrentInvoicePage) => {
    const { translations } = config

    if (showDelayPopup) {
      return (
        <InvoiceDueDateModal
          theme={config.activeTheme}
          brand={config.activeBrand}
          invoice={showDelayPopup}
          translation={translations}
          title={translations.delayPopup.title}
          preface={translations.delayPopup.preface}
          description={translations.delayPopup.description}
          delayButtonText={translations.delayPopup.delay}
          abortButtonText={translations.delayPopup.cancel}
          callStateChange={(message: string, state: CallState) => {
            CallStateToast({
              text: message,
              callState: state
            })
            updateCustomerData(true)
          }}
          close={() => {
            setShowDelayPopup(undefined)
          }}
        />
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.invoice.type,
      usesSubPage: true
    },
    dependenciesOptional: [{ showDelayPopup }],
    handleData: getPageContent,
    render: (config: ICurrentInvoicePage) => {
      const {
        activeBrand,
        activeTheme,
        translations,
        nextAndPreviousInvoices,
        invoiceBlockData,
        fixedDueDateOptions,
        invoiceNavigationData
      } = config

      return (
        <StyleGrid className={classPrefix} direction='column' alignment='top-left' boxSizing='border-box'>
          {nextAndPreviousInvoices && (
            <PlankHouseBlock {...nextAndPreviousInvoices} className={`${classPrefix}__navigation-top`} />
          )}
          {invoiceBlockData && invoiceBlockData.length > 0 && (
            <InvoiceCardBlock
              itemGroups={invoiceBlockData}
              theme={activeTheme}
              brand={activeBrand}
              onChange={(value: any) => {
                setShowDelayPopup(value)
              }}
            />
          )}
          {invoiceNavigationData && (
            <PlankHouseBlock
              {...invoiceNavigationData}
              className={`${classPrefix}__navigation-bottom`}
              theme={activeTheme}
              brand={activeBrand}
            />
          )}
          {translations && fixedDueDateOptions && (
            <FixedInvoiceDueDateSelector accountInformation={fixedDueDateOptions} />
          )}
          {showDelayPopup && translations && _renderInvoiceDateModal(config)}
          <CommercialBlock className={`${classPrefix}__commercial-block`} />
        </StyleGrid>
      )
    }
  })
}
