import {
  type IAction,
  type IComponent,
  type IconType,
  StyleGrid,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { v4 as uuid4 } from 'uuid'
import { GetToastPrefab, MS_StaticStatusToastTemplate, ToastPrefabsType } from '../../../Prefabs'
import { DynamicEpiContentBlock } from '../../../blocks'
import {
  SubPageLayoutContext,
  useApplicationDefaultContext,
  useApplicationOverlayWrapperContext
} from '../../../contexts'
import type { ILocationNavigationInfoBoxContent } from '../../../models'
import './SubPageLayout.scss'
import { Toast } from '../../../components/Toaster'
import { LocationNavHeader } from '../../../shared/LocationNavHeader/LocationNavHeader'

export interface ISubPageLayout extends IComponent {
  autoAdjust?: boolean
}

export interface IStatusToast {
  text: string
  icon: IconType
  status: 'positive' | 'negative' | 'neutral'
}

export const SubPageLayout = (props: ISubPageLayout) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'sub-page-layout'
  const { children, autoAdjust = true } = props
  const { defaultProps } = useApplicationDefaultContext()
  const { globalLoading } = useApplicationOverlayWrapperContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [title, setTitle] = useState<string>()
  const [subTitle, setSubTitle] = useState<string>()
  const [back, setBack] = useState<IAction>()
  const [infoBoxContent, setInfoBoxContent] = useState<ILocationNavigationInfoBoxContent | null>(null)
  const [showInstallationSelector, setShowInstallationSelector] = useState<boolean>(false)
  const [statusToast, setStatusToast] = useState<IStatusToast>()
  const [description, setDescription] = useState<string>()
  const [descriptionTooltip, setDescriptionTooltip] = useState<string>()

  useEffect(() => {
    setStatusToast(undefined)
  }, [defaultProps])

  const contextHandler = useMemo(() => {
    return {
      title,
      setTitle,
      subTitle,
      setSubTitle,
      back,
      setBack,
      infoBoxContent,
      setInfoBoxContent,
      showInstallationSelector,
      setShowInstallationSelector,
      statusToast,
      setStatusToast,
      description,
      setDescription,
      descriptionTooltip,
      setDescriptionTooltip
    }
  }, [title, subTitle, back, infoBoxContent, showInstallationSelector, statusToast, description, descriptionTooltip])

  const _renderStatusToast = () => {
    if (statusToast && defaultProps) {
      return (
        <Toast
          {...GetToastPrefab(ToastPrefabsType.Generic, {
            id: uuid4(),
            className: `${classPrefix}__status-toast`,
            description: statusToast.text,
            icon: statusToast.icon,
            brand: defaultProps.activeBrand,
            theme: getCounterTheme(defaultProps.activeTheme),
            template: MS_StaticStatusToastTemplate({
              theme: defaultProps.activeTheme,
              status: statusToast.status
            })
          })}
          staticToast={true}
        />
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <SubPageLayoutContext.Provider value={contextHandler}>
      <StyleGrid
        id={`${classPrefix}-id`}
        className={`${classPrefix}`}
        direction='column'
        alignment={globalLoading ? 'center' : 'top-center'}
      >
        {!globalLoading && (
          <>
            <LocationNavHeader
              title={title}
              subTitle={subTitle}
              description={description}
              descriptionTooltip={descriptionTooltip}
              infoBoxContent={infoBoxContent}
              back={back}
              autoAdjust={autoAdjust}
              showInstallationSelector={showInstallationSelector}
            />
            {_renderStatusToast()}
          </>
        )}
        {!globalLoading && defaultProps?.translations?.topEditorialContent?.length > 0 && (
          <DynamicEpiContentBlock
            theme={defaultProps?.activeTheme}
            brand={defaultProps?.activeBrand}
            epiItems={defaultProps?.translations?.topEditorialContent}
          />
        )}
        <Outlet />
        {children}
      </StyleGrid>
    </SubPageLayoutContext.Provider>
  )
}
