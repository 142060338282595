import { BrandColors } from '@fjordkraft/fjordkraft.component.library'

export const DefaultColumnChartColors = [
  BrandColors['chart-shade-light-1'],
  BrandColors['chart-shade-light-2'],
  BrandColors['chart-shade-light-3'],
  BrandColors['chart-shade-dark-1'],
  BrandColors['chart-shade-dark-2'],
  BrandColors['chart-shade-dark-3']
]
