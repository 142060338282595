import { ContentGrid, type IMasterPlank, Icon, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { smallParagraphTextPrefab } from '../../Texts/SmallParagraphTextPrefab'
import type { ITextPlankPrefab } from '../TextPlankPrefab/TextPlankPrefab'

export interface ITooltipPlankPrefab extends Omit<ITextPlankPrefab, 'action'> {}

export const TooltipPlankPrefab = (props: ITooltipPlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, left, right, bottom, template } = props
  const classPrefix = 'ms-tooltip-plank'

  // ************************************
  // Lifecycle
  // ************************************

  let showTooltip = false

  // ************************************
  // Render Functionality
  // ************************************

  const _renderBottomContent = () => {
    if (bottom) {
      return (
        <ContentGrid tagType='section' alignment='top-left' direction='column' boxSizing='border-box' gap={1}>
          {bottom.title && (
            <Text
              brand={brand}
              weight={500}
              size={'regular'}
              family={'main'}
              type={'h4'}
              theme={getCounterTheme(theme)}
              align={'align-right'}
            >
              {bottom.title}
            </Text>
          )}
          {bottom.description && (
            <Text
              brand={brand}
              weight={400}
              size={'small'}
              family={'main'}
              align={'align-right'}
              type={'p'}
              theme={getCounterTheme(theme)}
              {...bottom.customization?.description}
            >
              {bottom.description}
            </Text>
          )}
        </ContentGrid>
      )
    }
  }

  const _renderLeftIcon = () => {
    if (left?.icon) {
      return [
        <Icon
          key={uuid4()}
          brand={brand}
          type={left.icon}
          width={left.customization?.icon?.size ?? 1.5}
          height={left.customization?.icon?.size ?? 1.5}
          {...left.customization?.icon}
        />
      ]
    }
  }

  const _renderLeftTitle = () => {
    if (left?.title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          align={'align-left'}
          {...left.customization?.title}
        >
          {left.title}
        </Text>
      )
    }
  }

  const _renderLeftDescription = () => {
    if (left?.description) {
      return (
        <Text
          brand={brand}
          weight={400}
          size={'small'}
          family={'main'}
          type={'p'}
          faded
          align={'align-left'}
          {...left.customization?.description}
        >
          {left.description}
        </Text>
      )
    }
  }

  const _renderLeftExtra = () => {
    if (left?.extra) {
      return (
        <Text
          className={`${classPrefix}__left__text__extra`}
          brand={brand}
          {...smallParagraphTextPrefab()}
          size={'tiny'}
          faded
          align={'align-left'}
          {...left.customization?.description}
        >
          {left.extra}
        </Text>
      )
    }
  }

  const _renderRightIcon = () => {
    if (right?.icon) {
      return [
        <Icon
          key={uuid4()}
          brand={brand}
          type={right.icon}
          width={right.customization?.icon?.size ?? 1.5}
          height={right.customization?.icon?.size ?? 1.5}
          {...right.customization?.icon}
        />
      ]
    }
  }

  const _renderRightTitle = () => {
    if (right?.title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          align={'align-right'}
          {...right.customization?.title}
        >
          {right.title}
        </Text>
      )
    }
  }

  const _renderRightDescription = () => {
    if (right?.description) {
      return (
        <Text
          brand={brand}
          weight={400}
          size={'small'}
          family={'main'}
          align={'align-right'}
          type={'p'}
          theme={getCounterTheme(theme)}
          {...right.customization?.description}
        >
          {right.description}
        </Text>
      )
    }
  }

  const _renderLeft = () => {
    if (left?.title || left?.description || left?.extra) {
      return [_renderLeftTitle(), _renderLeftDescription(), _renderLeftExtra()]
    }
  }

  const _renderRight = () => {
    if (right?.title || right?.description) {
      return [_renderRightTitle(), _renderRightDescription()]
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    brand,
    template,
    leftColumn1: _renderLeftIcon(),
    leftColumn2: _renderLeft(),
    rightColumn1: _renderRight(),
    rightColumn2: _renderRightIcon(),
    bottomContent: showTooltip ? _renderBottomContent() : undefined,
    action: {
      onClick: () => {
        showTooltip = !showTooltip
      }
    }
  } as IMasterPlank
}
