import { BrandColors, Chart, type IChartTooltip, getColorCode } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { tNumber } from '../../../../services'
import { ChartCard, type IChartCard } from '../ChartCard'

export interface ILineChartCard extends IChartCard {
  series: any[]
  categories?: string[]
  chartTooltip?: IChartTooltip
}

export const LineChartCard = (props: ILineChartCard) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id = 'fb3a5730-b07a-4c19-86e4-a85bb871019b',
    className,
    brand,
    theme,
    series,
    categories,
    chartTooltip
  } = props
  const classPrefix = 'line-chart-card'

  // ************************************
  // Lifecycle
  // ************************************

  const [clampValues, setClampValues] = useState<any>({ min: 0, max: 1 })

  useEffect(() => {
    let min = 9999
    let max = 1

    if (series.length > 0) {
      series.forEach((serie: any) => {
        if (serie.data.length > 0) {
          serie.data.forEach((val: number) => {
            if (val < min) {
              min = val
            }

            if (val > max) {
              max = val
            }
          })
        }
      })
    }

    max = Math.ceil(max * 100) / 100

    if (min < 0) {
      min = -Math.abs(min) * 1.1
      min = Math.floor(min * 100) / 100
    } else {
      min = 0
    }

    setClampValues({ min, max })
  }, [series])

  // ************************************
  // Render
  // ************************************

  return (
    <ChartCard
      {...props}
      id={id}
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
    >
      {series && series.length > 0 && (
        <Chart
          className={`${classPrefix}__chart`}
          series={series}
          categories={categories}
          type={'line'}
          height={'300px'}
          brand={brand}
          theme={theme}
          legends={{
            show: true
          }}
          chartTooltip={chartTooltip}
          strokeCustomization={{
            type: ['smooth', 'smooth'],
            dash: [0, 0]
          }}
          fillCustomization={{
            type: ['solid', 'none'],
            opacity: [1, 1]
          }}
          colors={[
            getColorCode({
              color: BrandColors['primary-shade-light-2'],
              type: 'rgb',
              element: document.getElementById(id)
            }),
            getColorCode({
              color: BrandColors['background-shade-light-3'],
              type: 'rgb',
              element: document.getElementById(id)
            })
          ]}
          xAxisCustomization={{
            tickAmount: 12
          }}
          yAxisCustomization={{
            tickAmount: 4,
            min: clampValues.min,
            max: clampValues.max,
            formatter: (value: any) => {
              const valueParsed = tNumber(value, 'no-NO', 0)

              return chartTooltip?.y?.showOnAxisLabels ? `${valueParsed} ${chartTooltip.y.suffix}` : valueParsed
            }
          }}
        />
      )}
    </ChartCard>
  )
}
