import { type IText, Text } from '@fjordkraft/fjordkraft.component.library'
import { animated, useSpring } from '@react-spring/web'

export interface IAnimatedCounter extends IText {
  from?: number
  n: number
}

export const AnimatedCounter = (props: IAnimatedCounter) => {
  const { from = 0, n } = props
  const { number } = useSpring({
    config: {
      mass: 1,
      tension: 10,
      friction: 10
    },
    from: { number: from },
    number: n,
    delay: 300
  })

  return (
    <Text {...props}>
      <animated.span>{number.to(n => n.toFixed(0))}</animated.span>
    </Text>
  )
}
