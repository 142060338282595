import { StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import classname from 'classnames'
import { useState } from 'react'
import {
  CommercialBlock,
  DynamicEpiContentBlock,
  HomePageBannerBlock,
  type IMSPlankWall,
  PlankHouseBlock
} from '../../blocks'
import { ArticleItem, type IArticleItem } from '../../components'
import type { IPlankDropdown } from '../../components/PlankDropdown/PlankDropdown'
import {
  useApplicationCoreDataContext,
  useApplicationOverlayWrapperContext,
  useDefaultPageContext
} from '../../contexts'
import { Constants } from '../../data'
import { NewInstallationModal } from '../../modals'
import { type IDefaultViewProps, PageV2 } from '../PageV2'
import { getPageContent } from './HomePageData'
import './HomePage.scss'

export interface IHomePageView extends IDefaultViewProps {
  article: any
  plankWalls: { topWalls: IMSPlankWall[]; bottomWalls: IMSPlankWall[]; middleWalls: IMSPlankWall[] }
  upgradeOptions: any
  onboardingArticle?: IArticleItem
  guestDropdown?: IPlankDropdown
}

export const HomePage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'home-page'
  const { updateCustomerData } = useApplicationCoreDataContext()
  const { setContentLoading, setMainContentClamping } = useDefaultPageContext()
  const { setGlobalLoading } = useApplicationOverlayWrapperContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [showAddInstallationMeter, setShowAddInstallationMeter] = useState<boolean>(false)

  // ************************************
  // Render Functionality
  // ************************************

  const _renderMainNavigation = (config: IHomePageView, plankWalls: IMSPlankWall[]) => {
    const { activeBrand, activeTheme } = config

    if (plankWalls && plankWalls.length > 0) {
      return (
        <PlankHouseBlock
          className={`${classPrefix}__house`}
          theme={activeTheme}
          brand={activeBrand}
          plankWalls={plankWalls}
        />
      )
    }
  }

  const _handleSkipOnboarded = async (config: IHomePageView) => {
    const { POST } = config.services

    await POST('Customers/setHasOnboarded')
    await updateCustomerData(true)
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.home.type
    },
    dependencies: [{ setShowAddInstallationMeter }, { showAddInstallationMeter }],
    loadingComplete: () => {
      setTimeout(() => {
        setGlobalLoading(false)
      }, 200)
    },
    onDataChange: (config: IHomePageView) => {
      setMainContentClamping(config.desktopView ? 'clamp' : 'stretch')
    },
    handleData: getPageContent,
    render: (config: IHomePageView) => {
      const { desktopView, activeBrand, activeTheme, user } = config
      const { installation } = user

      return (
        <StyleGrid
          id={`${classPrefix}-id`}
          className={`${classPrefix}`}
          direction='column'
          alignment={'top-left'}
          gap={10}
        >
          {config && <HomePageBannerBlock className={`${classPrefix}__banner`} {...config} />}
          <StyleGrid className={`${classPrefix}__middle`} alignment='top-center' direction='column'>
            <StyleGrid
              className={classname(`${classPrefix}__middle__navigation-wrapper`, {
                [`${classPrefix}__middle__navigation-wrapper--${activeTheme}`]: activeTheme
              })}
              brand={activeBrand}
              alignment={desktopView ? 'top-left' : 'top-center'}
              direction='column'
              gap={2}
              boxSizing='border-box'
            >
              {config?.translations?.topEditorialContent && (
                <DynamicEpiContentBlock
                  epiItems={config.translations.topEditorialContent}
                  theme={activeTheme}
                  brand={activeBrand}
                />
              )}
              {config && _renderMainNavigation(config, config.plankWalls.topWalls)}
              {showAddInstallationMeter && (
                <NewInstallationModal
                  theme={activeTheme}
                  brand={activeBrand}
                  translations={config?.translations}
                  onClose={() => {
                    setShowAddInstallationMeter(false)
                  }}
                  onSuccess={() => {
                    setContentLoading(true)
                  }}
                  installation={installation}
                />
              )}
              {config && <CommercialBlock />}
              {config && _renderMainNavigation(config, config.plankWalls.middleWalls)}
              {config?.onboardingArticle && (
                <ArticleItem
                  {...config.onboardingArticle}
                  className={`${classPrefix}__tutorial-article`}
                  onClose={() => {
                    _handleSkipOnboarded(config)
                  }}
                />
              )}
              {config && _renderMainNavigation(config, config.plankWalls.bottomWalls)}
            </StyleGrid>
          </StyleGrid>
        </StyleGrid>
      )
    }
  })
}
