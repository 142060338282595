import { type IComponent, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useMemo } from 'react'
import { v4 as uuid4 } from 'uuid'
import { type IInvoiceCard, InvoiceCard } from '../../components'
import './InvoiceCardBlock.scss'

export interface IInvoiceCardBlock extends IComponent {
  itemGroups: IInvoiceCard[]
  onChange?: (value: any) => void
}

/**
 *   InvoiceCardBlock is used for several InvoiceCads
 *   @type {IInvoiceCard} itemGroups (required)
 *   @type {function} onChange (optional)
 */

export const InvoiceCardBlock = (props: IInvoiceCardBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, theme, brand, className, itemGroups, onChange } = props
  const classPrefix = 'ms-invoice-card-block'

  // ************************************
  // Render Functionality
  // ************************************

  const generateItems = useMemo(() => {
    if (itemGroups && itemGroups.length > 0) {
      return itemGroups.map((listData: IInvoiceCard) => {
        return (
          <InvoiceCard
            key={uuid4()}
            className={`${classPrefix}__group`}
            theme={theme}
            brand={brand}
            onDelay={onChange}
            {...listData}
          />
        )
      })
    }
  }, [itemGroups, theme, brand])

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classnames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-left'
      gap={1}
      boxSizing='border-box'
    >
      {generateItems}
    </StyleGrid>
  )
}
