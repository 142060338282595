import {
  BrandColors,
  ContentGrid,
  type IComponent,
  type IText,
  type StyleLoopLimit,
  Text,
  TooltipIcon,
  getColorCode,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { h4TextPrefab } from '../../Prefabs'
import { MSRichText } from '../../components'
import './OptimizedPlankWallBase.scss'

export interface IOptimizedPlankWallBase extends IComponent {
  title?: string
  titleCustomization?: Omit<IText, 'type'>
  customization?: {
    titleGap?: StyleLoopLimit
    backgroundColor: {
      Light: BrandColors
      Dark: BrandColors
    }
  }
  optimizationSettings?: {
    plankAverageHeightInRem: number
    plankCount: number
  }
}

export const OptimizedPlankWallBase = (props: IOptimizedPlankWallBase) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    brand,
    theme = 'Light',
    title,
    titleCustomization = {
      size: 'medium',
      weight: 600
    },
    render = true,
    customization = {
      titleGap: 2,
      backgroundColor: {
        Light: BrandColors['background-shade-light-2'],
        Dark: BrandColors['background-shade-dark-3']
      }
    },
    tooltip,
    children,
    optimizationSettings = {
      plankAverageHeightInRem: 4,
      plankCount: 0
    }
  } = props
  const classPrefix = 'plank-wall-optimized'

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      id={id}
      className={classnames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      brand={brand}
      theme={theme}
      alignment={'top-left'}
      direction={'column'}
      render={render}
      gap={customization.titleGap}
      tagType={'section'}
    >
      {title && (
        <ContentGrid
          className={`${classPrefix}__header`}
          tagType={'section'}
          alignment={'center-left'}
          direction={'row'}
          boxSizing='border-box'
          spaceBetween
        >
          <Text {...h4TextPrefab()} brand={brand} theme={getCounterTheme(theme)} {...titleCustomization}>
            {title}
          </Text>
          {tooltip && (
            <TooltipIcon brand={brand} theme={getCounterTheme(theme)} alignment='center-left'>
              <MSRichText className={`${classPrefix}__tooltip`} text={tooltip} theme={theme} />
            </TooltipIcon>
          )}
        </ContentGrid>
      )}
      <ContentGrid
        className={`${classPrefix}__content`}
        wrap={false}
        boxSizing='border-box'
        direction='column'
        alignment='top-left'
        gap={2}
        gapType='px'
        tagType='nav'
        style={
          optimizationSettings
            ? {
                backgroundColor: getColorCode({ color: customization.backgroundColor[theme] })
              }
            : undefined
        }
      >
        {children}
      </ContentGrid>
    </ContentGrid>
  )
}
