import { ContentGrid } from '@fjordkraft/fjordkraft.component.library'
import type { ReactNode } from 'react'
import type { ITranslationItem } from '../../../models'
import { ContentText } from './ContentText'

export const StepContentWrapper = ({
  classPrefix,
  children,
  texts,
  textKey
}: {
  classPrefix: string
  children: ReactNode
  texts: ITranslationItem[]
  textKey: string
}) => {
  return (
    <ContentGrid
      className={`${classPrefix}__step-content`}
      direction='column'
      alignment='center'
      boxSizing='border-box'
      tagType='section'
      gap={2}
    >
      {children}
      <ContentText textKey={textKey} texts={texts} />
    </ContentGrid>
  )
}
