import { CheckInput, ContentGrid, type Theme, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { v4 as uuid4 } from 'uuid'
import { MSCheckInputTemplate } from '../../../Prefabs'
import { MSRichText } from '../../../components'
import type { IServicePopupCheckbox } from '../../../models'

export const ContentCheckbox = ({
  classPrefix,
  brand,
  theme,
  checkbox,
  onCheckboxChange
}: {
  classPrefix: string
  brand?: string
  theme: Theme
  checkbox: IServicePopupCheckbox
  onCheckboxChange: (change: IServicePopupCheckbox) => void
}) => {
  return (
    <ContentGrid
      className={`${classPrefix}__content__checkbox-row`}
      key={uuid4()}
      direction='row'
      alignment='top-left'
      tagType='section'
      gap={2}
      wrap={false}
    >
      <CheckInput
        brand={brand}
        theme={theme}
        active={checkbox.alreadyChecked}
        value={checkbox.alreadyChecked}
        template={MSCheckInputTemplate(theme, 'checkbox')}
        onChange={() => {
          checkbox.alreadyChecked = !checkbox.alreadyChecked
          onCheckboxChange(checkbox)
        }}
      />
      <MSRichText
        className={`${classPrefix}__content__checkbox-row__description`}
        brand={brand}
        theme={getCounterTheme(theme)}
        text={checkbox.checkboxDescription}
        alignment='lawful'
      />
    </ContentGrid>
  )
}
