import {
  type Alignment,
  BaseComponent,
  type Direction,
  type IBaseComponent
} from '@fjordkraft/fjordkraft.component.library'
import { useEffect } from 'react'
import { CardTemplate } from '../../Prefabs'

export interface ICard extends IBaseComponent {
  direction?: Direction
  alignment?: Alignment
}

export const Card = (props: ICard) => {
  const {
    id,
    className,
    theme,
    template = CardTemplate(theme),
    state,
    children,
    direction,
    alignment,
    gap,
    style,
    brand,
    scaling
  } = props

  useEffect(() => {
    if (direction && template.transform.grid) {
      template.transform.grid.direction = direction
    }

    if (alignment && template.transform.grid) {
      template.transform.grid.alignment = alignment
    }

    if (gap && template.transform.grid) {
      template.transform.grid.gap = gap
    }

    if (scaling && template.transform.grid?.scaling) {
      template.transform.grid.scaling = scaling
    }
  }, [template])

  return (
    <BaseComponent
      id={id}
      className={className}
      state={state}
      template={template}
      style={style}
      brand={brand}
      scaling={scaling}
    >
      {children}
    </BaseComponent>
  )
}
