import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import { _getTextPlankPrefabTemplate } from '.'
import type { IStatePlank } from '../../../components'
import { Constants } from '../../../data'
import { AllowFeature, IsActiveCustomer, getText } from '../../../services'
import type { ITrumfTransactions } from '../../ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices/TrumfServicePage'
import type { IHomePageData } from '../HomePageData'

export const TrumfPlank = (config: IHomePageData): IStatePlank | undefined => {
  const { translations, user, services } = config
  const { installation } = user
  const { customerServiceFeature } = services

  if (
    AllowFeature(Constants.features.trumf, translations, services.user, customerServiceFeature) &&
    IsActiveCustomer(installation)
  ) {
    return {
      promise: fetchTrumfPlank(config)
    }
  }
}

const fetchTrumfPlank = async (props: IHomePageData) => {
  const { activeTheme, activeBrand, translations, services } = props
  const { GETTYPED } = services

  let desc = ''
  let value = ''
  const resp = await GETTYPED<ITrumfTransactions>('Trumf/transactions')

  if (resp?.callState === 'success' && resp?.data) {
    const trumfData = resp.data

    value = `${trumfData.sumPoints} ${getText('currency', translations)}`
    desc = `${format(new Date(trumfData.periodStart), 'dd.MM.yyyy')} - ${format(
      new Date(trumfData.periodEnd),
      'dd.MM.yyyy'
    )}`

    return _getTextPlankPrefabTemplate({
      theme: activeTheme,
      brand: activeBrand,
      title: getText('plankTrumfTitle', translations),
      description: desc,
      rightTitle: value,
      link: `${Constants.paths.servicesPage}/trumf`,
      iconRight: IconType.ChevronRight
    })
  }
}
