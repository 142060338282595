import {
  BaseComponent,
  ContentGrid,
  type IAction,
  type IBaseComponent,
  type IImage,
  Icon,
  IconType,
  Image,
  StyleGrid,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { CommercialTemplate, mediumTextPrefab } from '../../Prefabs'
import { MSRichText } from '../MSRichText/MSRichText'
import './CommercialBanner.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useDefaultPageContext } from '../../contexts'
import { isExternalUrl } from '../../services'

export interface ICommercialBanner extends IBaseComponent {
  action?: IAction
  title?: string
  description?: string
  image?: IImage
  priority: number
  desktopView: boolean
  link?: string
}

export const CommercialBanner = (props: ICommercialBanner) => {
  // ************************************
  // Properties
  // ************************************

  const { className, link } = props

  const classPrefix = 'commercial-banner'

  const { setContentLoading } = useDefaultPageContext()
  const navigate = useNavigate()

  // ************************************
  // Helpers
  // ************************************

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {isExternalUrl(link) ? (
        <a
          className={classNames(`${classPrefix}`, {
            [`${className}`]: className
          })}
          target={'_blank'}
          rel='noreferrer'
          href={link}
        >
          <Banner {...props} className={classPrefix} />
        </a>
      ) : (
        <Link
          className={classNames(`${classPrefix}`, {
            [`${className}`]: className
          })}
          to={'..'}
          onClick={e => {
            e.preventDefault()
            setContentLoading(true)
            navigate(link ?? '/')
          }}
        >
          <Banner {...props} className={classPrefix} />
        </Link>
      )}
    </>
  )
}

const Banner = (props: ICommercialBanner) => {
  const {
    id,
    brand,
    theme = 'Light',
    className,
    title,
    description,
    image,
    template = CommercialTemplate(theme)
  } = props

  const classPrefix = className
  return (
    <BaseComponent
      id={id}
      brand={brand}
      theme={theme}
      template={template}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
    >
      <StyleGrid className={`${classPrefix}__top`} direction={'row'} alignment='top-left' wrap={false}>
        <ContentGrid
          className={classNames(`${classPrefix}__top__header`, {
            [`${classPrefix}__top__header--clamp`]: image
          })}
          direction='column'
          alignment='top-left'
          tagType={'section'}
          boxSizing={'border-box'}
        >
          {title && (
            <ContentGrid
              className={`${classPrefix}__top__header__nav`}
              direction='row'
              alignment='top-left'
              tagType={'nav'}
              gap={1}
            >
              <Text
                className={`${classPrefix}__top__header__nav__title`}
                palette={template.palette}
                {...mediumTextPrefab({ brand: brand, theme: theme })}
              >
                {title}
                <Icon
                  className={`${classPrefix}__top__header__nav__arrow`}
                  palette={template.palette}
                  theme={theme}
                  brand={brand}
                  type={IconType.ArrowRight}
                />
              </Text>
            </ContentGrid>
          )}
          {description && (
            <MSRichText
              className={`${classPrefix}__top__header__desc`}
              palette={template.palette}
              customization={{ type: 'p', color: template.palette.text?.color }}
              theme={theme}
              text={description}
              alignment='lawful'
            />
          )}
        </ContentGrid>
        {image && <Image {...image} className={`${classPrefix}__top__image`} fit={'cover'} scalingBy={'height'} />}
      </StyleGrid>
    </BaseComponent>
  )
}
