import {
  BrandColors,
  type CallState,
  ContentGrid,
  Icon,
  IconType,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { useMemo, useState } from 'react'
import {
  MS_ButtonTemplate,
  getPlankPrefab,
  h2TextPrefab,
  h3TextPrefab,
  paragraphTextPrefab,
  smallParagraphTextPrefab
} from '../../../../Prefabs'
import ConfirmedSvg from '../../../../assets/art/benefits/usePoints-invoice-confirmed.svg?react'
import InvoiceSvg from '../../../../assets/art/benefits/usePoints-invoice.svg?react'
import { Card, MSRichText, MsButton } from '../../../../components'
import {
  useApplicationServicehandlerContext,
  useDefaultPageContext,
  useSubPageLayoutContext
} from '../../../../contexts'
import { Constants } from '../../../../data'
import { createString, getBrand, getText, tNumber } from '../../../../services'
import { postKickbackDeduction } from '../../../../services/collection/BenefitService'
import { type IDefaultViewProps, PageV2 } from '../../../PageV2'
import { getPageContent } from './UsePointsInvoicePageData'
import './UsePointsInvoicePage.scss'
import type { ICheckInputPlankPrefab } from '../../../../Prefabs/Planks/CheckInputPlankPrefab/CheckInputPlankPrefab'
import { MS_MasterPlankTemplate } from '../../../../Prefabs/Templates/planks/MS_MasterPlankTemplate'
import { RadioPlankWall } from '../../../../blocks'
import type { ICustomerAccountInformation } from '../../../../models'

export interface IUsePointsInvoicePage extends IDefaultViewProps {
  availablePoints: number
}

export const UsePointsInvoicePage = () => {
  // ************************************
  // Properties
  // ************************************
  const { setBack } = useSubPageLayoutContext()
  const { setContentLoading } = useDefaultPageContext()
  const { POST } = useApplicationServicehandlerContext()
  const classPrefix = 'use-points-invoice-page'
  const MAX_POINTS = 3000

  // ************************************
  // Lifecycle
  // ************************************

  const [pointsToDeduct, setPointsToDeduct] = useState<number>()
  const [step, setStep] = useState<number>(0)
  const [deductionResult, setDeductionResult] = useState<CallState>()
  const [validInput, setValidInput] = useState<boolean>(false)
  const [maxPointsError, setMaxPointsError] = useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] = useState<string>()
  const [selectForm, setSelectForm] = useState<ICheckInputPlankPrefab[]>([])

  const _radioPlankWall = useMemo(() => {
    if (!selectForm || selectForm.length === 0) return <></>

    return (
      <RadioPlankWall
        theme={'Light'}
        brand={getBrand()}
        planks={selectForm}
        disabled={false}
        onSelectItem={item => {
          setSelectedInvoice(item.value)
        }}
      />
    )
  }, [selectForm])

  // ************************************
  // Helper Functions
  // ************************************

  const _deductPoints = async (availablePoints: number, points: number | undefined) => {
    if (points && availablePoints) {
      if (points <= availablePoints && points > 0) {
        setContentLoading(true)
        setBack(undefined)
        const resp = await postKickbackDeduction(POST, points, selectedInvoice)
        setStep(2)
        setContentLoading(false)
        setDeductionResult(resp.callState)
      }
    }
  }

  const _getInitialPoints = (initialPoints: number) => {
    const availablePoints = Math.max(0, initialPoints)
    const points = Math.min(availablePoints, MAX_POINTS)

    _validateAndSetPoints(availablePoints, points)
    setPointsToDeduct(points)
  }

  const _validateAndSetPoints = (availablePoints: number, points: number | undefined) => {
    if (points && availablePoints) {
      if (points > 0 && points <= availablePoints) {
        if (points > MAX_POINTS) {
          setMaxPointsError(true)
          setValidInput(false)
        } else {
          setMaxPointsError(false)
          setValidInput(true)
          setPointsToDeduct(points)
        }
      } else {
        setValidInput(false)
      }
    } else {
      setValidInput(false)
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _arrowIcon = (direction: 'left' | 'right', config: IUsePointsInvoicePage) => {
    const { activeBrand, activeTheme } = config

    return (
      <Icon
        className={`${classPrefix}__card__content__button__icon--${direction}`}
        brand={activeBrand}
        theme={activeTheme}
        type={direction === 'right' ? IconType.ArrowRight : IconType.ArrowLeft}
        width={0.8}
        height={0.8}
        color={BrandColors['text-shade-light-1']}
      />
    )
  }

  const _renderStepIcon = (config: IUsePointsInvoicePage) => {
    const stepTwo = deductionResult === 'success' ? _renderStepIconConfirmed() : _renderStepIconError(config)

    return <>{step !== 2 ? <InvoiceSvg width={100} height={100} /> : stepTwo}</>
  }

  const _renderStepIconConfirmed = () => <ConfirmedSvg width={100} height={100} />

  const _renderStepIconError = (config: IUsePointsInvoicePage) => (
    <Icon
      brand={config.activeBrand}
      type={IconType.ConfusedFace}
      color={BrandColors['primary-shade-light-2']}
      width={7}
      height={7}
    />
  )

  const _renderStep0Header = (config: IUsePointsInvoicePage) => {
    const { translations, activeBrand } = config
    return (
      <>
        {_renderStepIcon(config)}
        <ContentGrid
          className={`${classPrefix}__card__content__fullwidth`}
          alignment={'bottom-left'}
          direction={'column'}
          gap={4}
          tagType={'nav'}
        >
          <Text {...h3TextPrefab()} brand={activeBrand} size={'medium'} weight={700}>
            {getText('onInvoiceHeading', translations)}
          </Text>
        </ContentGrid>
      </>
    )
  }

  const _mapAccounts = (config: IUsePointsInvoicePage) => {
    const { user } = config
    const template = MS_MasterPlankTemplate('Light', 'middle')
    const { userData } = user

    return userData.accounts.map((account: ICustomerAccountInformation): ICheckInputPlankPrefab => {
      const installation = userData.installations.filter(i => i.accountId === account.accountId)
      const title = installation
        .map(i => i.residenceInfo?.residenceName ?? i.address.streetAddress)
        .reduce((acc, curr) => `${acc + curr}, `, '')
        .trimEnd()
        .slice(0, -1)

      return getPlankPrefab('Radio', {
        value: account.accountId,
        active: selectedInvoice === account.accountId,
        id: account.accountId.toString(),
        template: template,
        left: {
          title: title,
          description: account.accountId.toString()
        },
        right: {
          active: true,
          value: account.accountId
        }
      } as ICheckInputPlankPrefab) as ICheckInputPlankPrefab
    })
  }

  const _renderEnergyAccountSelection = (config: IUsePointsInvoicePage) => {
    const { translations, activeBrand } = config

    if (selectForm.length <= 1) return <></>

    return (
      <>
        <Text {...h2TextPrefab()} brand={activeBrand} size={'medium'} weight={700}>
          {getText('onInvoicePointsWithdrawalQuestion', translations)}
        </Text>
        <div style={{ width: '100%' }}>{_radioPlankWall}</div>
      </>
    )
  }

  const _renderStep0 = (config: IUsePointsInvoicePage) => {
    const { activeBrand, activeTheme, translations, availablePoints } = config

    if (!pointsToDeduct) _getInitialPoints(availablePoints)

    if (step === 0) {
      return (
        <>
          <MSRichText
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            text={createString(getText('onInvoicePointsAvailable', translations), {
              amount: tNumber(availablePoints ?? 0, 'no-NO', 0, 0)
            })}
          />
          <ContentGrid
            className={`${classPrefix}__card__content__fullwidth`}
            alignment={'bottom-left'}
            direction={'column'}
            gap={2}
            tagType={'section'}
          >
            {_renderEnergyAccountSelection(config)}
            <Text {...h2TextPrefab()} brand={activeBrand} size={'medium'} weight={700}>
              {getText('onInvoiceHowManyPoints', translations)}
            </Text>
            {maxPointsError && (
              <Text
                {...smallParagraphTextPrefab()}
                className={`${classPrefix}__card__content__error`}
                color={BrandColors['status-shade-light-3']}
                align={'align-center'}
              >
                {getText('onInvoiceMaxPointsError', translations)}
              </Text>
            )}
            <ContentGrid
              className={`${classPrefix}__card__content__input`}
              alignment={'center'}
              direction={'row'}
              tagType={'nav'}
              style={{
                borderColor: validInput ? 'green' : 'red'
              }}
            >
              <input
                type={'number'}
                step={1}
                name={'pointsToDeduct'}
                defaultValue={pointsToDeduct}
                onChange={e => {
                  // Forces input to be an integer:
                  const input = Math.floor(e.target.valueAsNumber)
                  e.target.valueAsNumber = input
                  _validateAndSetPoints(availablePoints, input)
                }}
              />
              <Text {...paragraphTextPrefab()} brand={activeBrand} theme={getCounterTheme(activeTheme)} faded>
                {getText('onInvoicePoengInput', translations)}
              </Text>
            </ContentGrid>
          </ContentGrid>
        </>
      )
    }
  }

  const _renderStep1 = (config: IUsePointsInvoicePage) => {
    const { activeBrand, activeTheme, translations, availablePoints } = config

    if (step === 1 && availablePoints && pointsToDeduct) {
      return (
        <ContentGrid
          className={`${classPrefix}__card__content__fullwidth`}
          alignment={'bottom-left'}
          direction={'column'}
          gap={2}
          tagType={'section'}
        >
          <MSRichText
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            text={createString(getText('onInvoicePointsChosenToUse', translations), {
              amount: tNumber(pointsToDeduct, 'no-NO', 0, 0)
            })}
          />
          <Text type={'p'} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
            {getText('onInvoiceAmountAfterUse', translations)}{' '}
            <span id='bold-green-text'>
              {tNumber(availablePoints - pointsToDeduct, 'no-NO', 0, 2)} {getText('onInvoicePoengInput', translations)}
            </span>
          </Text>
        </ContentGrid>
      )
    }
  }

  const _renderStep2 = (config: IUsePointsInvoicePage) => {
    const { activeBrand, activeTheme, translations } = config

    if (step === 2) {
      return (
        <>
          {deductionResult === 'success' ? (
            <>
              <Text type={'p'} align={'align-left'} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
                {getText('onInvoiceSuccessText1', translations)}
              </Text>
              <Text type={'p'} align={'align-left'} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
                {getText('onInvoiceSuccessText2', translations)}
              </Text>
            </>
          ) : (
            <>
              <Text type={'p'} align={'align-left'} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
                {getText('onInvoiceErrorText1', translations)}
              </Text>
              <Text type={'p'} align={'align-left'} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
                {getText('onInvoiceErrorText2', translations)}
              </Text>
            </>
          )}
        </>
      )
    }
  }

  const _getOnInvoiceHeadingText = (translations: any) => {
    return deductionResult !== 'success'
      ? getText('onInvoiceHeadingError', translations)
      : getText('onInvoiceHeadingSuccess', translations)
  }
  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.benefits.type,
      usesSubPage: true
    },
    dependencies: [{ step }, { validInput }, { _radioPlankWall }],
    handleData: getPageContent,
    render: (config: IUsePointsInvoicePage) => {
      const { activeBrand, activeTheme, translations, availablePoints, user } = config

      if (!selectedInvoice) setSelectedInvoice(user.userData.accounts[0].accountId.toString())

      if (!selectForm || selectForm.length === 0) setSelectForm(_mapAccounts(config))

      return (
        <StyleGrid
          className={`${classPrefix}`}
          alignment={'center-left'}
          direction={'row'}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          <Card className={`${classPrefix}__card`} brand={activeBrand} theme={activeTheme} alignment={'center'}>
            <ContentGrid
              className={`${classPrefix}__card__content__fullwidth`}
              alignment={'top-center'}
              direction={'column'}
              gap={4}
              tagType={'nav'}
            >
              {step !== 0 ? (
                <>
                  {_renderStepIcon(config)}
                  <Text
                    {...h2TextPrefab()}
                    weight={700}
                    size={'medium'}
                    brand={activeBrand}
                    theme={getCounterTheme(activeTheme)}
                    align={'align-center'}
                  >
                    {step !== 2 ? getText('onInvoiceHeading', translations) : _getOnInvoiceHeadingText(translations)}
                  </Text>
                </>
              ) : (
                _renderStep0Header(config)
              )}
              {_renderStep0(config)}
              {_renderStep1(config)}
              {_renderStep2(config)}
              <div className={`${classPrefix}__card__content__button-container`}>
                <MsButton
                  className={`${classPrefix}__card__content__button`}
                  template={MS_ButtonTemplate(activeTheme, 'primary')}
                  brand={activeBrand}
                  theme={activeTheme}
                  disabled={!validInput}
                  forceExternalLink={step === 2}
                  action={
                    step < 2
                      ? {
                          onClick: () => {
                            if (step === 0) setStep(1)
                            if (step === 1) _deductPoints(availablePoints, pointsToDeduct)
                          }
                        }
                      : {
                          link: Constants.paths.benefitsPage
                        }
                  }
                >
                  {step === 2 && _arrowIcon('left', config)}
                  <Text {...paragraphTextPrefab()} brand={activeBrand} theme={activeTheme}>
                    {step === 0 && getText('onInvoiceNextButton', translations)}
                    {step === 1 && getText('onInvoiceConfirmButton', translations)}
                    {step === 2 && getText('onInvoiceBackButton', translations)}
                  </Text>
                  {step < 2 && _arrowIcon('right', config)}
                </MsButton>
              </div>
            </ContentGrid>
          </Card>
        </StyleGrid>
      )
    }
  })
}
