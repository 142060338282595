import { format, getDaysInMonth } from 'date-fns'
import nb from 'date-fns/esm/locale/nb/index.js'
import _ from 'lodash'
import type { DateResolution, ICustomerConsumptionPoint } from '../../models'

// ************************************
// CONSUMPTION DATA
// ************************************

interface IConsumptionMockData {
  resolution?: DateResolution
  fromDate?: Date
  toDate?: Date
  returnData?: boolean
}

export const getMockData = (config: IConsumptionMockData) => {
  const { resolution = 'day', returnData = true } = config

  const consumption: ICustomerConsumptionPoint[] = []
  const formattedData = formatChartMockData(config)
  const allowEstimated = _.random(0, 1) === 0

  for (let i = formattedData.loopStart; i <= formattedData.loopTotal; i++) {
    const isEst = i > formattedData.loopTotal / 2 && allowEstimated
    let a = 0
    if (returnData) {
      a = isEst ? 50 : _.random(-2, 200, true)
    }
    let aSupport = 0
    if (returnData) {
      aSupport = isEst ? 0 : a - 10
    }

    const date = new Date(
      resolution === 'year' ? i : new Date().getFullYear(),
      resolution === 'month' ? i : new Date().getMonth(),
      resolution === 'day' ? i : new Date().getDate(),
      resolution === 'hour' ? i : new Date().getHours()
    )

    consumption.push({
      startTime: date,
      endTime: new Date(),
      endTimeCost: '',
      endTimeEnergy: '',
      meterType: 'Consumption',
      energy: {
        value: 0,
        amount: a,
        isEstimated: isEst
      },
      cost: {
        value: 0,
        amount: a,
        isEstimated: isEst
      },
      costIncludingSupport: {
        amount: aSupport,
        isEstimated: isEst,
        value: aSupport
      },
      estimatedEnergySectionGridTariffCost: null
    })
  }

  return consumption
}

const formatChartMockData = (config: IConsumptionMockData) => {
  const { resolution = 'day', fromDate, toDate } = config

  let from = fromDate
  let to = toDate
  let loopTotal = 0
  let loopStart = 0

  switch (resolution) {
    case 'year':
      loopStart = 2017
      loopTotal = new Date().getFullYear()

      if (!from) {
        from = new Date(loopStart)
        to = new Date(loopTotal)
      }
      break
    case 'month':
      loopStart = 0
      loopTotal = 11
      from = fromDate ?? new Date()
      to = toDate ?? new Date()

      if (!from) {
        from = new Date(to.getFullYear(), 0)
        to = new Date(to.getFullYear(), 11)
      }
      break
    case 'day':
      from = fromDate ?? new Date()
      to = toDate ?? new Date()

      loopStart = 1
      loopTotal = getDaysInMonth(new Date(to.getFullYear(), to.getMonth()))

      from.setDate(0)
      to.setDate(loopTotal)
      break
    case 'hour':
      loopStart = 0
      loopTotal = 23

      if (!from) {
        from = new Date()
        to = new Date()
        from.setHours(0)
        to.setHours(23)
      }
      break
  }

  return { loopTotal, loopStart }
}

export const getConsumptionSeriesData = (resolution: DateResolution, date: Date, reversed?: boolean) => {
  const data = []
  let cat = ''
  let loopTotal = 0
  let loopStart = 0

  switch (resolution) {
    case 'year':
      loopStart = 2017
      loopTotal = new Date().getFullYear()
      break
    case 'month':
      loopStart = 0
      loopTotal = 11
      break
    case 'day':
      loopStart = 1
      loopTotal = getDaysInMonth(new Date(date.getFullYear(), date.getMonth()))
      break
    case 'hour':
      loopStart = 0
      loopTotal = 23
      break
  }

  for (let i = loopStart; i <= loopTotal; i++) {
    const itemDate: Date = new Date(date)

    if (resolution === 'hour') {
      itemDate.setHours(i)
      cat = `${format(itemDate, 'HH:mm')}`
    } else if (resolution === 'day') {
      itemDate.setDate(i)
      cat = `${format(itemDate, 'd.', { locale: nb })}`
    } else if (resolution === 'month') {
      itemDate.setDate(1)
      itemDate.setMonth(i)
      cat = `${format(itemDate, 'MMMM', { locale: nb })}`
    }

    data.push({
      x: reversed ? 0 : cat,
      y: reversed ? cat : 0
    })
  }

  return data
}
