import { StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { ArticlesBlock, type IPlankHouse, PlankHouseBlock } from '../../../blocks'
import type { IArticleItem } from '../../../components'
import {
  useAddonServicesContext,
  useApplicationServicehandlerContext,
  useSubPageLayoutContext
} from '../../../contexts'
import { Constants } from '../../../data'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './ServicesOverviewPageData'
import './ServicesOverviewPage.scss'

interface IServiceOverviewPage extends IDefaultViewProps {
  house: IPlankHouse
  availableServices: {
    title: string
    items: IArticleItem[]
  }
}

export const ServicesOverviewPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'services-overview-page'
  const { setShowInstallationSelector } = useSubPageLayoutContext()
  const { addonStates } = useAddonServicesContext()
  const { GETTYPED } = useApplicationServicehandlerContext()

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.services.type,
      usesSubPage: true,
      pageClamp: 'clamp'
    },
    dependencies: [{ addonStates }, { setShowInstallationSelector }, { GETTYPED }],
    onDataChange: (config: IServiceOverviewPage) => {
      const { userData } = config.user

      setShowInstallationSelector((userData?.installations && userData.installations.length > 1) ?? false)
    },
    handleData: getPageContent,
    render: (config: IServiceOverviewPage) => {
      const { availableServices, activeBrand, activeTheme, house } = config

      return (
        <StyleGrid className={classPrefix} direction='column' alignment='top-center' gap={3}>
          {house && (
            <PlankHouseBlock {...house} brand={activeBrand} theme={activeTheme} className={`${classPrefix}__house`} />
          )}
          {availableServices && <ArticlesBlock {...availableServices} brand={activeBrand} theme={activeTheme} />}
        </StyleGrid>
      )
    }
  })
}
