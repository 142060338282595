import {
  type IAccordion,
  type IComponent,
  StyleGrid,
  useQueryParamPageRouter
} from '@fjordkraft/fjordkraft.component.library'
import { useEffect, useMemo, useState } from 'react'
import { useApplicationContext } from '../../contexts'
import './MoveFormBlock.scss'
import { type IMoveAddress, type IMoveInformation, MoveContext } from '../../contexts/variations/MoveContext'
import type { CallState } from '../../models'
import { MoveFormPageAddress } from './MoveFormBlockPages/MoveFormPageAddress'
import { MoveFormPageDate } from './MoveFormBlockPages/MoveFormPageDate'
import { MoveFormPageInformation } from './MoveFormBlockPages/MoveFormPageInformation'
import { MoveFormPageResult } from './MoveFormBlockPages/MoveFormPageResult'

export interface IMoveFormBlock extends IComponent {
  addressesData: IMoveAddress[]
  accordionData: IAccordion
}

export const MoveFormBlock = (props: IMoveFormBlock) => {
  // ************************************
  // Properties
  // ************************************
  const { id, addressesData, accordionData } = props
  const { activeBrand, activeTheme } = useApplicationContext()
  const { getPage, next, prev, setPage } = useQueryParamPageRouter(1, 4)
  const classPrefix = 'move-form-block'
  const page = getPage()

  // ************************************
  // Lifecycle
  // ************************************
  const [address, setAddress] = useState<IMoveAddress>({} as IMoveAddress)
  const [terminationDate, setTerminationDate] = useState<Date | null>(null)
  const [information, setInformation] = useState<IMoveInformation>({} as IMoveInformation)
  const [requestResult, setRequestResult] = useState<CallState>('idle')
  const [responseContent, setResponseContent] = useState<any>(null)

  useEffect(() => {
    if (page && page > 4) setPage(1)
  }, [page])

  const moveFormContext = useMemo(() => {
    return {
      address,
      setAddress,
      terminationDate,
      setTerminationDate,
      information,
      setInformation,
      requestResult,
      setRequestResult,
      responseContent,
      setResponseContent
    }
  }, [address, terminationDate, information, requestResult, responseContent])

  // ************************************
  // Render
  // ************************************
  return (
    <MoveContext.Provider value={moveFormContext}>
      <StyleGrid
        id={id}
        className={`${classPrefix}`}
        brand={activeBrand}
        theme={activeTheme}
        alignment={'center'}
        direction={'row'}
        boxSizing='border-box'
      >
        {page && (
          <>
            {page === 1 && (
              <MoveFormPageAddress addressesData={addressesData} accordionData={accordionData} nextPage={next} />
            )}
            {page === 2 && <MoveFormPageDate nextPage={next} prevPage={prev} setPage={setPage} />}
            {page === 3 && <MoveFormPageInformation nextPage={next} prevPage={prev} setPage={setPage} />}
            {page === 4 && <MoveFormPageResult setPage={setPage} />}
          </>
        )}
      </StyleGrid>
    </MoveContext.Provider>
  )
}
