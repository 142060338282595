import {
  BrandColors,
  type IComponentTemplate,
  type Theme,
  ToggleSwitchTemplate
} from '@fjordkraft/fjordkraft.component.library'

export const MS_ToggleSwitchTemplate = (theme: Theme) => {
  const base: IComponentTemplate = ToggleSwitchTemplate(theme)

  if (base.palette) {
    base.palette.background = { color: BrandColors['background-shade-light-3'] }
    base.palette.icon = { color: BrandColors['background-shade-light-1'] }
  }

  return base
}
