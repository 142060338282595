import { createContext, useContext } from 'react'
import type { IWebToast } from '../../blocks'

interface IApplicationOverlayWrapperContext {
  globalLoading: boolean
  setGlobalLoading: (boolean: boolean) => void
  toasts?: IWebToast[]
  setToasts: (toasts: IWebToast[] | undefined) => void
}

export const ApplicationOverlayWrapperContext = createContext<IApplicationOverlayWrapperContext>({
  globalLoading: true,
  setGlobalLoading: () => {},
  toasts: undefined,
  setToasts: () => {}
})

export const useApplicationOverlayWrapperContext = () => useContext(ApplicationOverlayWrapperContext)
