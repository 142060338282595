import type {
  IComponentColors,
  IComponentTemplate,
  IComponentTransform,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../../data'
import { logger } from '../../../services'
import { BUTTON_TEMPLATE_ARROW } from './ArrowButtonTemplate'
import { BUTTON_TEMPLATE_CTA_EDIT } from './Edit'
import { BUTTON_TEMPLATE_CTA_END } from './EndButtonTemplate'
import { BUTTON_TEMPLATE_GUEST_LINK } from './GuestLink'
import { BUTTON_TEMPLATE_MENU } from './HeaderMenuButtonTemplate'
import { BUTTON_TEMPLATE_CTA_HIGHLIGHTED } from './Highlighted'
import { BUTTON_TEMPLATE_ICON } from './IconButtonTemplate'
import { BUTTON_TEMPLATE_CTA_INFO } from './Info'
import { BUTTON_TEMPLATE_LINK } from './Link'
import { BUTTON_TEMPLATE_MENU_ICON } from './MenuIconButtonTemplate'
import { BUTTON_TEMPLATE_CTA_PRIMARY } from './Primary'
import { BUTTON_TEMPLATE_RATE } from './RateButtonTemplate'
import { BUTTON_TEMPLATE_CTA_SECONDARY } from './Secondary'
import { BUTTON_TEMPLATE_TOGGLE } from './ToggleButtonTemplate'
import { BUTTON_TEMPLATE_CTA_URGENT } from './Urgent'

// ************************************
// Properties
// ************************************

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'urgent'
  | 'info'
  | 'highlighted'
  | 'edit'
  | 'menu'
  | 'icon'
  | 'arrow'
  | 'end'
  | 'link'
  | 'rate'
  | 'menu-icon'
  | 'guestLink'
  | 'toggle'
const _ButtonLibrary: Map<ButtonTypes, any> = new Map<ButtonTypes, any>()

// ************************************
// Transform
// ************************************

const _transform = {
  width: 'auto',
  height: 'auto',
  minHeight: '2.563rem',
  border: {
    radiusType: 'roundedOff',
    type: 'solid',
    thickness: 2
  },
  grid: {
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center',
    gap: 1,
    inline: true,
    wrap: false
  }
} as IComponentTransform

// ************************************
// Palette Libraries
// ************************************

_ButtonLibrary.set('primary', {
  themedPalette: BUTTON_TEMPLATE_CTA_PRIMARY,
  transform: _transform
})
_ButtonLibrary.set('secondary', {
  themedPalette: BUTTON_TEMPLATE_CTA_SECONDARY,
  transform: _transform
})
_ButtonLibrary.set('end', {
  themedPalette: BUTTON_TEMPLATE_CTA_END,
  transform: _transform
})
_ButtonLibrary.set('urgent', {
  themedPalette: BUTTON_TEMPLATE_CTA_URGENT,
  transform: _transform
})
_ButtonLibrary.set('info', {
  themedPalette: BUTTON_TEMPLATE_CTA_INFO,
  transform: _transform
})
_ButtonLibrary.set('highlighted', {
  themedPalette: BUTTON_TEMPLATE_CTA_HIGHLIGHTED,
  transform: _transform
})
_ButtonLibrary.set('edit', {
  themedPalette: BUTTON_TEMPLATE_CTA_EDIT,
  transform: _transform
})
_ButtonLibrary.set('arrow', BUTTON_TEMPLATE_ARROW)
_ButtonLibrary.set('menu', BUTTON_TEMPLATE_MENU)
_ButtonLibrary.set('icon', BUTTON_TEMPLATE_ICON)
_ButtonLibrary.set('link', BUTTON_TEMPLATE_LINK)
_ButtonLibrary.set('rate', BUTTON_TEMPLATE_RATE)
_ButtonLibrary.set('menu-icon', BUTTON_TEMPLATE_MENU_ICON)
_ButtonLibrary.set('guestLink', BUTTON_TEMPLATE_GUEST_LINK)
_ButtonLibrary.set('toggle', BUTTON_TEMPLATE_TOGGLE)

// ************************************
// Export
// ************************************

export const MS_ButtonTemplate = (
  theme: Theme = 'Light',
  type: ButtonTypes = 'primary',
  brand: string = Constants.uiBrand
) => {
  const template = _ButtonLibrary.get(type)
  const foundTransform: IComponentTransform = _ButtonLibrary.get(type).transform
  let foundPalette: IComponentColors = {}

  try {
    foundPalette = template.themedPalette[brand][theme]
  } catch {
    try {
      foundPalette = template.themedPalette['brand-fjordkraft']['Light']
    } catch {
      logger(`MS_ButtonTemplate: Brand fallback failed, ${type} missing default brand.`, 'warn')
    }

    logger(`MS_ButtonTemplate: Brand, "${brand}" missing palette.`, 'warn')
  }

  return {
    transform: foundTransform,
    palette: foundPalette
  } as IComponentTemplate
}
