import {
  type Alignment,
  type Direction,
  FlexGridAligner,
  type IComponent
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { type ILegend, Legend } from '../../components/Legend/Legend'

export interface ILegendBlock extends IComponent {
  direction: Direction
  alignment: Alignment
  legends: ILegend[]
}

export const LegendBlock = (props: ILegendBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme, brand, direction, alignment, legends } = props
  const classPrefix = 'ms-legend-block'

  // ************************************
  // Render Functionality
  // ************************************

  const renderLegends = () => {
    return legends.map((legend: ILegend) => {
      return <Legend key={uuid4()} {...legend} legendSpotPlacement={alignment === 'center-right' ? 'right' : 'left'} />
    })
  }

  // ************************************
  // Render
  // ************************************

  return (
    <FlexGridAligner
      id={id}
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      theme={theme}
      brand={brand}
      direction={direction}
      alignment={alignment}
      gap={2}
    >
      {renderLegends()}
    </FlexGridAligner>
  )
}
