import { ContentGrid, Text, type Theme, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { h3TextPrefab } from '../../Prefabs'
import { type IPointCard, MSRichText, PointCard } from '../../components'
import { useApplicationContext } from '../../contexts'
import { Constants } from '../../data'
import type { IBlock } from '../../models'
import './DetailsDescriptionBlock.scss'

export interface IDetailsDescriptionBlock extends IBlock {
  title?: string
  description?: string
  points: IPointCard
  cardTheme?: Theme
}

export const DetailsDescriptionBlock = (props: IDetailsDescriptionBlock) => {
  // ************************************
  // Lifecycle
  // ************************************

  const {
    id,
    className,
    theme = 'Light',
    brand = Constants.uiBrand,
    title,
    description,
    points,
    cardTheme = theme
  } = props
  const classPrefix = 'details-description-block'
  const { desktopView } = useApplicationContext()

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      direction='row'
      alignment='top-left'
      boxSizing='border-box'
      gap={4}
      tagType='article'
      spaceBetween
    >
      <ContentGrid
        className={classNames(`${classPrefix}__content`, {
          [`${className}__content`]: className
        })}
        direction='column'
        alignment='top-left'
        gap={2}
        tagType='section'
        boxSizing='border-box'
        scaling={desktopView ? 3 : undefined}
      >
        {title && (
          <Text {...h3TextPrefab()} theme={getCounterTheme(theme)} brand={brand} align='align-left'>
            {title}
          </Text>
        )}
        {description && (
          <MSRichText text={description} theme={getCounterTheme(theme)} brand={brand} alignment='lawful' />
        )}
      </ContentGrid>
      {points.points.length > 0 ? (
        <PointCard
          {...points}
          className={classNames(`${classPrefix}__points`, {
            [`${className}__points`]: className
          })}
          theme={cardTheme}
          brand={brand}
          scaling={desktopView ? 2 : undefined}
        />
      ) : null}
    </ContentGrid>
  )
}
