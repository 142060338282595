import {
  type Alignment,
  BrandColors,
  ContentGrid,
  type IAction,
  type IComponent,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { isWeekend } from 'date-fns'
import Holidays from 'date-holidays'
import { v4 as uuid4 } from 'uuid'
import { MS_ButtonTemplate, h1TextPrefab } from '../../Prefabs'
import FadeLine from '../../assets/art/fade-line.svg'
import { ActionButton, MSRichText } from '../../components'
import { useApplicationContext, useApplicationCoreDataContext } from '../../contexts'
import { Constants } from '../../data'
import type { ICustomer } from '../../models'
import { isBetweenTwoTimes } from '../../services'
import './FooterLinkItemGridBlock.scss'

export interface IFooterLinkItemGrid extends IComponent {
  title?: string
  items: IFooterLinkItem[]
  alignment?: Alignment
}

export interface IFooterLinkItem {
  blockId?: string
  title: string
  content: string
  fromDate?: string
  toDate?: string
  showBasedOnTime?: boolean
  showDuringHolidays?: boolean
  showDuringWeekend?: boolean
  actionType: 'default' | 'copy' | 'call'
  actionButton: IAction
}

export const FooterLinkItemGridBlock = (props: IFooterLinkItemGrid) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, items } = props
  const { desktopView, activeBrand, activeTheme } = useApplicationContext()
  const { userData } = useApplicationCoreDataContext()
  const classPrefix = 'footer-link-item-grid-block'

  // ************************************
  // Helper Functionality
  // ************************************

  const _showFooterItem = (item: IFooterLinkItem) => {
    const currentDate: Date = new Date()
    const from: Date | null = item.fromDate ? new Date(item.fromDate) : null
    const to: Date | null = item.toDate ? new Date(item.toDate) : null
    const hd = new Holidays()
    hd.init('NO')

    const weekend: boolean = isWeekend(currentDate)
    const notHoliday: boolean = !hd.isHoliday(currentDate)

    if (item.showBasedOnTime && from && to && item.actionButton.active) {
      return _handleTimeDisplay(from, to, item, notHoliday, weekend)
    }
    return item.actionButton.active ?? false
  }

  const _handleTimeDisplay = (
    from: Date,
    to: Date,
    item: IFooterLinkItem,
    notHoliday: boolean,
    weekend: boolean
  ): boolean => {
    if (isBetweenTwoTimes(from, to)) {
      if (!item.showDuringHolidays && !notHoliday) {
        return false
      }
      if (!item.showDuringWeekend && weekend) {
        return false
      }
      return true
    }
    return false
  }

  const _handleCustomerChatWindow = (action: IAction, userData: ICustomer) => {
    let leftPosition = window.screen.width / 2
    let topPosition = window.screen.height / 2
    const width: number = desktopView ? window.screen.width / 4 : window.screen.width
    const height: number = desktopView ? Math.max(window.screen.height / 2, 650) : window.screen.height

    leftPosition = leftPosition - width / 2
    topPosition = topPosition - height / 2

    window.open(
      _handleCustomerChatWindowUrl(userData, action.link ?? ''),
      'Chat',
      `width=${width},height=${height}, top=${topPosition}, left=${leftPosition}`
    )
  }

  const _handleCustomerChatWindowUrl = (userData: ICustomer, baseUrl: string) => {
    let url = `${baseUrl}?show_chat=1`
    const { brand } = Constants.api

    url += `&brand=${brand}`
    url += `&enteredFormName=${userData.firstName} ${userData.lastName}`

    if (userData.contactInformation?.email) {
      url += `&enteredChatId=${userData.contactInformation.email}`
    }

    if (userData.contactInformation?.phone) {
      url += `&phoneNumber=${userData.contactInformation.phone}`
    }

    if (userData.customerId) {
      url += `&customerId=${userData.customerId}`
    }

    return url
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderText = (item: IFooterLinkItem) => {
    return (
      <MSRichText brand={activeBrand} theme={getCounterTheme(activeTheme)} text={item.content} alignment='lawful' />
    )
  }

  const _renderAction = (item: IFooterLinkItem, userData: ICustomer) => {
    if (item.actionButton) {
      if (desktopView) {
        if (item.actionType !== 'call') {
          return _renderButton(item.actionButton, item, userData)
        }
      } else {
        return _renderButton(item.actionButton, item, userData)
      }
    }
  }

  const _renderButton = (action: IAction, item: IFooterLinkItem, userData: ICustomer) => {
    let newAction = action

    if (item?.blockId === 'chatWindow') {
      newAction = {
        text: action.text,
        icon: action.icon,
        onClick: () => {
          _handleCustomerChatWindow(action, userData)
        }
      } as IAction
    }

    return (
      <ActionButton
        template={MS_ButtonTemplate(activeTheme, 'secondary')}
        brand={activeBrand}
        action={{
          ...newAction,
          ...{
            active: false
          }
        }}
        padding={'default'}
      />
    )
  }

  const _renderGridItems = (userData: ICustomer) => {
    const gridItems: any[] = []

    if (items.length > 0) {
      items.forEach((item: IFooterLinkItem, i: number) => {
        const foundItem: IFooterLinkItem | null = i + 1 < items.length && items[i + 1] !== null ? items[i + 1] : null
        const useLine: boolean = foundItem ? _showFooterItem(foundItem) : false

        if (_showFooterItem(item)) {
          gridItems.push(_renderGridItem(item, userData))

          if (useLine && desktopView) {
            gridItems.push(_renderLineSeperator())
          }
        }
      })
    }

    return gridItems
  }

  const _renderLineSeperator = () => {
    return <img key={uuid4()} className={`${classPrefix}__footer-link-divider`} src={FadeLine} alt={'falmet linje'} />
  }

  const _renderGridItem = (item: IFooterLinkItem, userData: ICustomer) => {
    return (
      <ContentGrid
        tagType='nav'
        className={`${classPrefix}__item`}
        key={uuid4()}
        direction='column'
        alignment={'top-center'}
        gap={desktopView ? 3 : 2}
      >
        {desktopView && (
          <Text
            brand={activeBrand}
            {...h1TextPrefab()}
            color={BrandColors['primary-shade-light-2']}
            align='align-center'
          >
            {item.title}
          </Text>
        )}
        {!desktopView && _renderAction(item, userData)}
        {desktopView && _renderText(item)}
        {desktopView && _renderAction(item, userData)}
        {!desktopView && _renderText(item)}
      </ContentGrid>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      direction={desktopView ? 'row' : 'column'}
      alignment='center'
      boxSizing='border-box'
      gap={desktopView ? 2 : 3}
      spaceBetween
      className={classnames(classPrefix, {
        [`${activeBrand}`]: activeBrand,
        [`${className}`]: className
      })}
    >
      {userData && _renderGridItems(userData)}
    </StyleGrid>
  )
}
