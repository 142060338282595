import { FlexGridAligner, type IComponent, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { ActionButton } from '../../../components'
import { useApplicationContext } from '../../../contexts'
import { CouponCodeModal } from './variants/CouponCodeModal'
import { RecruitModal } from './variants/RecruitModal'
import './BenefitListItemModalContent.scss'
import type { IBenefit } from '../../../models'

export interface IBenefitListItemModalContent extends IComponent {
  data: IBenefit
  recruitLink?: string
  webshopLink?: string
}

export const BenefitListItemModalContent = (props: IBenefitListItemModalContent) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { data, recruitLink } = props
  const classPrefix = 'benefit-list-item-modal-content'

  // ************************************
  // Render
  // ************************************
  return (
    <>
      <Text
        className={`${classPrefix}__description`}
        type={'p'}
        weight={400}
        brand={activeBrand}
        theme={getCounterTheme(activeTheme)}
        faded
      >
        {data.description}
      </Text>
      {data.descriptionImageUrl && (
        <img
          className={`${classPrefix}__description-image`}
          src={data.descriptionImageUrl}
          alt={'Billede i beskrivelse'}
        />
      )}
      {data.type === 'recruit' && recruitLink && data.recruitText && (
        <RecruitModal recruitLink={recruitLink} recruitText={data.recruitText} />
      )}
      {data.type === 'couponcode' && (
        <CouponCodeModal couponCode={data.couponCode} couponQRCodeUrl={data.couponQRCodeUrl} />
      )}

      <FlexGridAligner
        className={`${classPrefix}__text__footer`}
        alignment={'center-left'}
        direction={'column'}
        gap={2}
      >
        <Text
          className={classnames(`${classPrefix}__text__footer__how-to-text`, {
            [`${classPrefix}--${activeTheme}`]: getCounterTheme(activeTheme)
          })}
          type={'p'}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
          faded
        >
          {data.howToText ?? ''}
        </Text>

        {(data.type === 'cta' || data.type === 'couponcode') && (
          <ActionButton
            template={MS_ButtonTemplate(activeTheme, 'primary')}
            className={`${classPrefix}__text__footer__cta-button`}
            brand={activeBrand}
            padding={'medium'}
            action={{
              link: data.ctaLink?.url,
              text: data.ctaLink?.title
            }}
          />
        )}

        {data.termsText && (
          <Text
            className={classnames(`${classPrefix}__text__footer__how-to-text`, {
              [`${classPrefix}--${activeTheme}`]: getCounterTheme(activeTheme)
            })}
            type={'p'}
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            faded
          >
            {data.termsText}
          </Text>
        )}

        {data.moreInfoLink?.url && data.moreInfoLink?.title && (
          <ActionButton
            className={`${classPrefix}__text__footer__more-info-button`}
            brand={activeBrand}
            template={MS_ButtonTemplate(activeTheme, 'link')}
            action={{
              text: data.moreInfoLink.title,
              link: data.moreInfoLink.url
            }}
          />
        )}
      </FlexGridAligner>
    </>
  )
}
