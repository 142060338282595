import _ from 'lodash'
import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import { useApplicationDefaultContext } from '../../../contexts'
import { PopupCard } from '../../../modals'
import { PopupContent } from '../../../modals/ServiceManagementPopup/components/PopupContent'
import { getServiceDescriptionForStatus, initialSelectOption } from '../../../modals/ServiceManagementPopup/utils'
import {
  type IServicePopupBlock,
  type IServicePopupCheckbox,
  type OnClickServiceOrderAction,
  OnClickServiceOrderActionEnum,
  type ServiceOrderSteps,
  ServiceOrderStepsEnum,
  ServiceStatusEnum
} from '../../../models'
import { capitalizeFirstLetter, getText } from '../../../services'

const getServicePopupBlock = ({
  status,
  selectedOption,
  translations
}: {
  status: ServiceStatusEnum
  selectedOption: OnClickServiceOrderAction
  translations: any
}) => {
  let statusForSelection = status
  //Covers case with one installation active and one inactive, recalculates texts based on chosen option
  if (selectedOption === OnClickServiceOrderActionEnum.CANCEL) statusForSelection = ServiceStatusEnum.ACTIVE
  if (selectedOption === OnClickServiceOrderActionEnum.ORDER) statusForSelection = ServiceStatusEnum.INACTIVE
  const serviceStatusDescription = getServiceDescriptionForStatus(statusForSelection, translations.serviceDescriptions)
  if (!serviceStatusDescription) throw new Error('ServiceStatusDescription not found')

  const { servicePopupBlock } = serviceStatusDescription

  const checkboxes = servicePopupBlock.checkboxes?.map(e => ({ ...e, id: uuid4(), alreadyChecked: false }))

  const servicePopupBlockData: IServicePopupBlock = {
    texts: servicePopupBlock.texts,
    dataPoints: [],
    selectedExtraProductDesc: '',
    description: servicePopupBlock?.description,
    links: servicePopupBlock?.links,
    checkboxes: checkboxes,
    orderResult: servicePopupBlock?.orderResult,
    orderMethodType: servicePopupBlock?.orderMethodType
  }

  return servicePopupBlockData
}

const getPopupTitle = ({
  serviceActionData,
  currentStep,
  selectedOption
}: {
  serviceActionData: IServicePopupBlock
  currentStep: ServiceOrderSteps
  selectedOption: OnClickServiceOrderAction
}) => {
  const popupTitleKey = {
    [ServiceOrderStepsEnum.FAILURE]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.ERROR]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.IN_PROGRESS]: 'waitingResultTitle',
    [ServiceOrderStepsEnum.SUCCESS]: 'successResultTitle',
    [ServiceOrderStepsEnum.REDIRECT_SUCCESS]: 'successResultTitleOrder',
    [ServiceOrderStepsEnum.SELECT]: 'confirmTitle',
    [ServiceOrderStepsEnum.DEFAULT]: `title${capitalizeFirstLetter(selectedOption)}`
  }
  const textKey = _.get(popupTitleKey, `${currentStep}`, popupTitleKey[ServiceOrderStepsEnum.DEFAULT])
  return getText(textKey, serviceActionData, 'texts')
}

const getNavigationButtons = (
  steps: ServiceOrderStepsEnum[],
  currentStep: string,
  nextStep: ServiceOrderSteps | null,
  previousStep: ServiceOrderSteps | null,
  translations: any,
  allChecksChecked: boolean,
  updateStep: (stepChange: ServiceOrderSteps) => void,
  onConfirm: () => Promise<void>,
  onClose: () => void
) => {
  return {
    ...(steps.includes(currentStep as ServiceOrderStepsEnum) && {
      primaryAction: {
        ...(nextStep
          ? {
              onClick: () => updateStep(nextStep),
              text: getText('readMore', translations)
            }
          : {
              disabled: allChecksChecked,
              onClick: onConfirm,
              text: getText('readMore', translations)
            })
      },
      secondaryAction: {
        ...(previousStep
          ? {
              onClick: () => updateStep(previousStep),
              text: getText('goBack', translations)
            }
          : {
              onClick: onClose,
              text: getText('closePopupButton', translations)
            })
      }
    })
  }
}

const getNextStep = (currentStep: ServiceOrderSteps, steps: ServiceOrderSteps[]) => {
  const currentIndex = steps.indexOf(currentStep)
  const hasNextStep = steps.length - 1 > currentIndex
  return hasNextStep ? steps[currentIndex + 1] : null
}

const getPreviousStep = (currentStep: ServiceOrderSteps, steps: ServiceOrderSteps[]) => {
  const currentIndex = steps.indexOf(currentStep)
  const hasPreviousStep = currentIndex > 0
  return hasPreviousStep ? steps[currentIndex - 1] : null
}

export const ServicePopup = ({
  translations,
  status,
  steps,
  initialStep,
  isTrumf,
  onConfirm,
  onClose
}: {
  translations: any
  status: ServiceStatusEnum
  steps: ServiceOrderStepsEnum[]
  initialStep: ServiceOrderStepsEnum
  isTrumf: boolean
  onConfirm: (sel: OnClickServiceOrderAction) => Promise<ServiceOrderSteps>
  onClose: (c: ServiceOrderSteps) => void
}) => {
  const {
    defaultProps: { activeTheme: theme, activeBrand: brand, desktopView }
  } = useApplicationDefaultContext()

  const [selectedOption, setSelectedOption] = useState<OnClickServiceOrderAction>(initialSelectOption[status])
  const [currentStep, setCurrentStep] = useState<ServiceOrderSteps>(initialStep)
  const [nextStep, setNextStep] = useState<ServiceOrderSteps | null>(getNextStep(currentStep, steps))
  const [previousStep, setPreviousStep] = useState<ServiceOrderSteps | null>(null)
  const [checkboxStates, setCheckboxStates] = useState<IServicePopupCheckbox[]>()

  const onConfirmInternal = async () => {
    setCurrentStep(ServiceOrderStepsEnum.LOADING)
    const result = await onConfirm(selectedOption)
    setCurrentStep(result)
  }

  const onCloseInternal = () => {
    onClose(currentStep)
    updateStep(steps[0])
  }

  const updateStep = (stepChange: ServiceOrderSteps) => {
    const nextStep = getNextStep(stepChange, steps)
    const previousStep = getPreviousStep(stepChange, steps)
    setCurrentStep(stepChange)
    setNextStep(nextStep)
    setPreviousStep(previousStep)
  }

  const serviceActionData = getServicePopupBlock({ status, selectedOption, translations })
  if (!checkboxStates) {
    setCheckboxStates(serviceActionData.checkboxes ?? [])
  }

  const allChecksChecked = !!checkboxStates?.find(checkbox => !checkbox.alreadyChecked)

  const navigationButtons = getNavigationButtons(
    steps,
    currentStep,
    nextStep,
    previousStep,
    translations,
    allChecksChecked,
    updateStep,
    onConfirmInternal,
    onCloseInternal
  )

  return (
    <PopupCard
      theme={theme}
      brand={brand}
      contentTitle={getPopupTitle({ serviceActionData, currentStep, selectedOption })}
      contentTitleAlignment={
        currentStep === ServiceOrderStepsEnum.SELECT || currentStep === ServiceOrderStepsEnum.DEFAULT
          ? 'align-left'
          : 'align-center'
      }
      contentGap={4}
      onClose={onCloseInternal}
      desktopView={desktopView}
      {...navigationButtons}
    >
      <PopupContent
        currentStep={currentStep}
        classPrefix={'service-stage-popup'}
        selectedOption={selectedOption}
        isTrumf={isTrumf}
        theme={theme}
        brand={brand}
        checkboxStates={checkboxStates}
        setCheckboxStates={setCheckboxStates}
        productDefinitionId={translations.productDefinitionId}
        data={{
          dataPoints: serviceActionData?.dataPoints,
          texts: serviceActionData?.texts,
          house: serviceActionData?.house,
          selectedExtraProductDesc: serviceActionData?.selectedExtraProductDesc,
          description: serviceActionData?.description,
          links: serviceActionData?.links
        }}
        onClickSelectOption={val => setSelectedOption(val?.value)}
      />
    </PopupCard>
  )
}
