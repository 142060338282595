import {
  ContentGrid,
  type IAccordion,
  type IComponent,
  IconType,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { MS_ButtonTemplate, getPlankPrefab, h3TextPrefab, paragraphTextPrefab } from '../../../Prefabs'
import {
  type IMoveAddress,
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useMoveContext
} from '../../../contexts'
import { PlankWall } from '../../PlankWallBlock'
import './MoveFormPages.scss'
import { getText } from '../../../services'

export interface IMoveFormPageAddress extends IComponent {
  addressesData: IMoveAddress[]
  accordionData: IAccordion
  nextPage: () => void
}

export const MoveFormPageAddress = (props: IMoveFormPageAddress) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { translation } = useApplicationCoreDataContext()
  const { customerServiceFeature } = useApplicationServicehandlerContext()
  const { setAddress } = useMoveContext()
  const { id, addressesData, nextPage } = props
  const classPrefix = 'move-form-page-address'

  // ************************************
  // Render Functionality
  // ************************************

  const _getAddressPlanks = () => {
    return addressesData.map(address => {
      const isUnderTermination = address.status !== 'none'

      return getPlankPrefab('Action', {
        left: {
          title: address.address,
          description: `${translation.addressPage.meterNumberLabel} ${address.physicalMeterNumber}`
        },
        right: {
          template: MS_ButtonTemplate(activeTheme, 'primary')
        },
        bottom: isUnderTermination
          ? {
              title: getText('plankFormPageAddressMoveOutInstallationUnderTermination', translation)
            }
          : null,
        actionIconPlacement: 'Right',
        actionButtonPadding: 'medium',
        action: {
          disabled: isUnderTermination,
          text: desktopView ? translation.addressPage.buttonTitle : undefined,
          icon: IconType.ArrowRight,
          onClick: () => {
            setAddress(address)
            nextPage()
          }
        }
      })
    })
  }

  // ************************************
  // Render
  // ************************************
  return (
    <StyleGrid
      id={id}
      className={`${classPrefix}`}
      brand={activeBrand}
      theme={activeTheme}
      alignment={'center-left'}
      direction={'row'}
      gap={5}
    >
      <ContentGrid
        className={`${classPrefix}__addresses`}
        alignment={'top-left'}
        direction={'column'}
        tagType={'section'}
        gap={1}
      >
        <Text {...h3TextPrefab()} brand={activeBrand} theme={getCounterTheme(activeTheme)}>
          {customerServiceFeature ? translation.addressPage.titleCustomerService : translation.addressPage.title}
        </Text>
        {customerServiceFeature && (
          <Text {...paragraphTextPrefab()}>{translation.addressPage.descriptionCustomerService}</Text>
        )}
        {addressesData && (
          <PlankWall
            className={`${classPrefix}__address-plank-wall`}
            brand={activeBrand}
            theme={activeTheme}
            planks={_getAddressPlanks()}
          />
        )}
      </ContentGrid>
    </StyleGrid>
  )
}
