import {
  BaseComponent,
  BrandColors,
  CheckInputTemplate,
  type IBaseComponent,
  Icon,
  IconType
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './CheckInputV2.scss'

/**
 *   ICheckInput
 *   @type {function} onChange (required)
 *   @type {any} value (optional)
 *   @type {string} type (optional)
 *   @type {object} icon (optional)
 *   @type {string} parentGroupId (optional)
 */
export interface ICheckInput extends IBaseComponent {
  onChange: (value?: any, state?: boolean) => void
  value?: any
  type?: 'checkbox' | 'radio'
  icon?: {
    type: IconType
    size: number
  }
  parentGroupId?: string
}

/**
 *   This check input component can be used either as a radio input or check
 *   @type {ICheckInput} props (required)
 */
export const CheckInputV2 = (props: ICheckInput) => {
  // ************************************
  // Properties / Constructor
  // ************************************

  const {
    id,
    className,
    theme = 'Light',
    brand,
    active = false,
    disabled,
    value,
    type = 'checkbox',
    parentGroupId,
    icon = {
      type: IconType.CheckmarkThick,
      size: 1
    },
    template = CheckInputTemplate(theme, type),
    onChange
  } = props
  const classPrefix = 'check-input-component'

  // ************************************
  // Lifecycle
  // ************************************

  const [checked, setChecked] = useState<boolean>(active)

  useEffect(() => {
    setChecked(active)
  }, [active])

  // ************************************
  // Render
  // ************************************

  return (
    <BaseComponent
      id={id ? `${id}-wrapper` : undefined}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      theme={theme}
      brand={brand}
      template={template}
      active={checked}
      disabled={disabled}
    >
      <input
        id={id}
        className={`${classPrefix}__input`}
        name={parentGroupId}
        type={type}
        checked={checked}
        onChange={() => {
          setChecked(
            //type === 'checkbox' ? !checked : event.target.checked
            !checked
          )
          onChange(
            value,
            //type === 'checkbox' ? !checked : event.target.checked
            !checked
          )
        }}
        disabled={disabled}
      />

      {checked && icon && (
        <Icon
          className={`${classPrefix}__icon`}
          brand={brand}
          theme={theme}
          type={icon.type}
          width={icon.size}
          height={icon.size}
          color={template.palette.icon?.color ?? BrandColors['icon-shade-light-1']}
        />
      )}
    </BaseComponent>
  )
}
