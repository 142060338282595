import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { type IWebToast, ToasterBlock } from '../../../blocks'
import { ApplicationOverlayWrapperContext, useApplicationContext } from '../../../contexts'
import { LoadingModal } from '../../../modals'
import './ApplicationOverlayWrapper.scss'

export const ApplicationOverlayWrapper = (props: any) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'application-overlay-wrapper'
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [toasts, setToasts] = useState<IWebToast[]>()
  const [globalLoading, setGlobalLoading] = useState<boolean>(false)

  const context = useMemo(() => {
    return {
      toasts,
      setToasts,
      globalLoading,
      setGlobalLoading
    }
  }, [globalLoading, toasts])

  // ************************************
  // Helper Functionality
  // ************************************

  const _parseStaticToasts = () => {
    const toasties: IWebToast[] = []

    if (toasts && toasts.length > 0) {
      toasts.forEach((translation: IWebToast) => {
        const foundClosedItem = localStorage.getItem(translation.id)
        translation.staticToast = true

        if (!foundClosedItem) {
          toasties.push(translation)
        }
      })
    }

    return toasties
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderStaticToast = () => {
    if (toasts && toasts.length > 0) {
      return (
        <ToasterBlock
          id={`${classPrefix}__toaster__static-id`}
          className={classNames(`${classPrefix}__toaster`, {
            [`${classPrefix}__toaster__top`]: desktopView
          })}
          coverage='relative'
          theme={activeTheme}
          brand={activeBrand}
          toasts={_parseStaticToasts()}
        />
      )
    }
  }

  const _renderGlobalLoader = useMemo(() => {
    if (globalLoading) {
      return (
        <LoadingModal className={`${classPrefix}__loader`} mode={'window'} brand={activeBrand} theme={activeTheme} />
      )
    }
  }, [globalLoading])

  // ************************************
  // Render
  // ************************************
  return (
    <ApplicationOverlayWrapperContext.Provider value={context}>
      {props.children}
      {_renderStaticToast()}
      {_renderGlobalLoader}
    </ApplicationOverlayWrapperContext.Provider>
  )
}
