import {
  ContentGrid,
  type IIconAdvanced,
  type IMasterPlank,
  type ITextCustomization,
  Icon,
  type IconType,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { MSRichText } from '../../../components'
import { h4TextPrefab } from '../../Texts/Header4TextPrefab'
import { paragraphTextPrefab } from '../../Texts/ParagraphTextPrefab'
import { smallParagraphTextPrefab } from '../../Texts/SmallParagraphTextPrefab'
import '../PlankPrefabStyle.scss'
import { v4 as uuid4 } from 'uuid'
export interface ITextPlankPrefabCustomization {
  title?: ITextCustomization
  description?: ITextCustomization
  icon?: IIconAdvanced
}

export interface ITextPlankPrefab extends IMasterPlank {
  left?: {
    title?: string
    description?: string
    extra?: string
    icon?: IconType
    customization?: ITextPlankPrefabCustomization
  }
  right?: {
    title?: string
    description?: string
    icon?: IconType
    customization?: ITextPlankPrefabCustomization
  }
  bottom?: {
    title?: string
    description?: string
    titleRight?: string
    descriptionRight?: string
    customization?: {
      description: {
        align: 'align-left' | 'align-center' | 'align-right' | undefined
      }
    }
  }
  desktopView?: boolean
}

export const TextPlankPrefab = (props: ITextPlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, left, right, bottom, desktopView = true, template } = props
  const classPrefix = 'ms-text-plank'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderBottomContent = () => {
    if (bottom) {
      return (
        <ContentGrid
          className={`${classPrefix}__bottom`}
          tagType='section'
          alignment='top-left'
          direction='row'
          boxSizing='border-box'
          spaceBetween
          wrap={false}
        >
          {_renderBottomContentSide('left', bottom.title, bottom.description)}
          {(bottom.titleRight || bottom.descriptionRight) &&
            _renderBottomContentSide('right', bottom.titleRight, bottom.descriptionRight)}
        </ContentGrid>
      )
    }
  }

  const _renderBottomContentSide = (placement: 'left' | 'right', title?: string, description?: string) => {
    return (
      <ContentGrid
        className={`${classPrefix}__bottom--${placement}`}
        direction='column'
        alignment={placement === 'left' ? 'top-left' : 'top-right'}
        gap={1}
        boxSizing='border-box'
        tagType='section'
      >
        {title && (
          <Text {...h4TextPrefab()} brand={brand} theme={getCounterTheme(theme)} align={'align-right'}>
            {title}
          </Text>
        )}
        {description && (
          <MSRichText brand={brand} text={description} theme={getCounterTheme(theme)} alignment='lawful' />
        )}
      </ContentGrid>
    )
  }

  const _renderLeftIcon = () => {
    if (left?.icon) {
      return [
        <Icon
          key={uuid4()}
          brand={brand}
          type={left.icon}
          width={left.customization?.icon?.size ?? 1.5}
          height={left.customization?.icon?.size ?? 1.5}
          {...left.customization?.icon}
        />
      ]
    }
  }

  const _renderLeftTitle = () => {
    if (left?.title) {
      return (
        <Text {...h4TextPrefab()} brand={brand} align={'align-left'} {...left.customization?.title}>
          {left.title}
        </Text>
      )
    }
  }

  const _renderLeftDescription = () => {
    if (left?.description) {
      return (
        <MSRichText
          {...paragraphTextPrefab({ theme: theme })}
          text={left.description}
          brand={brand}
          alignment='lawful'
          customization={{
            type: 'p',
            faded: true,
            align: 'align-left',
            wrap: 'pre-wrap'
          }}
          {...left.customization?.description}
        />
      )
    }
  }

  const _renderLeftExtra = () => {
    if (left?.extra) {
      return (
        <Text
          {...smallParagraphTextPrefab()}
          className={`${classPrefix}__left__text__extra`}
          brand={brand}
          faded
          align={'align-left'}
          {...left.customization?.description}
        >
          {left.extra}
        </Text>
      )
    }
  }

  const _renderRightIcon = () => {
    if (right?.icon) {
      return [
        <Icon
          key={uuid4()}
          brand={brand}
          type={right.icon}
          width={right.customization?.icon?.size ?? 1.5}
          height={right.customization?.icon?.size ?? 1.5}
          {...right.customization?.icon}
        />
      ]
    }
  }

  const _renderRightTitle = () => {
    if (right?.title) {
      return (
        <Text {...h4TextPrefab()} brand={brand} align={'align-right'} wrap='pre-wrap' {...right.customization?.title}>
          {right.title}
        </Text>
      )
    }
  }

  const _renderRightDescription = () => {
    if (right?.description) {
      return (
        <MSRichText
          {...paragraphTextPrefab()}
          text={right.description}
          brand={brand}
          theme={getCounterTheme(theme)}
          alignment='lawful'
          customization={{
            type: 'p',
            faded: true,
            align: 'align-right',
            wrap: 'pre-wrap'
          }}
          {...right.customization?.description}
        />
      )
    }
  }

  const _renderLeft = () => {
    if (left?.title || left?.description || left?.extra) {
      return [_renderLeftTitle(), _renderLeftDescription(), _renderLeftExtra()]
    }
  }

  const _renderRight = () => {
    if (right?.title || right?.description) {
      return [_renderRightTitle(), _renderRightDescription()]
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    brand,
    template,
    leftColumn1: desktopView ? _renderLeftIcon() : undefined,
    leftColumn2: _renderLeft(),
    rightColumn1: _renderRight(),
    rightColumn2: _renderRightIcon(),
    bottomContent: _renderBottomContent()
  } as IMasterPlank
}
