import { Text, type TextType } from '@fjordkraft/fjordkraft.component.library'
import { h3TextPrefab, paragraphTextPrefab } from '../../../Prefabs'
import type { ITranslationItem } from '../../../models'
import { hasText } from '../../../services'

export const ContentText = ({
  textKey,
  tag = 'p',
  texts
}: {
  textKey: string
  tag?: TextType
  texts: ITranslationItem[]
}) => {
  const prefab = tag === 'p' ? paragraphTextPrefab() : h3TextPrefab()

  return <Text {...prefab}>{hasText(textKey, texts)}</Text>
}
