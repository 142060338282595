import type { IComponentTemplate, IconType, Theme } from '@fjordkraft/fjordkraft.component.library'
import { type ITextPlankPrefabCustomization, getPlankPrefab } from '../../../Prefabs'
import { isExternalUrl } from '../../../services'

interface ITextPlankPrefabTemplate {
  theme: Theme
  brand: string
  link?: string | null
  title?: string
  description?: string
  extra?: string
  rightTitle?: string
  text?: string
  iconLeft?: IconType
  iconRight?: IconType
  template?: IComponentTemplate
  rightCustomization?: ITextPlankPrefabCustomization
  leftCustomization?: ITextPlankPrefabCustomization
  disabled?: boolean
}

export const _getTextPlankPrefabTemplate = (props: ITextPlankPrefabTemplate) => {
  const {
    theme,
    brand,
    link,
    title,
    description,
    extra,
    rightTitle,
    text,
    iconLeft,
    iconRight,
    template,
    rightCustomization,
    leftCustomization,
    disabled
  } = props

  const rIcon: IconType | undefined = iconRight

  return getPlankPrefab('Text', {
    theme,
    brand,
    template: template,
    left: {
      title: title ?? '',
      description: description ?? '',
      extra: extra ?? '',
      icon: iconLeft,
      customization: leftCustomization
    },
    right: {
      title: rightTitle ?? undefined,
      description: text ?? '',
      icon: rIcon,
      customization: rightCustomization
    },
    action: link
      ? {
          link: link,
          useRouterLink: !isExternalUrl(link),
          disabled
        }
      : undefined
  })
}

export * from './ConsumptionPlanks'
export * from './ElectricityAgreementPlank'
export * from './SpotpricePlank'
export * from './ServicesPlank'
export * from './InvoicePlank'
export * from './MovingPlank'
export * from './InboxPlank'
export * from './MyPageSettingsPlank'
export * from './BenefitPlank'
export * from './TrumfPlank'
export * from './OnboardingPlank'
export * from './SteddiPlank'
