import { useEffect, useMemo, useState } from 'react'
import {
  ApplicationAddonServicesContext,
  type IServicesSummary,
  useApplicationCoreDataContext,
  useApplicationDefaultContext,
  useApplicationServicehandlerContext
} from '../../../contexts'
import type { IAddonData, IServicePage } from '../../../models'
import { getParsedAddonStates } from '../../../services'
import { getAvailableServicePages } from '../../ServicesPagesWrapper/ServicesOverviewPage/ServicesOverviewPageData'

export const ApplicationAddonServicesWrapper = (props: any) => {
  const { epiChildren, userData, installation } = useApplicationCoreDataContext()
  const { defaultProps } = useApplicationDefaultContext()
  const { user } = defaultProps
  const { GET, GETTYPED, customerServiceFeature } = useApplicationServicehandlerContext()

  //Context values
  const [addonStates, setAddonStates] = useState<IAddonData[]>()
  const [availableServicePages, setAvailableServicePages] = useState<IServicePage[]>()
  const [servicesSummary, setServicesSummary] = useState<IServicesSummary>()

  useEffect(() => {
    if (epiChildren && !availableServicePages && user && installation) {
      if (!addonStates) {
        updateAddonStatesAndAvailableServicePages()
      } else {
        updateAvailableServicePages(addonStates)
      }
    }
  }, [epiChildren, installation])

  const updateAddonStates = async () => {
    const addonData = await getParsedAddonStates(epiChildren, GET, true)
    setAddonStates(addonData)
    return addonData
  }

  const updateAvailableServicePages = async (addonStates: any) => {
    const availableServicePages = await getAvailableServicePages(
      epiChildren,
      customerServiceFeature,
      user,
      GETTYPED,
      userData,
      installation,
      addonStates
    )
    setAvailableServicePages(availableServicePages)
    return availableServicePages
  }
  const updateAddonStatesAndAvailableServicePages = async () => {
    const fetchedAddonStates = await updateAddonStates()
    const fetchedAvailableServicePages = await updateAvailableServicePages(fetchedAddonStates)
    setServicesSummary({
      totalAvailableServices: fetchedAvailableServicePages?.length,
      totalActiveServices: fetchedAvailableServicePages?.reduce(
        (acc, curr) => acc + (curr?.serviceStatus?.state === 'ACTIVE' ? 1 : 0),
        0
      )
    })
  }

  const context = useMemo(() => {
    return {
      addonStates,
      updateAddonStates,
      availableServicePages,
      updateAddonStatesAndAvailableServicePages,
      updateAvailableServicePages,
      servicesSummary
    }
  }, [addonStates, servicesSummary, availableServicePages, epiChildren])

  return (
    <ApplicationAddonServicesContext.Provider value={context}>
      {props.children}
    </ApplicationAddonServicesContext.Provider>
  )
}
