import {
  BrandColors,
  type IComponentColors,
  type IComponentTemplate,
  type IComponentTransform,
  type IThemedColors,
  type Theme,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import type { StatusType } from '../../../models'

const _wrapperTransform = (desktopView: boolean): IComponentTransform => {
  return {
    width: '100%',
    border: desktopView
      ? {
          radiusType: 'curved'
        }
      : undefined,
    padding: desktopView ? '1rem' : '1.25rem 1rem',
    grid: {
      direction: 'row',
      tagType: 'section',
      gap: 1,
      boxSizing: 'border-box',
      alignment: 'center-left'
    }
  }
}

const _transform = (desktopView: boolean): IComponentTransform => {
  return {
    height: 'auto',
    width: desktopView ? 'auto' : '100%',
    minWidth: desktopView ? '15rem' : '100vw',
    padding: '0',
    border: desktopView
      ? {
          radiusType: 'curved',
          type: 'solid',
          thickness: 3
        }
      : undefined,
    grid: {
      boxSizing: 'border-box',
      gap: 1,
      direction: 'column',
      alignment: 'top-left'
    }
  } as IComponentTransform
}

const _wrapperPalette = {
  Light: {
    background: { color: BrandColors['background-shade-light-1'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-3'] }
  }
} as IThemedColors

const _palette = (status: StatusType, theme: Theme): IComponentColors => {
  switch (status) {
    case 'negative':
      return _negativePalette(theme)
    case 'neutral':
      return _neutralPalette(theme)
    case 'positive':
      return _positivePalette(theme)
  }
}

const _positivePalette = (theme: Theme): IComponentColors => {
  const lowercaseTheme: 'light' | 'dark' = theme.toLocaleLowerCase() as 'light' | 'dark'
  const lowercaseCounterTheme: 'light' | 'dark' = getCounterTheme(theme).toLocaleLowerCase() as 'light' | 'dark'

  return {
    background: { color: BrandColors[`status-shade-${lowercaseTheme}-1`], opacity: 0.25 },
    border: { color: BrandColors[`status-shade-${lowercaseTheme}-1`] },
    icon: { color: BrandColors[`status-shade-${lowercaseTheme}-1`] },
    text: { color: BrandColors[`text-shade-${lowercaseCounterTheme}-3`] }
  }
}

const _neutralPalette = (theme: Theme): IComponentColors => {
  const lowercaseTheme: 'light' | 'dark' = theme.toLocaleLowerCase() as 'light' | 'dark'
  const lowercaseCounterTheme: 'light' | 'dark' = getCounterTheme(theme).toLocaleLowerCase() as 'light' | 'dark'

  return {
    background: { color: BrandColors[`action-shade-${lowercaseTheme}-2`], opacity: 0.25 },
    border: { color: BrandColors[`action-shade-${lowercaseTheme}-2`] },
    icon: { color: BrandColors[`action-shade-${lowercaseTheme}-2`] },
    text: { color: BrandColors[`text-shade-${lowercaseCounterTheme}-3`] }
  }
}

const _negativePalette = (theme: Theme): IComponentColors => {
  const lowercaseTheme: 'light' | 'dark' = theme.toLocaleLowerCase() as 'light' | 'dark'
  const lowercaseCounterTheme: 'light' | 'dark' = getCounterTheme(theme).toLocaleLowerCase() as 'light' | 'dark'

  return {
    background: { color: BrandColors[`status-shade-${lowercaseTheme}-3`], opacity: 0.25 },
    border: { color: BrandColors[`status-shade-${lowercaseTheme}-3`] },
    icon: { color: BrandColors[`status-shade-${lowercaseTheme}-3`] },
    text: { color: BrandColors[`text-shade-${lowercaseCounterTheme}-3`] }
  }
}

export const MS_StatusToastTemplate = (theme: Theme, desktopView = true) => {
  return {
    transform: _transform(desktopView),
    palette: _wrapperPalette[theme]
  }
}

export const MS_StatusWrapperToastTemplate = (theme: Theme, status: StatusType, desktopView = true) => {
  return {
    transform: _wrapperTransform(desktopView),
    palette: _palette(status, theme),
    dontUpdateChildren: true
  } as IComponentTemplate
}
