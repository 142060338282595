import type { IServicePitchCard, IServiceStatusDescriptions, ServiceStatus } from '../../../../models'
import { getServiceEpiDataBasedOnStatus } from './ServicePageDataDescriptions'

// ************************************
// PUBLIC
// ************************************
export type ServicePageDataPitchCards = {
  items?: IServicePitchCard[]
  title?: string
  description?: string
}

export const getServicePageDataPitchCards = (translations: any, status: ServiceStatus): ServicePageDataPitchCards => {
  const relevantDescription: IServiceStatusDescriptions | undefined = getServiceEpiDataBasedOnStatus(
    translations,
    status
  )

  return {
    items: relevantDescription?.pitches,
    title: relevantDescription?.pitchesTitle,
    description: relevantDescription?.pitchesDescription
  }
}
