import { CheckInput, ContentGrid, StyleGrid, Text } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import { MSCheckInputTemplate, h3TextPrefab, paragraphTextPrefab } from '../../Prefabs'
import { useApplicationContext, useApplicationCoreDataContext } from '../../contexts'
import type { IBlock } from '../../models'
import { getText } from '../../services'
import './InstallationYearsBar.scss'

export interface IInstallationConsumptionYearItem {
  value: any
  text: string
  active: boolean
  disabled?: boolean
}

export interface IInstallationYearsBar extends IBlock {
  primaryActiveYear: number
  translations: any
  maxClamp?: number
  onChange: (selectedYears: number[]) => void
}

export const InstallationYearsBar = (props: IInstallationYearsBar) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    theme = 'Light',
    brand,
    primaryActiveYear,
    translations,
    disabled,
    maxClamp = 5,
    onChange
  } = props
  const classPrefix = 'installation-years-bar'
  const { installation } = useApplicationCoreDataContext()
  const { desktopView } = useApplicationContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [items, setItems] = useState<IInstallationConsumptionYearItem[]>()
  const [clampedAmount, setClampedAmount] = useState<number>(1)

  // ************************************
  // Data handling
  // ************************************
  const _generateYearSelection = (consumptionYears?: number[]) => {
    const yearItems: IInstallationConsumptionYearItem[] = []

    if (consumptionYears && consumptionYears.length > 0) {
      consumptionYears.forEach((year: number) => {
        yearItems.push({
          value: year,
          text: `${year}`,
          active: primaryActiveYear === year,
          disabled: primaryActiveYear === year
        })
      })
    }
    setItems(yearItems)
  }

  // Every time user changes primary active year,
  // re-generate yearItems -> results in list with all years but new primary active year as chosen & disabled
  // set clamped amount (how many checkboxes are selected) to 1
  // trigger onChange for selected years to update chart series in parent component.
  useEffect(() => {
    _generateYearSelection(installation?.consumptionYears)
    setClampedAmount(1)
    onChange([primaryActiveYear])
  }, [primaryActiveYear])

  const _handleChecks = (newCheck: IInstallationConsumptionYearItem) => {
    if (items && items.length > 0) {
      const selectedYears: number[] = []
      let amountClampedLocal = 0
      const updatedItems: IInstallationConsumptionYearItem[] = []

      items.forEach((s: IInstallationConsumptionYearItem) => {
        if (newCheck.value === s.value) {
          s.active = !s.active
        }

        if (s.value === primaryActiveYear) {
          s.active = true
        }

        if (s.active) {
          amountClampedLocal++
          selectedYears.push(s.value)
        }

        updatedItems.push(s)
      })

      setItems(updatedItems)
      setClampedAmount(amountClampedLocal)
      onChange(selectedYears)
    }
  }

  // ************************************
  // Helpers
  // ************************************

  const _getMaxClamp = () => {
    if (maxClamp && items && maxClamp > items.length) {
      return items.length
    }
    return maxClamp ?? 1
  }

  const _getTitle = () => {
    return getText('compare', translations)
  }

  const _getScaling = () => {
    if (desktopView) {
      return _getTitle() ? 4 : 0
    }
    return 0
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderCheckInputs = useMemo(() => {
    if (items && items.length > 0) {
      return items.map((item: IInstallationConsumptionYearItem) => {
        let isDisabled = item.disabled ?? disabled
        const parentId = uuid4()

        if (maxClamp && clampedAmount >= _getMaxClamp() && !item.active) {
          isDisabled = true
        }

        return (
          <StyleGrid
            className={classNames(`${classPrefix}__container__item`, {
              [`${classPrefix}__container__item--desktop`]: desktopView
            })}
            key={uuid4()}
            alignment='center'
            gap={desktopView ? 1 : 0}
            spaceBetween={!desktopView}
            direction={'row'}
            boxSizing='border-box'
          >
            <Text {...paragraphTextPrefab(props)} faded={disabled}>
              {item.text}
            </Text>
            <CheckInput
              template={MSCheckInputTemplate(theme, 'checkbox')}
              theme={theme}
              brand={brand}
              disabled={isDisabled}
              parentGroupId={parentId}
              type={'checkbox'}
              value={item.value}
              active={item.active}
              onChange={() => {
                _handleChecks({
                  value: item.value,
                  active: !item.active,
                  text: item.text
                })
              }}
            />
          </StyleGrid>
        )
      })
    }
  }, [theme, brand, items, disabled, desktopView, primaryActiveYear])

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(classPrefix, {
        [`${classPrefix}--desktop`]: desktopView,
        [`${className}`]: className
      })}
      direction='column'
      alignment='center'
      gap={1}
      boxSizing='border-box'
    >
      <ContentGrid
        className={`${classPrefix}__header`}
        alignment='center'
        direction='row'
        spaceBetween
        tagType={'section'}
        boxSizing='border-box'
      >
        <Text {...h3TextPrefab(props)}>{_getTitle()}</Text>
        <Text {...paragraphTextPrefab(props)} faded={true}>
          {`${clampedAmount} / ${_getMaxClamp()}`}
        </Text>
      </ContentGrid>
      <ContentGrid
        className={classNames(`${classPrefix}__container`, {
          [`${classPrefix}__container--${theme}`]: theme
        })}
        alignment={'center'}
        direction={desktopView ? 'row' : 'column'}
        gap={desktopView ? 2 : 1}
        scaling={_getScaling()}
        tagType={'nav'}
        boxSizing='border-box'
      >
        {_renderCheckInputs}
        {disabled && (
          <span
            className={classNames(`${classPrefix}__container__loader`, {
              [`${classPrefix}__container__loader--${theme}`]: theme
            })}
          />
        )}
      </ContentGrid>
    </StyleGrid>
  )
}
