import {
  BrandColors,
  type IMasterPlankTemplate,
  type IThemedColors,
  type LocationType,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'
import { PointPlankTemplate } from './PointPlankTemplate'

const _palette = {
  Light: {
    background: { color: BrandColors['primary-shade-light-2'] },
    text: { color: BrandColors['text-shade-light-1'] },
    border: { color: BrandColors['none'] },
    icon: { color: BrandColors['primary-shade-light-3'] },
    hover: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    focus: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    disabled: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.5
      },
      text: { color: BrandColors['text-shade-light-1'], opacity: 0.5 },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    }
  },
  Dark: {
    background: { color: BrandColors['primary-shade-light-2'] },
    text: { color: BrandColors['text-shade-light-1'] },
    border: { color: BrandColors['none'] },
    icon: { color: BrandColors['primary-shade-light-3'] },
    hover: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    focus: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    disabled: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.5
      },
      text: { color: BrandColors['text-shade-light-1'], opacity: 0.5 },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    }
  }
} as IThemedColors

export const TrumfPlankTemplate = (theme: Theme, locaton: LocationType) => {
  const temp: IMasterPlankTemplate = structuredClone(PointPlankTemplate(theme, locaton))

  temp.palette = _palette[theme]

  return temp
}
