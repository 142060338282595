import type { MarketingConsent } from '../../../models/interfaces/sanity/MarketingConsent'
import { getBrandShorthand } from '../HelperService'
import { sanityClient } from './SanityClient'

export async function getMarketingConsentText(): Promise<MarketingConsent[]> {
  const query = `*[_type == "consent" && brand->.systemName == '${getBrandShorthand()}'] {
      'consentId': systemName,
      title,
      description,
      partners[]->{
        name,
        description
      }
}`

  return await sanityClient.fetch<MarketingConsent[]>(query)
}
