import {
  ContentGrid,
  type IAction,
  type IComponent,
  type IComponentTemplate,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { ActionButton, Card } from '../..'
import {
  type ButtonTypes,
  LocationNavigationInfoBoxTemplate,
  MS_ButtonTemplate,
  h4TextPrefab,
  paragraphTextPrefab
} from '../../../Prefabs'
import { useApplicationContext } from '../../../contexts'
import './LocationNavigationInfoBox.scss'

export interface ILocationNavigationInfoBoxButton extends IAction {
  type: 'link' | 'close'
  buttonStyle: ButtonTypes
}

export interface ILocationNavigationInfoBox extends IComponent {
  title?: string
  text?: string
  buttons?: ILocationNavigationInfoBoxButton[]
  fadeTiming?: number
  fadingInInfoBox: boolean
  displayingInfoBox: boolean
  closeButtonOnClickFunc?: () => void
  template?: IComponentTemplate
}

/**
 *    The InfoBox is used to show information about the page, it appears under the navigation, and
 *    @type {string} title
 *    @type {string} text
 *    @type {ILocationNavigationInfoBoxButton[]} buttons - buttons in the bottom of the infobox
 *    @type {number} fadeTiming - seconds that it takes to fade the box
 *    @type {boolean} fadingInInfoBox - sets css-class for fading in/out the box (sets opacity to 1)
 *    @type {boolean} displayingInfoBox - sets css-class for displaying the box (hides/shows it) - is different to fadingInInfoBox, because this needs to happen after a delay (which is fadeTiming)
 *    @type {Function} closeButtonOnClickFunc - optional - if a button set with type 'close' it runs this function.
 */
export const LocationNavigationInfoBox = (props: ILocationNavigationInfoBox) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const {
    title,
    text,
    buttons,
    fadingInInfoBox,
    displayingInfoBox,
    closeButtonOnClickFunc,
    fadeTiming = undefined,
    template = LocationNavigationInfoBoxTemplate(activeTheme)
  } = props
  const { desktopView } = useApplicationContext()
  const classPrefix = 'location-navigation-info-box'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderButtons = () => {
    return buttons?.map((button: ILocationNavigationInfoBoxButton) => {
      return (
        <ActionButton
          key={uuid4()}
          template={MS_ButtonTemplate(activeTheme, button.buttonStyle)}
          padding='medium'
          action={{
            ...button,
            onClick: () => {
              if (button.type === 'close' && closeButtonOnClickFunc) closeButtonOnClickFunc()
            }
          }}
        />
      )
    })
  }

  // ************************************
  // Render
  // ************************************
  return (
    <Card
      className={classnames(`${classPrefix}`, {
        [`${classPrefix}--fadingIn`]: fadingInInfoBox,
        [`${classPrefix}--hide`]: !displayingInfoBox
      })}
      style={fadeTiming ? { transition: `${fadeTiming}s` } : undefined}
      theme={activeTheme}
      brand={activeBrand}
      template={template}
    >
      {title && (
        <Text
          className={`${classPrefix}__title`}
          {...h4TextPrefab()}
          align={desktopView ? 'align-center' : 'align-left'}
        >
          {title}
        </Text>
      )}
      {text && (
        <Text
          className={`${classPrefix}__text`}
          {...paragraphTextPrefab()}
          align={desktopView ? 'align-center' : 'align-left'}
        >
          {text}
        </Text>
      )}
      {buttons && (
        <ContentGrid
          className={`${classPrefix}__navigation`}
          alignment={desktopView ? 'center' : 'center-left'}
          direction='row'
          gap={2}
          tagType={'nav'}
          boxSizing='border-box'
        >
          {_renderButtons()}
        </ContentGrid>
      )}
    </Card>
  )
}
