import {
  type Alignment,
  ContentGrid,
  type IComponent,
  IconType,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useState } from 'react'
import { LinkButtonTemplate, h3TextPrefab } from '../../Prefabs'
import { ActionButton, type IDescriptionPoint, MSRichText, ServicePointsCard } from '../../components'
import { useApplicationContext } from '../../contexts'
import { Constants } from '../../data'
import type { IDataPoint, IPage } from '../../models'
import { getText } from '../../services'
import './DescriptionBlock.scss'

export interface IDescriptionBlock extends IComponent {
  blockId?: string
  title?: string
  description: string
  points?: IDescriptionPoint[]
  placement?: Alignment
  translations?: IPage
  useExpandableText?: boolean
  dataPoints?: IDataPoint[]
  descriptionText?: string
}

export const DescriptionBlock = (props: IDescriptionBlock) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    theme = 'Light',
    brand = Constants.uiBrand,
    title,
    description,
    className,
    translations,
    points,
    useExpandableText = true
  } = props
  const { desktopView } = useApplicationContext()
  const classPrefix = 'description-block'

  // ************************************
  // Lifecycle
  // ************************************

  const [showMore, setShowMore] = useState<boolean>(!useExpandableText)
  const [showExpandButton, setShowExpandButton] = useState<boolean>(false)

  // ************************************
  // Render Functionality
  // ************************************

  const _renderDescription = () => {
    return (
      <ContentGrid
        className={classnames(`${classPrefix}__content`, {
          [`${classPrefix}__content--large`]: points && points?.length > 0
        })}
        direction='column'
        alignment='top-left'
        tagType='section'
        boxSizing='border-box'
        gap={desktopView ? 2 : 1}
        scaling={points ? 3 : 0}
      >
        {title && (
          <Text
            className={`${classPrefix}__content__title`}
            {...h3TextPrefab()}
            theme={getCounterTheme(theme)}
            brand={brand}
          >
            {title}
          </Text>
        )}
        {description && (
          <MSRichText
            className={classnames(`${classPrefix}__content__description`, {
              [`${classPrefix}__content__description--hide`]: !showMore && useExpandableText
            })}
            theme={getCounterTheme(theme)}
            text={`${description}`}
            alignment='lawful'
            handleOnRender={rect => {
              if (rect) {
                setShowExpandButton(rect.scrollHeight > rect.clientHeight)
                setShowMore(rect.scrollHeight < rect.clientHeight)
              }
            }}
          />
        )}
        {useExpandableText && showExpandButton && translations && (
          <ActionButton
            className={`${classPrefix}__button`}
            brand={brand}
            template={LinkButtonTemplate(theme, false)}
            action={{
              text: showMore ? getText('showLess', translations) : getText('readMore', translations),
              icon: showMore ? IconType.ChevronUp : IconType.ChevronDown,
              onClick: () => {
                setShowMore(!showMore)
              }
            }}
          />
        )}
      </ContentGrid>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classnames(classPrefix, {
        [`${classPrefix}--large`]: points && points.length > 0,
        [`${className}`]: className
      })}
      direction='row'
      alignment='top-left'
      boxSizing='border-box'
      gap={points ? 2 : 0}
      spaceBetween
    >
      {_renderDescription()}
      {points && <ServicePointsCard theme={theme} brand={brand} points={points} />}
    </StyleGrid>
  )
}
