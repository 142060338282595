import { ContentGrid, type IImage, Image, StyleGrid, type Theme } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useParams } from 'react-router'
import { v4 as uuid4 } from 'uuid'
import {
  DescriptionBlock,
  DetailsDescriptionBlock,
  HighlightedDropdownBlock,
  type IDescriptionBlock,
  type IDetailsDescriptionBlock,
  type IHighlightedDropdownBlock,
  type IPlankHouse,
  type ISectionBlock,
  PitchCardsBlock,
  PlankHouseBlock,
  SectionBlock
} from '../../../blocks'
import {
  useAddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useSubPageLayoutContext
} from '../../../contexts'
import { Constants } from '../../../data'
import type { IServiceManagementPopup } from '../../../modals'
import type { IServicePitchCard } from '../../../models'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import './ServiceBasePage.scss'
import type { ServicePageDataPitchCards } from './Datahandling'

export interface IServiceBasePageView extends IDefaultViewProps {
  localBrand: string
  banner: IImage
  house?: IPlankHouse
  sections?: ISectionBlock[]
  pitchCards?: {
    items: IServicePitchCard[]
    title?: string
    description?: string
  }
  coreDetails?: IDetailsDescriptionBlock[]
  popup?: IServiceManagementPopup
  dropOrder?: IHighlightedDropdownBlock
  descriptions?: {
    top?: IDescriptionBlock[]
    middle?: IDescriptionBlock[]
    bottom?: IDescriptionBlock[]
  }
}

export interface IServiceBasePage {
  handleData: (config: any) => Promise<any>
}

// ************************************
// Render Functionality
// ************************************

export const RenderPitchCards = (pitchCards: ServicePageDataPitchCards | undefined, brand: string, theme: Theme) => {
  if (pitchCards?.items && pitchCards.items.length > 0) {
    return (
      <PitchCardsBlock
        theme={theme}
        brand={brand}
        items={pitchCards.items}
        title={pitchCards.title}
        description={pitchCards.description}
      />
    )
  }
}

export const RenderSections = (sections: ISectionBlock[] | undefined, brand: string, classPrefix: string) => {
  if (sections && sections.length > 0) {
    return (
      <StyleGrid className={`${classPrefix}__sections`} direction='column' alignment='top-center'>
        {sections?.map((section: any) => {
          return <SectionBlock key={uuid4()} {...section} brand={brand} />
        })}
      </StyleGrid>
    )
  }
}

export const RenderDescription = (
  classPrefix: string,
  brand?: string,
  theme?: Theme,
  descriptions?: IDescriptionBlock[]
) => {
  if (descriptions && descriptions.length > 0) {
    return descriptions.map((data: any) => {
      if (data.blockId !== Constants.services.dynamicInfoDetailsId) {
        return (
          <DescriptionBlock
            {...data}
            key={uuid4()}
            className={`${classPrefix}__description-block`}
            description={data.descriptionText}
            points={data.dataPoints}
            theme={theme}
            brand={brand}
            useExpandableText={false}
          />
        )
      }
      return undefined
    })
  }
}

export const RenderCoreDetails = (
  coreDetails: IDetailsDescriptionBlock[] | undefined,
  brand: string,
  theme: Theme,
  classPrefix: string
) => {
  if (coreDetails && coreDetails.length > 0) {
    return coreDetails.map((details: IDetailsDescriptionBlock) => {
      return (
        <DetailsDescriptionBlock
          {...details}
          key={uuid4()}
          className={`${classPrefix}__details-description-block`}
          theme={theme}
          brand={brand}
        />
      )
    })
  }
}

export const ServiceBasePage = (props: IServiceBasePage) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'service-page'
  const { handleData } = props
  const { setShowInstallationSelector } = useSubPageLayoutContext()
  const { GETTYPED } = useApplicationServicehandlerContext()
  const { serviceId } = useParams()
  const { setInstallation } = useApplicationCoreDataContext()
  const { addonStates } = useAddonServicesContext()

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.services.type,
      subPageType: serviceId,
      usesSubPage: true,
      pageClamp: 'stretch'
    },
    dependencies: [serviceId, { addonStates }, { GETTYPED }],
    dependenciesOptional: [{ setInstallation }],
    handleData,
    onDataChange: () => {
      setShowInstallationSelector(false)
    },
    render: (config: IServiceBasePageView) => {
      const { banner, desktopView, activeTheme, activeBrand, localBrand, house, dropOrder, coreDetails } = config

      return (
        <StyleGrid className={classPrefix} alignment='top-center' direction='column'>
          <ContentGrid
            className={`${classPrefix}__introduction`}
            direction='column'
            alignment='top-center'
            tagType='section'
            gap={4}
          >
            {RenderDescription(classPrefix, localBrand, activeTheme, config.descriptions?.top)}
            {banner?.src && (
              <Image
                className={classNames(`${classPrefix}__banner`, {
                  [`${classPrefix}__banner--curve`]: desktopView
                })}
                scalingBy='height'
                fit={'cover'}
                {...banner}
              />
            )}
            {RenderCoreDetails(coreDetails, activeBrand, activeTheme, classPrefix)}
          </ContentGrid>
          <StyleGrid className={`${classPrefix}__main`} direction='column' alignment='top-center' gap={4}>
            <ContentGrid
              className={`${classPrefix}__main__description-container`}
              direction='column'
              alignment='top-center'
              gap={desktopView ? 8 : 4}
              tagType='article'
            >
              {RenderDescription(classPrefix, localBrand, activeTheme, config.descriptions?.middle)}
              {RenderPitchCards(config.pitchCards, localBrand, activeTheme)}
              {RenderDescription(classPrefix, localBrand, activeTheme, config.descriptions?.bottom)}
            </ContentGrid>
            {RenderSections(config.sections, config.localBrand, classPrefix)}
            {house && house?.plankWalls?.length > 0 && (
              <PlankHouseBlock className={`${classPrefix}__main__house`} theme={activeTheme} {...house} />
            )}
            {dropOrder && <HighlightedDropdownBlock {...dropOrder} theme={activeTheme} brand={activeBrand} />}
          </StyleGrid>
        </StyleGrid>
      )
    }
  })
}
