import {
  type BrandColors,
  ContentGrid,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useMemo } from 'react'
import { paragraphTextPrefab } from '../../Prefabs'
import { ColumnChartCard, type IColumnChartCard } from '../../components'
import type { ILegend } from '../../components/Legend/Legend'
import { useApplicationContext } from '../../contexts'
import { Constants } from '../../data'
import type { IBlock, IPage } from '../../models'
import { capitalizeFirstLetter, getText, tNumber } from '../../services'
import './ColumnChartBlock.scss'

export interface IColumnChartBlock extends IBlock {
  translations: IPage
  chartColors: BrandColors[]
  chartData: IColumnChartCard
}

export const ColumnChartBlock = (props: IColumnChartBlock) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'column-chart-block'
  const { id, theme = 'Light', brand = Constants.uiBrand, className, translations, chartColors, chartData } = props
  const { desktopView } = useApplicationContext()

  // ************************************
  // Helper Functionality
  // ************************************

  const _getLegends = (page: IPage) => {
    const legends: ILegend[] = []

    legends.push({
      text: getText('series1Name', page),
      color: chartColors[0],
      size: 0.7
    } as ILegend)

    return legends
  }

  // ************************************
  // Render Functionality
  // ************************************

  const renderChartFallback = () => {
    return (
      <ContentGrid
        tagType='section'
        className={`${classPrefix}__fallback`}
        brand={brand}
        direction='column'
        alignment='center'
      >
        <Text brand={brand} theme={getCounterTheme(theme)} {...paragraphTextPrefab()}>
          {getText('noData', translations)}
        </Text>
      </ContentGrid>
    )
  }

  const _renderChart = useMemo(() => {
    if (chartData && translations) {
      return (
        <ColumnChartCard
          {...chartData}
          id={id}
          theme={theme}
          brand={brand}
          className={`${classPrefix}__chart`}
          series={chartData.series}
          max={chartData.max}
          legends={_getLegends(translations)}
          categories={[]}
          stack={true}
          fallback={{
            show: chartData.fallback?.show ?? false,
            content: renderChartFallback()
          }}
          chartTooltip={{
            show: true,
            x: {
              formatter: (value: any) => {
                return capitalizeFirstLetter(value)
              }
            },
            y: {
              formatter: (value: any) => {
                return `${tNumber(value, 'no-NO', 2)} ${getText('currency', translations)}`
              },
              suffix: ` ${getText('valueSuffix', translations)}`,
              showOnAxisLabels: true
            }
          }}
        />
      )
    }
  }, [chartData, desktopView, translations, brand, theme])

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      alignment='top-left'
      direction='column'
      gap={2}
    >
      <ContentGrid
        className={classNames(`${classPrefix}__chart-wrapper`, {
          [`${classPrefix}__chart-wrapper__background-container`]: !chartData,
          [`${classPrefix}__chart-wrapper__background-container--${theme}`]: theme && !chartData
        })}
        tagType='section'
        direction='column'
        alignment='center'
      >
        {_renderChart}
        {translations && !chartData && (
          <Text {...paragraphTextPrefab()} theme={getCounterTheme(theme)}>
            {getText('noDataFound', translations)}
          </Text>
        )}
      </ContentGrid>
    </StyleGrid>
  )
}
