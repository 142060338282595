import {
  type IIconAdvanced,
  type IImage,
  type IMasterPlank,
  type ITextCustomization,
  Icon,
  type IconType,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import './ImagePlankPrefab.scss'

export interface IImagePlankPrefabCustomization {
  title?: ITextCustomization
  description?: ITextCustomization
  icon?: IIconAdvanced
}

export interface IImagePlankPrefab extends IMasterPlank {
  left?: {
    title?: string
    description?: string
    image?: IImage
    customization?: IImagePlankPrefabCustomization
  }
  right?: {
    title?: string
    description?: string
    icon?: IconType
    customization?: IImagePlankPrefabCustomization
  }
}

export const ImagePlankPrefab = (props: IImagePlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand = 'brand-fjordkraft', left, right, template } = props
  const classPrefix = 'ms-plank'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderLeftImage = () => {
    if (left?.image) {
      return [
        <img
          key={uuid4()}
          className={`${classPrefix}__left__image`}
          src={left.image.src}
          alt={left.image.alt}
          width={'2.5rem'}
          height={'2.5rem'}
        />
      ]
    }
  }

  const _rightIcon = () => {
    if (right?.icon) {
      return [
        <Icon
          key={uuid4()}
          type={right.icon}
          theme={theme}
          brand={brand}
          width={right.customization?.icon?.size ?? 1.5}
          height={right.customization?.icon?.size ?? 1.5}
        />
      ]
    }
  }

  const _renderLeftTitle = () => {
    if (left?.title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          align={'align-left'}
          {...left.customization?.title}
        >
          {left.title}
        </Text>
      )
    }
  }

  const _renderLeftDescription = () => {
    if (left?.description) {
      return (
        <Text
          brand={brand}
          weight={400}
          size={'small'}
          family={'main'}
          type={'p'}
          faded
          align={'align-left'}
          {...left.customization?.description}
        >
          {left.description}
        </Text>
      )
    }
  }

  const _renderLeft = () => {
    if (left?.title || left?.description) {
      return [_renderLeftTitle(), _renderLeftDescription()]
    }
  }

  const _renderRightTitle = () => {
    if (right?.title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          align={'align-right'}
          {...right.customization?.title}
        >
          {right.title}
        </Text>
      )
    }
  }

  const _renderRightDescription = () => {
    if (right?.description) {
      return (
        <Text
          brand={brand}
          weight={400}
          size={'small'}
          family={'main'}
          align={'align-right'}
          type={'p'}
          {...right.customization?.description}
        >
          {right.description}
        </Text>
      )
    }
  }

  const _renderRight = () => {
    if (right?.title || right?.description) {
      return [_renderRightTitle(), _renderRightDescription()]
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    template,
    leftColumn1: _renderLeftImage(),
    leftColumn2: _renderLeft(),
    rightColumn1: _renderRight(),
    rightColumn2: _rightIcon()
  } as IMasterPlank
}
