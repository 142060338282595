import { Icon, IconType, Text } from '@fjordkraft/fjordkraft.component.library'
import { useMemo, useState } from 'react'
import { AlertCardTemplate, MS_ButtonTemplate } from '../../Prefabs'
import { AlertCard, MsButton } from '../../components'
import { useApplicationContext, useApplicationCoreDataContext } from '../../contexts'
import type { ICustomerInstallation } from '../../models'
import { IsActiveCustomer } from '../../services'
import { AccessDeniedPage } from '../AccessDeniedPage/AccessDeniedPage'
import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'
import type { IPageData } from '../PageV2'
import './BenefitsPage.scss'

export interface IBenefitPage extends IPageData {
  summary: {
    amount: number
    hasOnboarded: boolean
    expiringPoints?: {
      points: number
      date: string
    }
  }
  latestKickbackTransaction: any
  kickbackNotification: {
    transaction: any
    title: string
    points: string
  }
}

export const BenefitsPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { installation } = useApplicationCoreDataContext()
  const { activeTheme, activeBrand } = useApplicationContext()
  const classPrefix = 'benefits-page'

  // ************************************
  // Lifecycle
  // ************************************

  const [kickbackNotification, setKickbackNotification] = useState<any>()

  // ************************************
  // Access Functionality
  // ************************************

  const _benefitsAllowed = (inst?: ICustomerInstallation): boolean => {
    if (IsActiveCustomer(inst)) {
      return true
    }
    return false
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderNotificationAlert = useMemo(() => {
    if (kickbackNotification?.transaction && _benefitsAllowed(installation)) {
      return (
        <AlertCard template={AlertCardTemplate(activeTheme, 'success')}>
          <Icon
            className={`${classPrefix}__notification-icon`}
            type={IconType.SuccessBubble}
            width={1.5}
            height={1.5}
          />
          <Text
            className={`${classPrefix}__notification-text`}
            type={'p'}
            size={'small'}
            weight={500}
            brand={activeBrand}
          >
            {kickbackNotification.title}
          </Text>
          <Text
            className={`${classPrefix}__notification-text`}
            type={'p'}
            size={'small'}
            weight={500}
            brand={activeBrand}
          >
            {`${kickbackNotification.transaction.message}: ${kickbackNotification.transaction.amount} ${kickbackNotification.points}`}
          </Text>
          <MsButton
            template={MS_ButtonTemplate(activeTheme)}
            action={{
              onClick: () => {
                setKickbackNotification(null)
              }
            }}
          >
            <Icon type={IconType.Close} />
          </MsButton>
        </AlertCard>
      )
    }
  }, [activeBrand, activeTheme, kickbackNotification, installation])

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {_benefitsAllowed(installation) && <SubPageLayout>{_renderNotificationAlert}</SubPageLayout>}
      {!_benefitsAllowed(installation) && <AccessDeniedPage accessTo={'Fordel'} />}
    </>
  )
}
