import type { IComponent, IComponentTemplate } from '@fjordkraft/fjordkraft.component.library'
import { AlertCardTemplate } from '../../../Prefabs'
import { Card } from '../Card'
import './AlertCard.scss'

export interface IAlertCard extends IComponent {
  template: IComponentTemplate
}

export const AlertCard = (props: IAlertCard) => {
  // ************************************
  // Properties
  // ************************************

  const { id, children, brand, theme = 'Light', template = AlertCardTemplate(theme) } = props
  const classPrefix = 'alert-card'

  // ************************************
  // Render
  // ************************************

  return (
    <Card id={id} brand={brand} className={`${classPrefix}`} template={template}>
      {children}
    </Card>
  )
}
