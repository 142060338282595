import {
  BrandColors,
  type CallState,
  type IRadioPlankSelectForm,
  type IRadioSelectItem,
  IconType,
  MasterPlank,
  RadioPlankSelectForm,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { h4TextPrefab, paragraphTextPrefab, smallParagraphTextPrefab } from '../../../Prefabs'
import { DynamicEpiContentBlock, type IPlankHouse, PlankHouseBlock } from '../../../blocks'
import { TipCard } from '../../../components'
import {
  useApplicationAccountSelectContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext
} from '../../../contexts'
import { Constants } from '../../../data'
import { DirectDebitModal } from '../../../modals'
import type { ICustomer, InvoiceDeliveryMethodType } from '../../../models'
import { type IResponse, capitalizeFirstLetter, getText } from '../../../services'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import './InvoiceSettingsPage.scss'
import { CallStateToast } from '../../../components/Toastify/Toastify'
import { getPageContent } from './InvoiceSettingsPageData'

export type DirectDebitStatus = 'None' | 'Active' | 'Pending' | 'Rejected' | 'Cancelled'

export interface IInvoiceSettingsPage extends IDefaultViewProps {
  invoiceSettings: IPlankHouse
  hasSteddi: boolean
  deliveryMethodOptions: {
    options: IRadioSelectItem[]
    selectedIndex: number
  }
  invoiceAddress: IPlankHouse
}

export const InvoiceSettingsPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'invoice-settings-page'
  const { setIncludeAllOption } = useApplicationAccountSelectContext()
  const { activeAccount } = useApplicationDefaultContext()
  const { updateCustomerData, setCustomerDataToEdit } = useApplicationCoreDataContext()
  const location = useLocation()

  // ************************************
  // Lifecycle
  // ************************************

  const [deliveryMethodCallState, setDeliveryMethodCallState] = useState<CallState>('idle')
  const [showDirectDebitPopup, setShowDirectDebitPopup] = useState<boolean>(false)

  // ************************************
  // Handlers
  // ************************************

  const _updateDeliveryMethod = async (
    accountNumber: string,
    method: InvoiceDeliveryMethodType,
    config: IInvoiceSettingsPage
  ): Promise<IResponse> => {
    const { services } = config
    const { PUT } = services

    setDeliveryMethodCallState('pending')
    let resp: IResponse

    try {
      resp = await PUT(`Account/${accountNumber}/deliverymethod/${method}`)

      if (resp.callState === 'success') {
        await updateCustomerData(true)
        setDeliveryMethodCallState('idle')
        _handleToasts(resp.callState, 'deliveryMethod', config)
      } else if (resp.callState === 'error' || resp.callState === 'aborted') {
        setDeliveryMethodCallState('idle')
        throw Error(`deliveryMethod: ${resp.callState}`)
      }
      return resp
    } catch (e) {
      setDeliveryMethodCallState('idle')
      throw Error(`deliveryMethod: ${e}`)
    }
  }

  const _handleToasts = (callState: CallState, type: string, config: IInvoiceSettingsPage) => {
    const { translations } = config
    CallStateToast({
      text: getText(`update${type}${capitalizeFirstLetter(callState)}`, translations),
      callState: callState
    })
  }

  // ************************************
  // Helpers
  // ************************************

  const _hasAccounts = (userData: ICustomer) => {
    return userData.accounts.find(e => e)
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderTipCard = (config: IInvoiceSettingsPage) => {
    const { translations } = config

    return (
      <TipCard
        brand={'brand-steddi'}
        iconType={IconType.InfoBubble}
        iconColor={BrandColors['primary-shade-light-2']}
        title={getText('invoiceSteddiInfoBoxTitle', translations)}
        content={getText('invoiceSteddiInfoBoxContent', translations)}
      />
    )
  }

  const _renderDeliverySelection = (config: IInvoiceSettingsPage) => {
    const { translations, activeBrand, activeTheme, user, relationship, deliveryMethodOptions, hasSteddi } = config
    const { userData } = user
    const { isGuest } = relationship

    if (userData && activeAccount?.deliveryMethod && translations) {
      if (hasSteddi) {
        return _renderTipCard(config)
      }
      const selectForm: IRadioPlankSelectForm = {
        items: deliveryMethodOptions.options,
        initialActiveIndex: deliveryMethodOptions.selectedIndex,
        onChange: () => {},
        asyncOnChange: async (value: any) => {
          await _updateDeliveryMethod(activeAccount.accountId, value.id, config)
        }
      }

      return (
        <RadioPlankSelectForm
          {...selectForm}
          disabled={deliveryMethodCallState !== 'idle' || isGuest}
          brand={activeBrand}
          theme={activeTheme}
        />
      )
    }
    if (!activeAccount?.deliveryMethod && translations) {
      return (
        <Text
          {...smallParagraphTextPrefab()}
          // disable eslint since Text-component actually takes prop "style" as string

          style='italic'
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          {getText('deliveryMethodError', translations)}
        </Text>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.invoiceSettings.type,
      usesSubPage: true
    },
    dependencies: [
      { activeAccount },
      { setShowDirectDebitPopup },
      { location },
      { showDirectDebitPopup },
      { deliveryMethodCallState },
      { setCustomerDataToEdit }
    ],
    handleData: getPageContent,
    startedRender: () => {
      setIncludeAllOption(false)
    },
    render: (config: IInvoiceSettingsPage) => {
      const { translations, activeBrand, activeTheme, user, invoiceSettings, invoiceAddress } = config
      const { userData } = user

      return (
        <StyleGrid className={`${classPrefix}`} direction='column' alignment='top-center' gap={4}>
          {translations.topEditorialContent && (
            <DynamicEpiContentBlock
              theme={activeTheme}
              brand={activeBrand}
              epiItems={translations.topEditorialContent}
            />
          )}
          <PlankHouseBlock
            className={`${classPrefix}__house`}
            brand={activeBrand}
            theme={activeTheme}
            {...invoiceSettings}
          />
          <StyleGrid
            className={`${classPrefix}__plankhouse`}
            direction='column'
            alignment='top-left'
            gap={2}
            boxSizing='border-box'
          >
            <Text
              {...h4TextPrefab()}
              className={`${classPrefix}__plankhouse__title`}
              theme={getCounterTheme(activeTheme)}
              brand={activeBrand}
              weight={700}
            >
              {getText('deliveryTypeTitle', translations)}
            </Text>
            {!_hasAccounts(userData) && (
              <Text {...paragraphTextPrefab()} theme={getCounterTheme(activeTheme)} brand={activeBrand}>
                {getText('invoicesMissingText', translations)}
              </Text>
            )}
            {_renderDeliverySelection(config)}
          </StyleGrid>
          {showDirectDebitPopup && activeAccount && (
            <DirectDebitModal
              translations={translations}
              account={activeAccount}
              onClose={() => {
                setShowDirectDebitPopup(false)
              }}
            />
          )}

          <StyleGrid
            className={`${classPrefix}__plankhouse`}
            direction='column'
            alignment='top-left'
            gap={2}
            boxSizing='border-box'
          >
            <Text
              {...h4TextPrefab()}
              className={`${classPrefix}__plankhouse__title`}
              theme={getCounterTheme(activeTheme)}
              brand={activeBrand}
            >
              {getText('changeInvoiceAddressTitle', translations)}
            </Text>
            <MasterPlank leftColumn1={[invoiceAddress]} />
          </StyleGrid>
        </StyleGrid>
      )
    }
  })
}
