import {
  BrandColors,
  ContentGrid,
  type IAction,
  type IComponent,
  type IComponentTemplate,
  type IIcon,
  type ITextCustomization,
  Icon,
  IconType,
  StyleGrid,
  Text,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { v4 as uuid4 } from 'uuid'
import { ListedButtonTemplate, MS_BareDropdownTemplate, MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs'
import {
  ActionButton,
  AsyncRedirectButton,
  BareDropdown,
  HeaderLogoButton,
  LogoutButton,
  MenuButton,
  MobileSelectMenu
} from '../../components'
import {
  useApplicationAddonServicesContext,
  useApplicationContext,
  useApplicationCoreDataContext
} from '../../contexts'
import { Constants } from '../../data'
import type { ICustomerInstallation, IHomePage } from '../../models'
import { IsActiveCustomer, createString, getText, isExternalUrl } from '../../services'
import './Header.scss'
import { Link } from 'react-router-dom'

export interface IHeader extends IComponent {
  rootPageData: IHomePage
  navigating: (path: string) => void
}

export interface IServiceMenuOptions extends IAction {
  iconColor: BrandColors
  serviceActive: boolean
}

export const Header = (props: IHeader) => {
  // ************************************
  // Properties
  // ************************************

  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { rootPageData, navigating } = props
  const navigate = useNavigate()
  const { userData, installation, translation } = useApplicationCoreDataContext()
  const { availableServicePages } = useApplicationAddonServicesContext()
  const classPrefix = 'header'

  // ************************************
  // Lifecycle
  // ************************************

  const [activeNavigationElementId, setActiveNavigationElementId] = useState<string>()
  const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false)
  const [goToMobileText, setGoToMobileText] = useState<string>('')

  useEffect(() => {
    if (translation?.pageNavigationFocus && activeNavigationElementId !== translation?.pageNavigationFocus) {
      setActiveNavigationElementId(translation.pageNavigationFocus)
    }
  }, [translation])

  useEffect(() => {
    if (userData) {
      if (userData.isMobileCustomer) {
        setGoToMobileText(getText('goToMobileLinkTextCustomer', rootPageData))
      } else {
        setGoToMobileText(getText('goToMobileLinkTextNonCustomer', rootPageData))
      }
    }
  }, [userData])

  // ************************************
  // Helpers
  // ************************************

  const _allowMenuButton = (data: any, inst?: ICustomerInstallation) => {
    if (!IsActiveCustomer(inst)) {
      if (data.blockId === 'Benefits') {
        return false
      }
    }

    return true
  }

  const _getMobileNav = () => {
    const totalLength: number = rootPageData.headerNav.length
    const items = []
    const headerNavItems = rootPageData.headerNav
    const textCustomization = {
      text: {
        size: 'large'
      } as ITextCustomization
    }

    if (userData?.customerId && headerNavItems.length > 0) {
      headerNavItems.forEach((data: any, index: number) => {
        const buttonAction: IAction = {
          text: data.text,
          icon: undefined,
          active: activeNavigationElementId === data.blockId,
          useRouterLink: !isExternalUrl(data.url),
          overrideTagEnforcer: true,
          onClick: () => {
            navigate(data.url ?? '/')
            setToggleMobileMenu(false)
          }
        }

        if (_allowMenuButton({ ...data, ...buttonAction }, installation)) {
          if (data.blockId === 'Services') {
            const activeServicesCount = serviceMenuOptions.filter((e: any) => e.serviceActive).length
            items.push(
              <DropDownMobile
                key={uuid4()}
                brand={activeBrand}
                theme={activeTheme}
                className={classPrefix}
                navigating={navigating}
                serviceMenuOptions={serviceMenuOptions}
                text={data.text}
                buttonTemplate={ListedButtonTemplate(activeTheme, index, totalLength + 1)}
                customization={textCustomization}
                onClick={setToggleMobileMenu}
                mainButtonTextRight={createString(getText('headerNavActiveServicesCount', rootPageData), {
                  activeServices: activeServicesCount,
                  totalServices: serviceMenuOptions.length - 1
                })}
              />
            )
          } else {
            items.push(
              <ActionButton
                key={uuid4()}
                customization={textCustomization}
                action={buttonAction}
                brand={activeBrand}
                template={ListedButtonTemplate(activeTheme, index, totalLength + 1)}
              />
            )
          }
        }
      })
    }
    items.push(
      <AsyncRedirectButton
        key={uuid4()}
        text={goToMobileText}
        icon={IconType.SmartPhone}
        addition={'Redirect/mobile'}
        flipped={true}
        template={ListedButtonTemplate(activeTheme, items.length, totalLength + 2)}
        customization={{
          ...textCustomization,
          icon: {
            width: 1.5,
            height: 1.5
          }
        }}
      />
    )
    items.push(
      <LogoutButton
        key={uuid4()}
        theme={activeTheme}
        brand={activeBrand}
        text={getText('logoutText', rootPageData)}
        icon={IconType.LogoutIcon}
        flipped={true}
        template={ListedButtonTemplate(activeTheme, items.length, totalLength + 1)}
        customization={{
          ...textCustomization,
          icon: {
            width: 1.5,
            height: 1.5
          }
        }}
      />
    )
    return [{ items }]
  }

  const serviceMenuOptions = useMemo(() => {
    const data = rootPageData.headerNav.find(e => e.blockId === 'Services')
    let services: IServiceMenuOptions[] = []
    if (data) {
      const sortStatus = (a: any) => (a.serviceStatus.state === 'ACTIVE' ? 0 : 1)
      const sortedAddonStates = [...(availableServicePages ?? [])].sort(
        (a: any, b: any) => sortStatus(a) - sortStatus(b)
      )
      services =
        sortedAddonStates.map((e: any) => ({
          text: e?.pitch?.title,
          link: `${data.url}/${e?.servicePageId}`,
          icon: e?.serviceStatus?.state === 'ACTIVE' ? IconType.CheckmarkRound : undefined,
          iconColor: BrandColors['secondary-shade-light-1'],
          serviceActive: e?.serviceStatus?.state === 'ACTIVE',
          useRouterLink: !isExternalUrl(`${data.url}/${e?.servicePageId}`)
        })) || []
      services.push({
        text: getText('headerNavSeeAllServices', rootPageData),
        link: data.url,
        icon: IconType.ArrowRight,
        iconColor: BrandColors['text-shade-dark-3'],
        serviceActive: false,
        useRouterLink: !isExternalUrl(data.url)
      })
    }
    return services
  }, [activeNavigationElementId, rootPageData.headerNav, availableServicePages])

  // ************************************
  // Render Functionality
  // ************************************

  const [isOpenServiceDropdown, setIsOpenServiceDropdown] = useState(false)

  const _renderHeaderNavigation = useMemo(() => {
    const nav: any[] = []

    if (userData?.customerId && rootPageData.headerNav.length > 0) {
      rootPageData.headerNav.forEach((data: any) => {
        const buttonAction: IAction = {
          link: data.url,
          text: data.text,
          icon: data.iconType,
          active: activeNavigationElementId === data.blockId,
          useRouterLink: !isExternalUrl(data.url)
        }

        if (_allowMenuButton({ ...data, ...buttonAction }, installation)) {
          if (data.blockId === 'Services') {
            nav.push(
              <BareDropdown
                key={uuid4()}
                text={data.text}
                id={data.text}
                gridPlacement={'bottom-left'}
                className={`${classPrefix}__dropDown`}
                template={{
                  ...MS_BareDropdownTemplate(activeTheme, desktopView),
                  dropdown: {
                    ...MS_ButtonTemplate(activeTheme, 'menu'),
                    transform: {
                      ...MS_ButtonTemplate(activeTheme, 'menu').transform,
                      padding: '0.5rem 1rem 0.5rem 1.5rem'
                    }
                  }
                }}
                active={data.blockId === activeNavigationElementId}
                isOpen={isOpenServiceDropdown}
                setIsOpen={setIsOpenServiceDropdown}
                icon={isOpenServiceDropdown ? IconType.ChevronUp : IconType.ChevronDown}
              >
                <DropDownList
                  id={data.text}
                  className={`${classPrefix}__dropDown__dropDownList`}
                  navigating={navigating}
                  elements={serviceMenuOptions}
                  onClickOnElement={() => setIsOpenServiceDropdown(false)}
                />
              </BareDropdown>
            )
          } else {
            nav.push(<MenuButton key={uuid4()} action={buttonAction} navigating={navigating} />)
          }
        }
      })
    }

    return nav
  }, [activeNavigationElementId, rootPageData, installation, availableServicePages, isOpenServiceDropdown])

  const _renderDesktop = () => {
    return (
      <ContentGrid className={classPrefix} direction='row' alignment='center' tagType='header'>
        <ContentGrid className={`${classPrefix}__left`} tagType={'nav'} direction='row' alignment='center' gap={1}>
          <HeaderLogoButton
            link={Constants.paths.energyPage ?? '/'}
            image={{
              src: rootPageData.logo ?? '',
              alt: 'minside logo'
            }}
          />
        </ContentGrid>
        <ContentGrid className={`${classPrefix}__center`} tagType={'nav'} direction='row' alignment='center' gap={1}>
          {_renderHeaderNavigation}
        </ContentGrid>
        <ContentGrid className={`${classPrefix}__right`} direction='row' alignment='center' tagType='nav' gap={3}>
          <AsyncRedirectButton text={goToMobileText} icon={IconType.SmartPhone} addition={'Redirect/mobile'} />
          <LogoutButton
            theme={activeTheme}
            brand={activeBrand}
            text={getText('logoutText', rootPageData)}
            icon={IconType.LogoutIcon}
          />
        </ContentGrid>
      </ContentGrid>
    )
  }

  const _renderMobile = () => {
    if (translation) {
      return (
        <StyleGrid
          className={`${classPrefix}__small-device`}
          direction='row'
          alignment='top-right'
          boxSizing='border-box'
        >
          <ActionButton
            brand={activeBrand}
            template={MS_ButtonTemplate(activeTheme, 'menu-icon')}
            customization={{
              icon: {
                width: 1.5,
                height: 1.5
              }
            }}
            action={{
              text: getText('menu', rootPageData),
              icon: IconType.MenuBurger,
              onClick: () => {
                setToggleMobileMenu(true)
              }
            }}
          />
          {toggleMobileMenu && (
            <MobileSelectMenu
              theme={activeTheme}
              brand={activeBrand}
              items={_getMobileNav()}
              onClose={() => {
                setToggleMobileMenu(false)
              }}
            />
          )}
        </StyleGrid>
      )
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderHeader = useMemo(() => {
    return (
      <>
        {desktopView && _renderDesktop()}
        {!desktopView && _renderMobile()}
      </>
    )
  }, [
    desktopView,
    activeBrand,
    activeTheme,
    installation,
    rootPageData,
    activeNavigationElementId,
    toggleMobileMenu,
    isOpenServiceDropdown,
    availableServicePages
  ])

  // ************************************
  // Render
  // ************************************

  return _renderHeader
}

interface IDropDownMobile {
  className: string
  brand: string
  theme: Theme
  navigating: (path: string) => void
  buttonTemplate: IComponentTemplate
  serviceMenuOptions: IServiceMenuOptions[]
  customization: {
    icon?: Omit<IIcon, 'type'>
    text: ITextCustomization
  }
  text: string
  onClick: (val: boolean) => void
  mainButtonTextRight: string
}

const DropDownMobile = (props: IDropDownMobile) => {
  const { brand, buttonTemplate, serviceMenuOptions, text, customization, onClick, mainButtonTextRight } = props
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const getTemplateWithIconColor = (template: IComponentTemplate, color: BrandColors) => {
    if (template?.palette?.icon?.color) {
      template.palette.icon.color = color
    }
    if (template?.palette?.hover?.icon?.color) {
      template.palette.hover.icon.color = color
    }
    if (template?.palette?.focus?.icon) {
      template.palette.focus.icon.color = color
    }
    return template
  }

  return (
    <>
      <ActionButton
        key={uuid4()}
        action={{
          icon: isOpen ? IconType.ChevronUp : IconType.ChevronDown,
          text: text,
          value: mainButtonTextRight,
          onClick: () => setIsOpen(!isOpen)
        }}
        brand={brand}
        template={buttonTemplate}
        customization={customization}
        iconPlacement={'End'}
      />

      {isOpen && (
        <StyleGrid alignment={'center'} direction={'column'} className={'dropDownMobile__items'}>
          {serviceMenuOptions.map((e: IServiceMenuOptions, i: number) => (
            <ActionButton
              iconPlacement={'Right'}
              key={e.link}
              action={{
                ...e,
                link: undefined,
                active: false,
                overrideTagEnforcer: true,
                onClick: () => {
                  navigate(e.link ?? '/')
                  onClick(false)
                }
              }}
              brand={brand}
              template={getTemplateWithIconColor(_.cloneDeep(buttonTemplate), e.iconColor)}
              customization={{
                ...customization,
                icon: { ...customization.icon, width: 1.8, height: 1.8 },
                text: { ...customization.text, ...(i < serviceMenuOptions.length - 1 && { weight: 400 }) }
              }}
            />
          ))}
        </StyleGrid>
      )}
    </>
  )
}

const DropDownList = ({
  elements,
  className,
  id,
  navigating,
  onClickOnElement
}: {
  elements: IServiceMenuOptions[]
  className: string
  id: string
  navigating: (path: string) => void
  onClickOnElement: () => void
}) => {
  const navigate = useNavigate()

  return (
    <StyleGrid direction={'column'} alignment={'top-left'} className={className} id={id}>
      {elements?.map(e => (
        <Link
          to={'..'}
          key={uuid4()}
          title={e.text}
          id={id}
          className={`${className}__button`}
          onClick={event => {
            event.preventDefault()
            if (e.link) {
              navigating(e.link)
            }
            navigate(e.link ?? '/')
            onClickOnElement()
          }}
        >
          <Text id={id} className={`${className}__button__text`} {...paragraphTextPrefab()}>
            {e.text}
          </Text>
          {e?.icon && (
            <Icon
              id={id}
              className={`${className}__button__icon`}
              type={e.icon}
              color={e.iconColor}
              width={1.2}
              height={1.2}
            />
          )}
        </Link>
      ))}
    </StyleGrid>
  )
}
