import {
  BrandColors,
  type IComponent,
  Icon,
  IconType,
  LoaderGraphic,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { LinkButtonTemplate, downloadLinkTextPrefab } from '../../../Prefabs'
import type { CallState, ICustomerInvoice } from '../../../models'
import { MsButton } from '../Button'
import './InvoicePdfButton.scss'
import { useApplicationServicehandlerContext } from '../../../contexts'

export interface IInvoicePdfButton extends IComponent {
  type?: 'clean' | 'action-primary'
  text: string
  url: string
  token: string
  invoice?: ICustomerInvoice
}

export const InvoicePdfButton = (props: IInvoicePdfButton) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, brand, theme = 'Light', text, url, invoice, type = 'clean' } = props
  const classPrefix = 'invoice-pdf-button'

  const { BLOB } = useApplicationServicehandlerContext()

  const getInvoicePdfName = () => {
    if (invoice) {
      const sender = invoice.sender ? `${invoice.sender}_` : ''
      const dateFormat = invoice.dueDate ? `_${format(new Date(invoice.dueDate), 'dd_MM_yyyy')}` : ''
      return `${sender}${text}${dateFormat}`
    }
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [callState, setCallState] = useState<CallState>('idle')

  useEffect(() => {
    switch (callState) {
      case 'error':
      case 'success': {
        const timer = setTimeout(() => {
          setCallState('idle')
        }, 3000)
        return () => clearTimeout(timer)
      }
    }
  }, [callState])

  // ************************************
  // Async Functionality
  // ************************************

  const fetchInvoice = async () => {
    setCallState('pending')
    const fetchDur = await BLOB(url, invoice ? getInvoicePdfName() : undefined)
    setCallState(fetchDur)
  }

  // ************************************
  // Helper Functionality
  // ************************************

  const getStateColors = () => {
    switch (callState) {
      case 'error':
        return BrandColors['status-shade-light-3']
      case 'success':
        return BrandColors['status-shade-light-1']
      case 'idle':
        return BrandColors['action-shade-light-1']
      case 'pending':
        return theme === 'Light' ? BrandColors['text-shade-light-3'] : BrandColors['text-shade-dark-1']
    }
  }

  const getStateIcon = () => {
    switch (callState) {
      case 'error':
      case 'aborted':
        return IconType.ErrorTriangle
      case 'success':
        return IconType.CheckmarkThick
      case 'idle':
      case 'pending':
        return IconType.PdfIcon
    }
  }

  const invoicePdfButtonTemplate = () => {
    const template = LinkButtonTemplate(theme)
    if (template.palette.border) template.palette.border = undefined
    return template
  }

  // ************************************
  // Render
  // ************************************

  return (
    <MsButton
      id={id}
      brand={brand}
      theme={theme}
      disabled={callState === 'pending'}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      action={{
        onClick: () => {
          fetchInvoice()
        }
      }}
      template={invoicePdfButtonTemplate()}
    >
      <StyleGrid className={`${classPrefix}__container`} direction={'row'} alignment={'top-left'} gap={1}>
        <StyleGrid alignment={'top-left'} direction={'column'}>
          {type === 'clean' && callState !== 'pending' && <Icon type={getStateIcon()} color={getStateColors()} />}
          {callState === 'pending' && (
            <LoaderGraphic
              theme={theme}
              brand={brand}
              thickness={'none'}
              scaleThickness={0.15}
              size={0.702} //exactly 16 pixels, because scaleThickness changes size
              colors={{
                backgroudColor: BrandColors['background-shade-light-2'],
                fillColor: BrandColors['action-shade-light-1']
              }}
            />
          )}
        </StyleGrid>
        <StyleGrid alignment={'top-left'} direction={'column'} scaling={1}>
          <Text
            {...downloadLinkTextPrefab()}
            brand={brand}
            theme={getCounterTheme(theme)}
            color={getStateColors()}
            underline={true}
          >
            {text}
          </Text>
        </StyleGrid>
      </StyleGrid>
    </MsButton>
  )
}
