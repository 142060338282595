import {
  BrandColors,
  FlexGridAligner,
  type IComponent,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useState } from 'react'
import { LinkButtonTemplate, MS_ButtonTemplate } from '../../../../Prefabs'
import { ActionButton, MsButton } from '../../../../components'
import { useApplicationContext, useApplicationCoreDataContext } from '../../../../contexts'
import { getText } from '../../../../services'
import './RecruitModal.scss'

export interface IRecruitModal extends IComponent {
  recruitLink?: string
  recruitText?: string
}

/**
 *    The RecruitModal is used to show recruitment ('verving') links, and buttons to copy them etc.
 *    @type {string} recruitText
 */
export const RecruitModal = (props: IRecruitModal) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { translation } = useApplicationCoreDataContext()
  const { id, recruitLink, recruitText } = props
  const classPrefix = 'recruit-modal'

  // ************************************
  // Lifecycle
  // ************************************

  const [copyAllButtonTitle, setCopyAllButtonTitle] = useState<string>(getText('recruitCopy', translation))
  const [copyOnlyLinkButtonTitle, setCopyOnlyLinkButtonTitle] = useState<string>(
    getText('recruitCopyOnlyRecruitLink', translation)
  )

  // ************************************
  // Functions
  // ************************************

  const copyToClipboard = (whichButton: string, text: string) => {
    navigator.clipboard.writeText(text)

    if (whichButton === 'all') {
      setCopyAllButtonTitle(getText('recruitTextCopied', translation))
      setTimeout(() => setCopyAllButtonTitle(getText('recruitCopy', translation)), 1000)
    }
    if (whichButton === 'link') setCopyOnlyLinkButtonTitle(getText('recruitLinkCopied', translation))
    setTimeout(() => setCopyOnlyLinkButtonTitle(getText('recruitCopyOnlyRecruitLink', translation)), 1000)
  }

  // ************************************
  // Render
  // ************************************
  return (
    <FlexGridAligner
      id={id}
      className={`${classPrefix}`}
      brand={activeBrand}
      theme={activeTheme}
      alignment={'center'}
      direction={'column'}
    >
      <FlexGridAligner className={`${classPrefix}__recruit-text`} alignment={'center-left'} direction={'column'}>
        <Text
          className={classnames(`${classPrefix}__recruit-text__text`, {
            [`${classPrefix}--${activeTheme}`]: getCounterTheme(activeTheme)
          })}
          type={'p'}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
          align={'align-left'}
        >
          {recruitText}
        </Text>
        <MsButton
          className={`${classPrefix}__recruit-text__recruit-link`}
          brand={activeBrand}
          theme={activeTheme}
          template={LinkButtonTemplate(activeTheme)}
          action={{ link: recruitLink }}
        >
          <Text
            className={`${classPrefix}__recruit-text__recruit-link__text`}
            type={'p'}
            color={BrandColors['action-shade-light-1']}
            wrap={'normal'}
          >
            {recruitLink}
          </Text>
        </MsButton>
      </FlexGridAligner>
      <FlexGridAligner className={`${classPrefix}__copy-buttons`} alignment={'center'} direction={'row'} gap={2}>
        <ActionButton
          className={`${classPrefix}__copy-buttons__copy-text`}
          template={MS_ButtonTemplate(activeTheme, 'primary')}
          brand={activeBrand}
          padding='medium'
          action={{
            onClick: () => copyToClipboard('all', `${recruitText}\n${recruitLink}`),
            text: copyAllButtonTitle
          }}
        />
        <ActionButton
          className={`${classPrefix}__copy-buttons__copy-link`}
          template={MS_ButtonTemplate(activeTheme, 'secondary')}
          brand={activeBrand}
          padding='medium'
          action={{
            onClick: () => {
              if (recruitLink) copyToClipboard('link', recruitLink)
            },
            text: copyOnlyLinkButtonTitle
          }}
        />
      </FlexGridAligner>
    </FlexGridAligner>
  )
}
