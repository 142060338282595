import { ContentGrid, type IAction, Text } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useApplicationContext } from '../../contexts'
import './ActionDescriptionBlock.scss'
import { MS_ButtonTemplate, h3TextPrefab, smallParagraphTextPrefab } from '../../Prefabs'
import { ActionButton } from '../../components'
import type { IBlock } from '../../models'

export interface IActionDescriptionBlock extends IBlock {
  title: string
  description: string
  action: IAction
}

export const ActionDescriptionBlock = (props: IActionDescriptionBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { activeBrand, activeTheme } = useApplicationContext()
  const { title, description, action, id, className } = props
  const classPrefix = 'action-description-block'

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      id={id}
      direction='column'
      alignment='top-center'
      tagType='section'
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      gap={2}
    >
      <Text {...h3TextPrefab()} align={'align-center'}>
        {title}
      </Text>
      <Text {...smallParagraphTextPrefab()} align={'align-center'}>
        {description}
      </Text>
      {action?.active && (
        <ActionButton
          template={MS_ButtonTemplate(activeTheme, 'primary')}
          brand={activeBrand}
          action={action}
          padding='default'
        />
      )}
    </ContentGrid>
  )
}
