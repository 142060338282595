import type { ContentLoader } from '@episerver/content-delivery'
import type { CallState } from '@fjordkraft/fjordkraft.component.library'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import {
  ApplicationCoreDataContext,
  useApplicationContext,
  useApplicationOverlayWrapperContext,
  useApplicationServicehandlerContext
} from '../../../contexts'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { ErrorModal, type ICustomerDataToEdit } from '../../../modals'
import type { ICustomer, ICustomerInstallation, IEpiPage, IError, IGuestRelationship } from '../../../models'
import {
  HomePageData,
  fetchHomePage,
  getInitiatedContentLoader,
  getText,
  logger,
  newrelicErrorLogger
} from '../../../services'

export function ApplicationCoreDataWrapper(props: any) {
  // ************************************
  // Properties
  // ************************************

  const { activeTheme, activeBrand } = useApplicationContext()
  const { GETTYPED, token } = useApplicationServicehandlerContext()
  const { setGlobalLoading } = useApplicationOverlayWrapperContext()
  const { setHosts, setGuests, mainUser, setMainUser, setChosenHost, chosenHost } =
    useApplicationGuestsAndHostsContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [pageId, setPageId] = useState<string>('basic')
  const [epiContentLoader, setEpiContentLoader] = useState<ContentLoader>()
  const [epiChildren, setEpiChildren] = useState<any>()
  const [error, setError] = useState<IError>()
  const [userData, setUserData] = useState<ICustomer>()
  const [installation, setInstallation] = useState<ICustomerInstallation>()
  const [translation, setTranslation] = useState<any>()
  const [contentPromise, setContentPromise] = useState<Promise<void>>()
  const [customerDataToEdit, setCustomerDataToEdit] = useState<ICustomerDataToEdit | undefined>()

  // STEP 1: TOKEN & FEED CONNECTION
  useEffect(() => {
    setGlobalLoading(true)
    if (token.length > 0) {
      if (!epiContentLoader) {
        setEpiContentLoader(getInitiatedContentLoader(token))
      }
    }
  }, [token])

  // STEP 2: EPISERVER PARSING AND MAPPING
  useEffect(() => {
    if (epiContentLoader && !epiChildren) {
      setContentPromise(_setupEpiChildren(epiContentLoader))
    }
  }, [epiContentLoader])

  useEffect(() => {
    if (contentPromise) {
      updateCustomerData()
    }
  }, [chosenHost, contentPromise])

  // ************************************
  // userData data handler
  // ************************************

  const updateCustomerData = async (resetCustomerData = false): Promise<{ callState: CallState; data: ICustomer }> => {
    setGlobalLoading(true)
    const { callState, data: customerData } = await GETTYPED<ICustomer>('Customers/summary', resetCustomerData)
    await contentPromise
    if (customerData && epiChildren) {
      _handleInstallation(customerData)
    }

    if (!mainUser && customerData) {
      setMainUser(customerData)
    }

    if (customerData) {
      _handleGuestsAndHosts(customerData, chosenHost)
    }

    if (callState === 'success') {
      if (customerData) {
        let newCustomer: ICustomer = customerData

        newCustomer = _capitalizeLettersProperlyInNamesAndAddresses(newCustomer)
        setUserData(newCustomer)
      }
    } else if (callState === 'error') {
      if (!error) {
        const homePageData = HomePageData(epiChildren)

        newrelicErrorLogger(new Error('Error fetching customer data'), {
          level: 'error',
          message: 'Error fetching customer data'
        })

        setError({
          title: getText('errorGlobalModalTitle', homePageData, undefined, 'Ooops...'),
          errorMessage: getText('errorGlobalModalDesc', homePageData, undefined, 'Ser ut som noe gikk galt'),
          action: {
            text: getText('errorGlobalModalAction', homePageData, undefined, 'Prøv igjen'),
            onClick: () => {
              setError(undefined)
              window.location.reload()
            }
          },
          logOutButtonText: getText('logoutText', homePageData, undefined, 'Logg ut')
        })
      }
    }

    setGlobalLoading(false)
    return { callState, data: customerData ?? ({} as ICustomer) }
  }

  const _handleInstallation = (data: ICustomer) => {
    const homePageData = HomePageData(epiChildren)

    if (data.installations && data.installations.length > 0) {
      data.installations.forEach((i: ICustomerInstallation) => {
        if (!i.address.streetAddress) {
          i.address.streetAddress = getText('unknownAddress', homePageData)
        }
      })

      setInstallation(data.installations[0])
    }
  }

  const _handleGuestsAndHosts = (userData: ICustomer, chosenHost?: IGuestRelationship) => {
    if (userData.guestRelationships?.hosts || userData.guestRelationships?.guests) {
      // If we are a "NonCustomer" our "customerId" will be the same as one of our hosts,
      // so we set "chosenHost" to that host, which will set us as a guest in the rest of the app.
      const foundHostInRelationships = userData.guestRelationships?.hosts.find(
        host => host.customerId === userData.customerId
      )
      if (
        foundHostInRelationships &&
        (!chosenHost || (chosenHost && chosenHost.customerId !== foundHostInRelationships.customerId))
      ) {
        setChosenHost(foundHostInRelationships)
      }

      setHosts(userData?.guestRelationships?.hosts)
      setGuests(userData?.guestRelationships?.guests)
    }
  }

  // ************************************
  // Application userData Context
  // ************************************

  const contextHandler = useMemo(() => {
    return {
      updateCustomerData,
      epiChildren,
      userData,
      pageId,
      setPageId,
      installation,
      setInstallation,
      translation,
      setTranslation,
      customerDataToEdit,
      setCustomerDataToEdit
    }
  }, [epiChildren, userData, pageId, installation, translation, customerDataToEdit])

  // ************************************
  // Episerver Setup (Private)
  // ************************************

  const _setupEpiChildren = async (l: ContentLoader) => {
    const homePage = await fetchHomePage(l)
    const pageMap: any = {}

    if (homePage) {
      const page = _createEpiPage(homePage, pageMap)

      pageMap[page.type] = {
        id: page.id,
        data: page.data,
        type: page.type,
        children: _getEpiChildren(homePage.childrenContent, pageMap)
      } as IEpiPage
    }

    if (pageMap.HeadlessHomePageType?.children?.length > 0) {
      pageMap.HeadlessHomePageType?.children.forEach((child: IEpiPage) => {
        pageMap[child.type] = child
      })
    }

    setEpiChildren(pageMap)
  }

  const _getEpiChildren = (children: any[], map: any) => {
    const epiChildren: IEpiPage[] = []

    if (children?.length > 0) {
      children.forEach((child: any) => {
        const parsed = JSON.parse(child)
        const data = _createEpiPage(parsed, map)

        epiChildren.push({
          id: data.id,
          data: parsed,
          type: data.type,
          children: _getEpiChildren(parsed.childrenContent, map)
        } as IEpiPage)
      })
    }

    return epiChildren
  }

  const _createEpiPage = (data: any, map: any) => {
    let id = ''
    let type = ''

    if (data.pageId) {
      id = data.pageId
    }

    if (
      data.modelType &&
      data.modelType !== 'HeadlessGenericPageType' &&
      data.modelType !== 'HeadlessServicePageType'
    ) {
      type = data.modelType
    } else if (data.modelType && data.modelType === 'HeadlessServicePageType') {
      type = `${data.modelType}${data.servicePageType}`
    } else if (data.pageType) {
      type = data.pageType

      if (map[type]) {
        logger(
          `There are two pages with the same type, we will only reference the first instance of this page type: ${data.pageType}`,
          'warn'
        )
        const duplicateType: string = `${data.pageType}_${uuid4()}`
        type = duplicateType
      }
    }

    return { id, type, data }
  }

  const _capitalizeLettersProperlyInNamesAndAddresses = (userData: ICustomer): ICustomer => {
    const newCustomer = userData

    newCustomer.firstName = _namify(newCustomer.firstName)
    newCustomer.lastName = _namify(newCustomer.lastName)
    if (newCustomer.address) {
      newCustomer.address.streetAddress = _namify(newCustomer.address.streetAddress, true)
      newCustomer.address.postalLocation = _namify(newCustomer.address.postalLocation)
    }

    newCustomer.installations = newCustomer.installations.map(inst => {
      return {
        ...inst,
        address: {
          ...inst.address,
          streetAddress: _namify(inst.address.streetAddress, true),
          postalLocation: _namify(inst.address.postalLocation)
        }
      }
    })

    return newCustomer
  }

  const _namify = (name: string, isStreetName = false): string => {
    // 1. Always capitalize first letter.
    // 2. If the letter before a letter is a space (' ') or a dash ('-') then capitalize it.
    // 3. If the name is a streetname and the letter before a letter is a number, then capitalize it (ie. "Veien 8F").
    if (name) {
      let letters = name.toLowerCase().split('')
      letters = letters.map((letter, i) => {
        if (i !== 0) {
          if (
            letters[i - 1] === ' ' ||
            letters[i - 1] === '-' ||
            (isStreetName && !Number.isNaN(Number.parseInt(letters[i - 1])))
          ) {
            return letter.toUpperCase()
          }
          return letter
        }
        return letter.toUpperCase()
      })
      return letters.join('')
    }
    return ''
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationCoreDataContext.Provider value={contextHandler}>
      {error && (
        <ErrorModal
          theme={activeTheme}
          brand={activeBrand}
          text={error.title}
          description={error.errorMessage}
          action={error.action}
          logOutButtonText={error.logOutButtonText}
        />
      )}
      {epiChildren && mainUser && props.children}
    </ApplicationCoreDataContext.Provider>
  )
}
