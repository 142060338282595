import {
  BrandColors,
  ContentGrid,
  type IText,
  Text,
  type Theme,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { h1TextPrefab, h3TextPrefab } from '../../../Prefabs'

interface IContent {
  contentTitle?: string
  contentTitleAlignment: IText['align']
  contentSubTitle?: string
  contentSubTitleAlignment: IText['align']
}

export const ContentHeader = ({
  classPrefix,
  theme,
  content
}: {
  classPrefix: string
  theme: Theme
  content: IContent
}) => {
  const { contentTitle, contentTitleAlignment, contentSubTitle, contentSubTitleAlignment } = content
  return (
    <ContentGrid
      className={`${classPrefix}__card__header`}
      direction='column'
      alignment={contentTitle ? 'top-left' : 'top-center'}
      tagType='section'
      boxSizing='border-box'
    >
      {contentTitle && (
        <Text
          {...h1TextPrefab()}
          className={`${classPrefix}__content-title`}
          color={BrandColors['primary-shade-light-2']}
          align={contentTitleAlignment}
        >
          {contentTitle}
        </Text>
      )}
      {contentSubTitle && (
        <Text
          {...h3TextPrefab()}
          className={`${classPrefix}__content-sub-title`}
          theme={getCounterTheme(theme)}
          align={contentSubTitleAlignment}
          weight={500}
          faded
        >
          {contentSubTitle}
        </Text>
      )}
    </ContentGrid>
  )
}
