import { StyleGrid, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { DetailCheckboxCollectionBlock, type IDetailCheckboxCollectionBlockData } from '../../../blocks'
import { MSRichText } from '../../../components'
import { Constants } from '../../../data'
import { ConsentStateEnum, type IConsent, MarketingConsentType } from '../../../models'
import { type IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './ConsentPageData'
import './ConsentPage.scss'
import { useConsentContext } from '../../../contexts'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { getText, translateCheckboxValue } from '../../../services'

export interface IConsentPage extends IDefaultViewProps {
  detailCheckboxCollection: IDetailCheckboxCollectionBlockData[]
  description: string
  consentStates: IConsent[]
  isReservedAgainstMarketing: boolean
  isGuest: boolean
}

export const ConsentPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'consent-page'
  const { consents, setConsents, PUT_CONSENTS, isReservedAgainstMarketing } = useConsentContext()
  const { isGuest } = useApplicationGuestsAndHostsContext()

  // ************************************
  // Handling
  // ************************************

  const _handleConsentSettings = async (id: MarketingConsentType, state: boolean, translations: any) => {
    const newConsentsTypes = [
      MarketingConsentType.PersonalizedMarketing,
      MarketingConsentType.GeneralMarketing,
      MarketingConsentType.DisableMarketing
    ]
    const consentsToUpdate = [{ consentName: id, value: translateCheckboxValue(state) }]

    if (consents) {
      //if any of the other consents are pending, set them to DECLINED
      consents.forEach(consent => {
        if (newConsentsTypes.includes(consent.consentName) && consent.consentName !== id) {
          if (consent.value === ConsentStateEnum.PENDING) {
            consentsToUpdate.push({
              consentName: consent.consentName,
              value: ConsentStateEnum.DECLINED as ConsentStateEnum
            })
          }
          if (isReservedAgainstMarketing && consent.consentName === MarketingConsentType.DisableMarketing) {
            consentsToUpdate.push({
              consentName: consent.consentName,
              value: ConsentStateEnum.DECLINED as ConsentStateEnum
            })
          }
        }
      })

      const updatedConsents = consents.map(consent => {
        const match = consentsToUpdate.find(consentToUpdate => consentToUpdate.consentName === consent.consentName)
        return match ? { ...consent, value: match.value } : consent
      })
      setConsents(updatedConsents)
    }

    PUT_CONSENTS(consentsToUpdate, translations)
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderDetailCheckboxCollections = (config: IConsentPage) => {
    const { detailCheckboxCollection, activeBrand, activeTheme, isGuest, translations } = config

    return detailCheckboxCollection.map((item: IDetailCheckboxCollectionBlockData) => {
      return (
        <DetailCheckboxCollectionBlock
          {...item}
          type={'radio'}
          className={classPrefix}
          disabled={isGuest}
          theme={activeTheme}
          brand={activeBrand}
          key={`detailCheckboxCollection_${item.id}`}
          checkboxes={[
            { text: getText('radioButtonIConsent', translations), stateToReturn: true, states: ['ACCEPTED'] },
            { text: getText('radioButtonIDoNotConsent', translations), stateToReturn: false, states: ['DECLINED'] }
          ]}
          onChange={(value, state) => {
            _handleConsentSettings(value, state, config.translations)
          }}
        />
      )
    })
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.consentPage.type,
      usesSubPage: true,
      pageClamp: 'clamp'
    },
    dependencies: [{ consents }, { isReservedAgainstMarketing }, { isGuest }],
    handleData: getPageContent,
    render: (config: IConsentPage) => {
      const { activeBrand, activeTheme, description, desktopView } = config

      return (
        <StyleGrid className={`${classPrefix}`} direction='column' alignment='top-left' gap={4} boxSizing='border-box'>
          <MSRichText
            className={`${classPrefix}__description`}
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            text={description}
            alignment='lawful'
            customization={{
              type: 'p',
              align: desktopView ? 'align-center' : 'align-left'
            }}
          />
          {_renderDetailCheckboxCollections(config)}
        </StyleGrid>
      )
    }
  })
}
