import { ContentGrid, type IAction, Text } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { MS_ButtonTemplate, h3TextPrefab, smallParagraphTextPrefab } from '../../Prefabs'
import { ActionButton, MSRichText } from '../../components'
import { useApplicationContext } from '../../contexts'
import type { IBlock } from '../../models'

export interface IGenericHtmlBlock extends IBlock {
  title?: string
  description?: string
  action: IAction
}

export const EpiserverHtmlBlock = (props: IGenericHtmlBlock) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { title, description, action, id, className } = props
  const classPrefix = 'generic-html-block'
  return (
    <ContentGrid
      id={id}
      direction='column'
      alignment='top-left'
      tagType='section'
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      gap={2}
    >
      {title && (
        <Text {...h3TextPrefab()} align={'align-left'}>
          {title}
        </Text>
      )}
      {description && <MSRichText text={description} {...smallParagraphTextPrefab()} />}
      {action?.active && (
        <ActionButton
          template={MS_ButtonTemplate(activeTheme, 'primary')}
          brand={activeBrand}
          action={action}
          padding='default'
        />
      )}
    </ContentGrid>
  )
}
