import type { IAction } from '@fjordkraft/fjordkraft.component.library'
import { useNavigate } from 'react-router-dom'
import { type IMSPlankWall, PlankHouseBlock } from '../../../blocks'
import { useSubPageLayoutContext } from '../../../contexts'
import { Constants } from '../../../data'
import { type IPageData, PageV2 } from '../../PageV2'
import { getPageContent } from './UsePointsPageData'
import './UsePointsPage.scss'

export interface IUsePointsPage extends IPageData {
  title: string
  back: IAction
  listedOptions: IMSPlankWall
}

export const UsePointsPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { setInfoBoxContent } = useSubPageLayoutContext()
  const navigateFunc = useNavigate()
  const classPrefix = 'use-points-page'

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.benefits.type,
      usesSubPage: true
    },
    dependencies: [{ navigateFunc }],
    handleData: getPageContent,
    onDataChange: () => {
      if (setInfoBoxContent) {
        setInfoBoxContent({ hasInfo: false })
      }
    },
    render: (config: IUsePointsPage) => {
      const { activeBrand, activeTheme, listedOptions } = config

      return (
        <PlankHouseBlock
          className={`${classPrefix}`}
          theme={activeTheme}
          brand={activeBrand}
          plankWalls={[listedOptions]}
        />
      )
    }
  })
}
