import {
  BrandColors,
  ContentGrid,
  type FlexScaling,
  type IComponent,
  StyleGrid,
  Text,
  getColorCode,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { h3TextPrefab, paragraphTextPrefab, smallParagraphTextPrefab } from '../../../Prefabs'
import { PlankWall } from '../../../blocks'
import { type IStatePlank, MSTooltip } from '../../../components'
import './PointCard.scss'

export interface ICardPoint {
  id?: string
  leftText?: string
  extraLeftText?: string
  rightText?: string
  extraRightText?: string
  tooltip?: string
  subDetail?: boolean
  customization?: {
    color: BrandColors
  }
}

export interface IPointCard extends IComponent {
  title?: string
  points: ICardPoint[]
  scaling?: FlexScaling
  planks?: IStatePlank[]
  customization?: {
    highlighted: {
      background: BrandColors
      text: BrandColors
    }
    sub: {
      background: BrandColors
      text: BrandColors
    }
  }
}

export type PointCardSectionType = 'highlighted' | 'sub'

export const PointCard = (props: IPointCard) => {
  // ************************************
  // Properties
  // ************************************

  const {
    className,
    theme,
    brand,
    points,
    title,
    planks,
    customization = {
      highlighted: {
        background: BrandColors['action-shade-light-3'],
        text: BrandColors['text-shade-dark-3']
      },
      sub: {
        background: BrandColors['background-shade-light-1'],
        text: BrandColors['text-shade-dark-2']
      }
    }
  } = props
  const classPrefix = 'point-card'

  // ************************************
  // Helper Functionality
  // ************************************

  const _getBackgroundColor = (type: PointCardSectionType = 'highlighted') => {
    return type === 'sub' ? customization.sub.background : customization.highlighted.background
  }

  const _getTextColor = (type: PointCardSectionType = 'highlighted') => {
    return type === 'highlighted' ? customization.highlighted.text : customization.sub.text
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderPoints = (type: PointCardSectionType = 'highlighted') => {
    const highlightedPoints: any[] = []
    const subPoints: any[] = []

    for (const data of points) {
      const element = (
        <ContentGrid
          className={`${classPrefix}__point`}
          key={uuid4()}
          direction='row'
          alignment='top-left'
          tagType='section'
          brand={brand}
          spaceBetween
        >
          <ContentGrid
            className={`${classPrefix}__point__left`}
            direction='column'
            alignment='center-left'
            tagType='section'
            boxSizing='border-box'
            gap={1}
          >
            <Text {...paragraphTextPrefab()} brand={brand} weight={700} color={_getTextColor(type)}>
              {data.leftText}
            </Text>
            {data.extraLeftText && (
              <Text {...smallParagraphTextPrefab()} brand={brand} color={_getTextColor(type)}>
                {data.extraLeftText}
              </Text>
            )}
          </ContentGrid>
          <ContentGrid
            className={`${classPrefix}__point__right`}
            direction='column'
            alignment='center-right'
            tagType='section'
            boxSizing='border-box'
            gap={1}
          >
            <ContentGrid tagType='section' alignment='center-right' boxSizing='border-box' direction='row' gap={1}>
              <Text
                {...paragraphTextPrefab()}
                brand={brand}
                weight={500}
                wrap={'pre-wrap'}
                align='align-right'
                color={_getTextColor(type)}
              >
                {data.rightText}
              </Text>
              {data.tooltip && (
                <MSTooltip
                  className={`${classPrefix}__tooltip`}
                  brand={brand}
                  theme={getCounterTheme(theme)}
                  text={data.tooltip}
                  alignment={'bottom-left'}
                />
              )}
            </ContentGrid>
            {data.extraRightText && (
              <Text {...paragraphTextPrefab()} brand={brand} weight={400} color={_getTextColor(type)}>
                {data.extraRightText}
              </Text>
            )}
          </ContentGrid>
        </ContentGrid>
      )

      if (data.subDetail) {
        subPoints.push(element)
      } else {
        highlightedPoints.push(element)
      }
    }

    return { highlightedPoints, subPoints }
  }

  const _renderPointsContainer = (elements: any[], type: PointCardSectionType = 'highlighted') => {
    const hasSub: boolean = _renderPoints('sub').subPoints?.length > 0
    const hasPlanks: boolean = (planks && planks?.length > 0) ?? false

    if (elements.length > 0) {
      return (
        <ContentGrid
          className={classNames(`${classPrefix}__points-container`, {
            [`${classPrefix}__points-container--${type}`]: type,
            [`${classPrefix}__points-container--top`]: (type === 'highlighted' && hasSub) || hasPlanks,
            [`${classPrefix}__points-container--middle`]: hasPlanks && type === 'sub',
            [`${classPrefix}__points-container--bottom`]: type === 'sub' && !hasPlanks
          })}
          direction='column'
          alignment='top-left'
          gap={2}
          tagType='section'
          boxSizing='border-box'
          style={{
            borderColor: getColorCode({
              color: _getBackgroundColor('highlighted')
            }),
            backgroundColor: getColorCode({
              color: _getBackgroundColor(type)
            })
          }}
        >
          {title && type === 'highlighted' && (
            <Text
              className={`${classPrefix}__points-container__title`}
              {...h3TextPrefab()}
              brand={brand}
              color={_getTextColor(type)}
            >
              {title}
            </Text>
          )}
          <StyleGrid
            className={`${classPrefix}__points-container__grid`}
            direction='column'
            alignment='top-left'
            gap={type === 'sub' ? 2 : 3}
            boxSizing='border-box'
          >
            {elements}
          </StyleGrid>
        </ContentGrid>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-left'
      boxSizing='border-box'
      tagType='article'
    >
      {_renderPointsContainer(_renderPoints('highlighted').highlightedPoints, 'highlighted')}
      {_renderPointsContainer(_renderPoints('sub').subPoints, 'sub')}
      {planks && planks.length > 0 && (
        <PlankWall
          planks={planks}
          theme={theme}
          brand={brand}
          customization={{
            blendWithTop: true,
            titleGap: 2,
            backgroundColor: {
              Light: BrandColors['background-shade-light-2'],
              Dark: BrandColors['background-shade-dark-3']
            }
          }}
        />
      )}
    </ContentGrid>
  )
}
