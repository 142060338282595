import {
  type CallState,
  type IAction,
  LoaderGraphic,
  StyleGrid,
  TextInput,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import { MSRichText } from '../../components'
import { useApplicationContext, useApplicationServicehandlerContext } from '../../contexts'
import type { IPage } from '../../models'
import { capitalizeFirstLetter, createString, getText } from '../../services'
import { type IPopupCard, PopupCard } from '../PopupCard/PopupCard'
import './DigitVerificationModal.scss'

export interface IDigitVerificationModal extends IPopupCard {
  translation: IPage
  digitClampCount?: number
  changedValue: string
  verified: (callState: CallState) => void
}

export const DigitVerificationModal = (props: IDigitVerificationModal) => {
  // ************************************
  // Properties
  // ************************************

  const { PUT } = useApplicationServicehandlerContext()
  const { desktopView, activeBrand } = useApplicationContext()
  const { theme = 'Light', onClose, translation, digitClampCount = 6, verified, changedValue } = props
  const classPrefix = 'digit-verification-modal'
  const inputId = uuid4()

  // ************************************
  // Lifecycle
  // ************************************

  const [callState, setCallState] = useState<CallState>('idle')
  const [isValidInput, setIsValidInput] = useState<boolean>()
  const [currentValue, setCurrentValue] = useState<string>('')

  // ************************************
  // Handlers
  // ************************************

  const _fetchNewCode = async () => {
    setCallState('pending')
    await PUT('Customers/initiateChangePhoneNumber', changedValue)
    setCallState('idle')
  }

  const _verify = async (verificationNumber: any) => {
    if (isValidInput) {
      setCallState('pending')
      const resp = await PUT('Customers/verifyChangePhoneNumber', `${verificationNumber}`)

      switch (resp.callState) {
        case 'aborted':
        case 'error':
          setCallState('error')
          break
        case 'success':
          verified(resp.callState)
          break
        default:
          setCallState('idle')
          break
      }
    }
  }

  const _isValid = (value: any) => {
    const numberVal = Number(value)
    setIsValidInput(!Number.isNaN(numberVal) && value.length === digitClampCount)
    return !Number.isNaN(numberVal) && value.length === digitClampCount
  }

  // ************************************
  // Helpers
  // ************************************

  const _getContentTitle = (callState: CallState) => {
    return getText(`digitVerification${capitalizeFirstLetter(callState)}Title`, translation)
  }

  const _getPrimaryAction = () => {
    if (callState === 'error') {
      return {
        text: getText('digitPopupNewCode', translation),
        onClick: () => {
          _fetchNewCode()
        }
      } as IAction
    }
    if (callState === 'idle') {
      return {
        disabled: !isValidInput,
        text: getText('digitPopupChange', translation),
        onClick: () => {
          _verify(currentValue)
        }
      } as IAction
    }

    return undefined
  }

  // ************************************
  // Render
  // ************************************

  return (
    <PopupCard
      contentTitle={
        callState === 'pending'
          ? getText('customerInfoEditorModalLoadingText', translation)
          : _getContentTitle(callState)
      }
      contentTitleAlignment={callState === 'pending' ? 'align-center' : 'align-left'}
      {...props}
      brand={activeBrand}
      contentGap={4}
      contentAlignment={'top-left'}
      primaryAction={_getPrimaryAction()}
      secondaryAction={{
        text: getText('digitPopupCancel', translation),
        onClick: onClose
      }}
      desktopView={desktopView}
    >
      {callState !== 'pending' && (
        <MSRichText
          className={`${classPrefix}__text`}
          theme={getCounterTheme(theme)}
          brand={activeBrand}
          alignment='lawful'
          text={createString(getText('digitPopupDescription', translation), {
            amount: digitClampCount
          })}
        />
      )}
      {callState !== 'pending' && (
        /*<ClampedDigitInputField
                    id={inputId}
                    className={`${classPrefix}__input`}
                    theme={theme}
                    brand={brand}
                    digitClamp={digitClampCount}
                    onComplete={(value: string) => {
                        _isValid(value);
                        setCurrentValue(value);
                    }}
                />*/
        <TextInput
          id={inputId}
          className={`${classPrefix}__input`}
          theme={theme}
          brand={activeBrand}
          type={'number'}
          value={currentValue}
          missingRequired={!isValidInput}
          requiredText={getText('digitPopupInvalid', translation)}
          input={{
            type: 'number',
            maxLength: 6
          }}
          onChange={e => {
            _isValid(e.target.value)
            setCurrentValue(e.target.value)
          }}
        />
      )}
      {callState === 'pending' && (
        <StyleGrid className={`${classPrefix}__loader`} direction='column' alignment='center'>
          <LoaderGraphic theme={theme} brand={activeBrand} thickness={'regular'} scaleThickness={0.5} size={4} />
        </StyleGrid>
      )}
    </PopupCard>
  )
}
