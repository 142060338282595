import type { getRequest, typedPutRequest } from '../../contexts'
import type { ICustomerInstallationPowerPlant } from '../../models'

export const fetchPowerPlant = async (GET: getRequest, priceArea: string) => {
  const resp = await GET(`installations/powerplant/${priceArea}`)
  return resp?.data as ICustomerInstallationPowerPlant
}

// ************************************
// PUT
// ************************************

export interface UpdateMeterNumberRequest {
  meterNumber: string
  postalCode: string
  streetAddress: string
  lastUpdatedDate: Date
}

export const updateMeterNumber = async (
  PUTTYPED: typedPutRequest,
  orderModuleId: number,
  body: UpdateMeterNumberRequest
) => {
  const resp = await PUTTYPED(`installations/updateMeterNumber/${orderModuleId}`, body)
  return resp
}
