import type { IToast } from '../../components/Toaster'
import { GenericToastPrefab, type IGenericToastPrefab } from './GenericToastPrefab'
import { type IMessageToastPrefab, MessageToastPrefab } from './MessageToastPrefab'
import { type IStatusToastPrefab, StatusToastPrefab } from './StatusToastPrefab'

// ************************************
// Interfaces and types
// ************************************

export enum ToastPrefabsType {
  Generic = 0,
  MessageToast = 1,
  StatusToast = 2
}
export type ToastPrefabInterface = IGenericToastPrefab | IMessageToastPrefab | IStatusToastPrefab

// ************************************
// Getter
// ************************************

export const GetToastPrefab = (type: ToastPrefabsType, data: ToastPrefabInterface): IToast => {
  switch (type) {
    case ToastPrefabsType.Generic:
      return GenericToastPrefab(data as IGenericToastPrefab) // Invoice status (paid/unpaid invoice)
    case ToastPrefabsType.MessageToast:
      return MessageToastPrefab(data as IMessageToastPrefab) // Service messages, from servicemessages API-call, black toast with close button
    case ToastPrefabsType.StatusToast:
      return StatusToastPrefab(data as IStatusToastPrefab) // Status on managed power agreement (negative/positive result)
  }
}
