import { BrandColors, type IComponent, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { ArticleItem, FAQ, TipCard } from '../../components'
import { ActionDescriptionBlock } from '../ActionDescriptionBlock/ActionDescriptionBlock'
import './DynamicEpiContentBlock.scss'
import { EpiserverHtmlBlock } from '../EpiserverHtmlBlock/EpiserverHtmlBlock'

interface IProps extends IComponent {
  epiItems: any[]
  scaling?: 'stretch' | 'clamp' | 'scale'
}

export const DynamicEpiContentBlock = (props: IProps) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme, brand, epiItems, scaling = 'clamp' } = props
  const classPrefix = 'ms-dynamic-epi-content-block'

  // ************************************
  // Block Handler
  // ************************************

  const generateContent = () => {
    const content: any[] = []

    if (epiItems && epiItems.length > 0) {
      epiItems.forEach((item: any) => {
        if (item.active) {
          content.push(getContentBlock(item.modelType, item))
        }
      })
    }

    return content
  }

  const getContentBlock = (type: string, item: any) => {
    switch (type) {
      case 'ArticleItemBlock':
        return renderArticleBlock(item)
      case 'TipsBlock':
        return renderTipsBlock(item)
      case 'ActionDescriptionBlock':
        return renderActionDescriptionBlock(item)
      case 'FaqBlock':
        return renderFaqBlock(item)
      case 'GenericHtmlElementBlock':
        return renderEpiserverHtmlBlock(item)
    }
  }

  // ************************************
  // Helpers
  // ************************************

  const _blockItemClasses = () => {
    return classNames(`${classPrefix}__item`, {
      [`${classPrefix}__item--${scaling}`]: scaling,
      [`${className}__item`]: className
    })
  }

  // ************************************
  // Render Functionality
  // ************************************

  const renderEpiserverHtmlBlock = (item: any) => {
    return <EpiserverHtmlBlock key={uuid4()} {...item} className={_blockItemClasses()} theme={theme} brand={brand} />
  }

  const renderFaqBlock = (item: any) => {
    return (
      <FAQ
        key={uuid4()}
        title={item.title}
        questions={item.faqContent}
        className={_blockItemClasses()}
        theme={theme}
        brand={brand}
      />
    )
  }

  const renderArticleBlock = (item: any) => {
    let actionIcon: any = item.action.iconType

    if (actionIcon && actionIcon === 'None') {
      actionIcon = undefined
    }

    return (
      <ArticleItem
        key={uuid4()}
        className={_blockItemClasses()}
        theme={theme}
        brand={brand}
        image={{
          alt: item.imageAlt ?? '',
          src: item.imageSrc
        }}
        title={item.imageLabelText}
        description={item.content}
        action={{
          text: item.action.text,
          icon: item.action.iconType === 'None' ? undefined : item.action.iconType,
          link: item.action.url
        }}
      />
    )
  }

  const renderTipsBlock = (item: any) => {
    return (
      <TipCard
        key={uuid4()}
        className={_blockItemClasses()}
        theme={theme}
        brand={brand}
        title={item.title}
        content={item.content}
        iconType={item.iconType}
        iconColor={BrandColors['primary-shade-light-2']}
        action={
          item.url
            ? {
                link: item.url,
                text: item.linkText
              }
            : undefined
        }
      />
    )
  }

  const renderActionDescriptionBlock = (item: any) => {
    return (
      <ActionDescriptionBlock
        key={uuid4()}
        className={_blockItemClasses()}
        theme={theme}
        brand={brand}
        title={item.title}
        description={item.description}
        action={{ ...item.action, ...{ link: item.action.url } }}
      />
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-center'
      gap={4}
    >
      {generateContent()}
    </StyleGrid>
  )
}
