import { createContext, useContext } from 'react'
import type { ICustomerDataToEdit } from '../../modals'
import type { CallState, ICustomer, ICustomerInstallation } from '../../models'

interface IApplicationExternalData {
  epiChildren?: any
  userData?: ICustomer
  installation?: ICustomerInstallation
  setInstallation: (installation?: ICustomerInstallation) => void
  pageId: string
  updateCustomerData: (newSetup?: boolean) => Promise<{ callState: CallState; data: ICustomer }>
  setPageId: (id: string) => void
  translation: any
  setTranslation: (translation: any) => void
  customerDataToEdit: ICustomerDataToEdit | undefined
  setCustomerDataToEdit: (data: ICustomerDataToEdit | undefined) => void
}

export const ApplicationCoreDataContext = createContext<IApplicationExternalData>({
  epiChildren: undefined,
  userData: undefined,
  pageId: 'basic',
  installation: undefined,
  updateCustomerData: () => new Promise(() => {}),
  setInstallation: () => {},
  setPageId: () => {},
  translation: undefined,
  setTranslation: () => {},
  customerDataToEdit: undefined,
  setCustomerDataToEdit: () => {}
})

export const useApplicationCoreDataContext = () => useContext(ApplicationCoreDataContext)
