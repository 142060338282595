import { IconType, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { format } from 'date-fns'
import { _getTextPlankPrefabTemplate } from '.'
import type { IStatePlank } from '../../../components'
import { Constants } from '../../../data'
import { AllowFeature, GetInstallationStatusAndInstallationDate, IsActiveCustomer, getText } from '../../../services'
import type { IHomePageData } from '../HomePageData'

export const InstallationStatusPlank = (props: IHomePageData): IStatePlank | undefined => {
  const { translations, user, activeTheme, services } = props
  const { installation } = user
  const { customerServiceFeature } = services

  if (IsActiveCustomer(installation) && installation) {
    const [status, installationDate] = GetInstallationStatusAndInstallationDate(
      installation.validFrom,
      installation.validTo
    )

    if (
      AllowFeature(Constants.features.installationStatus, translations, services.user, customerServiceFeature) &&
      status !== 'none'
    ) {
      return {
        promise: fetchInstallationStatusPlank(props, status === 'establishing', installationDate),
        theme: activeTheme
      }
    }
  }
}

const fetchInstallationStatusPlank = async (props: IHomePageData, establishing: boolean, date: Date) => {
  const { activeTheme, activeBrand, translations, desktopView } = props

  return _getTextPlankPrefabTemplate({
    theme: getCounterTheme(activeTheme),
    brand: activeBrand,
    title: getText('plankInstallationOrderStatusTitle', translations),
    description: establishing
      ? getText('plankInstallationOrderStatusDesc', translations)
      : getText('plankInstallationOrderStatusEndingDesc', translations),
    rightTitle: establishing
      ? getText('plankInstallationOrderStatusValue', translations)
      : getText('plankInstallationOrderStatusEndingValue', translations),
    text: format(date, 'dd.MM.yyyy'),
    iconLeft: desktopView ? IconType.MoreInfo : undefined
  })
}
