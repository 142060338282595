import { StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CommercialBanner } from '../../components'
import {
  useApplicationAddonServicesContext,
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext
} from '../../contexts'
import type { IBlock, IEpiAction, ITranslationItem } from '../../models'
import { getText } from '../../services'
import './CommercialBlock.scss'
import { get } from 'lodash'
import { CommercialTemplate } from '../../Prefabs'
import { CarouselControls } from '../../components/CarouselControls/CarouselControls'

export interface EpiCommercial {
  action?: IEpiAction
  active: boolean
  blockId: string
  commercialId?: string
  image?: string
  modelType: string
  priority: number
  translationItems: ITranslationItem[]
}

interface ICommercialBlock extends IBlock {
  commercialWidth?: 'large' | 'standard'
}

export const CommercialBlock = (props: ICommercialBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, commercialWidth = 'standard' } = props
  const classPrefix = 'commercial-block'

  const { defaultProps } = useApplicationDefaultContext()
  const { activeBrand: brand, activeTheme: theme = 'Light', translations, epiChildren } = defaultProps
  const commercials = (get(translations, 'commercials', undefined) ??
    get(epiChildren, 'HeadlessHomePageType.data.commercials', undefined) ??
    []) as EpiCommercial[]
  const storageId = `commercials-${translations.pageId}`

  const { availableServicePages: addonStates } = useApplicationAddonServicesContext()
  const { desktopView } = useApplicationContext()
  const { userData } = useApplicationCoreDataContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [showCommercials, setShowCommercials] = useState<boolean>(true)
  const scrollRef = useRef<any>(null)
  const [currentCommercialIndex, setCurrentCommercialIndex] = useState(0)

  useEffect(() => {
    if (localStorage.getItem(storageId)) {
      setShowCommercials(localStorage.getItem(storageId) !== 'true')
    }
  }, [])

  // ************************************
  // Priority handling/filter out irrelelvent commercials
  // ************************************

  const mapAddonStates = () =>
    addonStates?.reduce((prevValue: { [key: string]: boolean }, currValue) => {
      prevValue[currValue.productDefinitionId ?? currValue.servicePageId] = currValue?.serviceStatus?.state === 'ACTIVE'
      return prevValue
    }, {})

  const filterOutServicesInUse = (commercial: EpiCommercial, mappedAddonStates?: { [key: string]: boolean }) => {
    const relatedServices = commercial.translationItems.find(item => item.key === 'relatedServices')?.value?.split(',')

    //filter otu if related service is active
    //filter out if service not found in addonStates (page not active e.g)
    const relatedServicesInUse = relatedServices?.filter(service => {
      if (service === 'mobile') {
        return userData?.isMobileCustomer
      }
      return !!get(mappedAddonStates, `${service}`, true)
    })

    return !relatedServicesInUse?.length
  }

  const _removeItemFromList = (item: EpiCommercial, list: EpiCommercial[]) => {
    const itemId = list.findIndex(e => e.commercialId === item?.commercialId)
    list.splice(itemId, 1)
  }

  const getFilteredCommercials = () => {
    const mappedAddonStates = mapAddonStates()
    const activeCommercials = commercials?.filter(commercial => commercial.active)
    return activeCommercials.filter(commercial => filterOutServicesInUse(commercial, mappedAddonStates))
  }

  const _getRandomizedPriority = (totalPriorityWeight: number) => {
    // Using crypto because SonarQube hates Math.random():
    // 1. Generate random byte,
    // 2. Normalize value from 0 - MAX_INTEGER to 0 - 1,
    // 3. Multiply with total weight.

    const array = new Uint32Array(1)
    crypto.getRandomValues(array)
    return (array[0] / (0xffffffff + 1)) * totalPriorityWeight
  }

  const _fetchPrioritizedItem = (availableItems: EpiCommercial[]): EpiCommercial | undefined => {
    const activeCommercials = availableItems
    const totalPriorityWeight = activeCommercials.reduce((sum, item) => sum + item.priority, 0)

    const mappedCommercials = []
    let priority = 0

    for (const commercial of activeCommercials) {
      priority += commercial.priority
      mappedCommercials.push({
        commercial: commercial,
        priority: priority
      })
    }

    return mappedCommercials.find(commercial => _getRandomizedPriority(totalPriorityWeight) <= commercial.priority)
      ?.commercial
  }

  const commercialItems = useMemo(() => {
    const unPickedCommercials = getFilteredCommercials()
    const commercialsInPrioritizedOrder = []

    while (unPickedCommercials.length > 0) {
      const prioritizedCommercial = (
        unPickedCommercials.length > 1 ? _fetchPrioritizedItem(unPickedCommercials) : unPickedCommercials.pop()
      ) as EpiCommercial

      commercialsInPrioritizedOrder.push(prioritizedCommercial)
      _removeItemFromList(prioritizedCommercial, unPickedCommercials)
    }

    return commercialsInPrioritizedOrder.map(commercial => (
      <CommercialBanner
        key={commercial.commercialId}
        className={`${classPrefix}__scroll__item`}
        link={commercial?.action?.url}
        priority={commercial.priority}
        title={getText('title', commercial)}
        description={getText('description', commercial)}
        image={
          commercial.image
            ? {
                src: commercial.image,
                alt: 'Commercial'
              }
            : undefined
        }
        brand={brand}
        theme={theme}
        desktopView={desktopView}
      />
    ))
  }, [addonStates, userData])

  // ************************************
  //  sideways scroll navigation
  // ************************************

  const scrollToItem = (index: number) => {
    const findLengthToMiddle = (item: any, container: any) => {
      const offsetLeftWithoutPadding =
        item.offsetLeft -
        container.offsetLeft -
        container.clientLeft -
        Number.parseInt(getComputedStyle(container).paddingLeft)
      return offsetLeftWithoutPadding - (container.clientWidth - item.clientWidth) / 2
    }

    if (scrollRef.current && commercialItems[index]) {
      const container = scrollRef.current
      container.scrollLeft = findLengthToMiddle(container.children[index], container)
      setCurrentCommercialIndex(index)
    }
  }

  const handleNext = (index?: number) => {
    const indexToUse = index ?? currentCommercialIndex
    if (indexToUse < commercialItems.length - 1) {
      scrollToItem(indexToUse + 1)
    }
  }

  const handlePrev = (index?: number) => {
    const indexToUse = index ?? currentCommercialIndex
    if (indexToUse > 0) {
      scrollToItem(indexToUse - 1)
    }
  }

  //mobile sideways scroll handling
  let start = 0
  let end = 0
  let currentIndex = 0
  useEffect(() => {
    if (commercials?.length > 0) {
      const scrollWrapper = document.querySelector(`.${classPrefix}__scroll__wrapper`)

      if (scrollWrapper) {
        scrollWrapper.addEventListener('touchstart', (e: any) => {
          start = e.touches[e.touches.length - 1].clientX
        })
        scrollWrapper.addEventListener('touchmove', (e: any) => {
          end = e.touches[e.touches.length - 1].clientX
        })
        scrollWrapper.addEventListener('touchend', onTouchEnd)
      }
    }
  }, [])

  const onTouchEnd = () => {
    if (start && end) {
      if (start < end && currentIndex > 0) {
        handlePrev(currentIndex)
        currentIndex -= 1
      }
      if (start > end && currentIndex < commercialItems.length - 1) {
        handleNext(currentIndex)
        currentIndex += 1
      }
      start = 0
      end = 0
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {showCommercials && (
        <StyleGrid
          id={id}
          className={classNames(classPrefix, {
            [`${className}`]: className,
            [`${classPrefix}-${commercialWidth}`]: true
          })}
          brand={brand}
          direction='column'
          alignment='center'
          gap={1}
        >
          <div className={`${classPrefix}__scroll__wrapper`} ref={scrollRef}>
            {commercialItems}
          </div>
          {commercialItems?.length > 1 && (
            <CarouselControls
              className={`${classPrefix}__carousel__controls`}
              currentIndex={currentCommercialIndex}
              listLength={commercialItems.length}
              onPrevious={() => handlePrev()}
              onNext={() => handleNext()}
              palette={CommercialTemplate(theme).palette}
            />
          )}
        </StyleGrid>
      )}
    </>
  )
}
