import {
  BrandColors,
  type IComponentTemplate,
  type IComponentTransform,
  type IThemedColors,
  type Theme
} from '@fjordkraft/fjordkraft.component.library'

const _transform = {
  width: '100%',
  border: {
    radiusType: 'curved'
  },
  grid: {
    boxSizing: 'border-box',
    tagType: 'div',
    direction: 'row',
    alignment: 'center-left',
    gap: 2
  }
} as IComponentTransform

const _palette = {
  Light: {
    icon: { color: BrandColors['action-shade-dark-3'], opacity: 0.75 }
  },
  Dark: {
    icon: { color: BrandColors['action-shade-dark-3'], opacity: 0.75 }
  }
} as IThemedColors

export const ProgressBarTemplate = (theme: Theme = 'Light') => {
  return {
    transform: _transform,
    palette: _palette[theme]
  } as IComponentTemplate
}
